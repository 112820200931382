import React from "react";
import { TableCell, Tooltip } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import PropTypes from "prop-types";
/** Component Description:
 *  This component render for each table container,
 *  the row's fields.
 */
function DisplayMarketsPricesTableRows({ row }) {
  return (
    <React.Fragment>
      <TableCell align="left">{row.marche.nom}</TableCell>
      <TableCell align="center">{row.variete.nom}</TableCell>

      <TableCell align="center">
        {row.prix_gros_min === null
          ? "NEANT"
          : `${row.prix_gros_min}-${row.prix_gros_max}`}
      </TableCell>
      <TableCell align="center">
        {row.prix_demi_gros_min === null
          ? "NEANT"
          : `${row.prix_demi_gros_min}-${row.prix_demi_gros_max}`}
      </TableCell>

      <TableCell align="center">
        {row.unite_demi_gros === null && row.quantite_demi_gros === null
          ? "NEANT"
          : `${row.quantite_demi_gros}${row.unite_demi_gros}`}
      </TableCell>
      <TableCell align="center">
        {row.prix_detaillant_min === null
          ? "NEANT"
          : `${row.prix_detaillant_min}-${row.prix_detaillant_max}`}
      </TableCell>
      <TableCell align="center">
        {row.prix_dominant === null ? "NEANT" : row.prix_dominant}
      </TableCell>
      <TableCell align="center">
        {row.statut ? (
          <Tooltip title="Les prix ont ete valides!">
            <CheckIcon size="small" style={{ color: "green" }} />
          </Tooltip>
        ) : (
          <Tooltip title="Les prix n'ont pas ete valides!">
            <CloseIcon size="small" style={{ color: "red" }} />
          </Tooltip>
        )}
      </TableCell>
      <TableCell align="center">
        {row.date_statut === null ? "NEANT" : row.date_statut}
      </TableCell>
      <TableCell align="center">
        {row.date_statut_expiration === null
          ? "NEANT"
          : row.date_statut_expiration}
      </TableCell>
    </React.Fragment>
  );
}
DisplayMarketsPricesTableRows.propTypes = {
  row: PropTypes.object,
};

export default DisplayMarketsPricesTableRows;
