import { CircularProgress } from "@mui/material";
import React from "react";
import CommonPagesLayout from "../../../components/layouts/Common/CommonPagesLayout";
import { LIST_VARIETIES_DATA_URL } from "../../../utils/api/apiUrls";
import apiInstance from "../../../utils/api/axios.config";
import { useAuthenticationContext } from "../../../utils/contexts/AuthenticationProvider";
import { useProductsContext } from "../../../utils/contexts/ProductsProvider";
import { useUpdatePagesStateContext } from "../../../utils/contexts/UpdatePagesStateProvider";
import VarietiesListTableContainer from "./CustomTable/VarietiesListTableContainer";

function VarietiesListContainer() {
  const { token } = useAuthenticationContext();
  const { isListVarietiesUpdated, setIsListVarietiesUpdated } =
    useUpdatePagesStateContext();
  const { setListVarieties } = useProductsContext();
  const [progressState, setProgressState] = React.useState(true);
  React.useEffect(() => {
    if (token !== "") {
      const fetchListVarieties = async () => {
        try {
          const result = await apiInstance.get(LIST_VARIETIES_DATA_URL, {
            headers: { Authorization: `Token ${token}` },
          });
          setListVarieties(result.data);
          setProgressState(false);
        } catch (err) {
          setProgressState(false);
          return err;
        }
      };

      fetchListVarieties();
    }
  }, [token, setListVarieties, setProgressState]);
  return (
    <CommonPagesLayout>
      <h1>Variétés</h1>
      {progressState ? <CircularProgress /> : <VarietiesListTableContainer />}
    </CommonPagesLayout>
  );
}

export default VarietiesListContainer;
