import React from "react";
import { toast } from "react-toastify";
import {
  LIST_CODES_DOMMAGES_URL,
  LIST_ENTREE_STOCKS_DATA_URL,
  LIST_LETTRE_VOITURES_DATA_URL,
  LIST_ZONES_DATA_URL,
} from "../api/apiUrls";
import apiInstance from "../api/axios.config";
import { useAuthenticationContext } from "./AuthenticationProvider";
import { useUpdatePagesStateContext } from "./UpdatePagesStateProvider";

const RecordsContext = React.createContext();

export const useRecordsContext = () => {
  return React.useContext(RecordsContext);
};

function RecordsProvider({ children }) {
  const { token } = useAuthenticationContext();
  const [listLettreVoitures, setListLettreVoitures] = React.useState([]);
  const [listEntreeStocks, setListEntreeStocks] = React.useState([]);
  const [listSortieDommages, setListSortieDommages] = React.useState([]);
  const [listCodeDommages, setListCodeDommages] = React.useState([]);
  const [listZones, setListZones] = React.useState([]);
  const [
    listZonesOrderByRegionByDepartmentByCommune,
    setListZonesOrderByRegionByDepartmentByCommune,
  ] = React.useState([]);

  const mountedRef = React.useRef(false);

  const { setIsListEntreeStockUpdated, setIsListCodesDommagesUpdated } =
    useUpdatePagesStateContext();

  const formatListZones = (list) => {
    if (list.length > 0) {
      let tempRegionsArray = [];
      let tempDepartmentsArray = [];
      list.forEach((e) => {
        tempRegionsArray.push(e.region);
        tempDepartmentsArray.push(e.departement);
      });

      let uniqueRegionsArray = [...new Set(tempRegionsArray)];
      let tempDepartmentsByRegionArray = [];
      uniqueRegionsArray.forEach((e) =>
        tempDepartmentsByRegionArray.push({ region: e, departements: [] })
      );
      list.forEach((z) => {
        tempDepartmentsByRegionArray.forEach((d) => {
          if (z.region === d.region) {
            d.departements.push(z.departement);
          }
        });
      });

      tempDepartmentsByRegionArray.forEach((d) => {
        let uniqueDept = [];
        uniqueDept = [...new Set(d.departements)];
        d.departements = [];
        uniqueDept.forEach((uND) => {
          d.departements.push({ departement: uND, communes: [] });
        });
      });

      tempDepartmentsByRegionArray.forEach((r) => {
        r.departements.forEach((d) => {
          list.forEach((z) => {
            if (z.departement === d.departement) {
              d.communes.push({ commune: z.commune });
            }
          });
        });
      });
      setListZonesOrderByRegionByDepartmentByCommune(
        tempDepartmentsByRegionArray
      );
    }
  };

  React.useEffect(() => {
    mountedRef.current = true;
    if (mountedRef.current) {
      if (token !== "") {
        const fetchAllZones = async () => {
          try {
            const result = await apiInstance.get(LIST_ZONES_DATA_URL, {
              headers: { Authorization: `Token ${token}` },
            });
            if (!mountedRef.current) return null;
            formatListZones(result.data);
            setListZones(result.data);
          } catch (err) {
            console.log(err);
          }
        };
        fetchAllZones();
      }
    }

    return () => {
      mountedRef.current = false;
    };
  }, [token, mountedRef]);

  const createEntreeStocks = async (data) => {
    try {
      await apiInstance.post(LIST_ENTREE_STOCKS_DATA_URL, data, {
        headers: { Authorization: `Token ${token}` },
      });
      setIsListEntreeStockUpdated(true);
      toast.success("Entrée Stock créee!", {
        position: "bottom-center",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } catch (err) {
      toast.error(JSON.stringify(err.response.data), {
        position: "bottom-center",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  const deleteEntreeStocks = async (row) => {
    try {
      await apiInstance.delete(LIST_ENTREE_STOCKS_DATA_URL + `${row.id}/`, {
        headers: { Authorization: `Token ${token}` },
      });
      setIsListEntreeStockUpdated(true);
      toast.success("Entrée Stock supprimé!", {
        position: "bottom-center",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } catch (err) {
      toast.error(JSON.stringify(err.response.data), {
        position: "bottom-center",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  const updateEntreeStocks = async (id, data) => {
    try {
      await apiInstance.patch(LIST_ENTREE_STOCKS_DATA_URL + `${id}/`, data, {
        headers: { Authorization: `Token ${token}` },
      });
      setIsListEntreeStockUpdated(true);
      toast.success("Entrée Stock mis à jour!", {
        position: "bottom-center",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } catch (err) {
      toast.error(JSON.stringify(err.response.data), {
        position: "bottom-center",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  const createCodeDommages = async (data) => {
    try {
      await apiInstance.post(LIST_CODES_DOMMAGES_URL, data, {
        headers: { Authorization: `Token ${token}` },
      });
      setIsListCodesDommagesUpdated(true);
      toast.success("Code Dommage crée!", {
        position: "bottom-center",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } catch (err) {
      toast.error(JSON.stringify(err.response.data), {
        position: "bottom-center",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  const updateCodeDommages = async (id, data) => {
    try {
      await apiInstance.patch(LIST_CODES_DOMMAGES_URL + `${id}/`, data, {
        headers: { Authorization: `Token ${token}` },
      });
      setIsListCodesDommagesUpdated(true);
      toast.success("Code Dommage mis à jour!", {
        position: "bottom-center",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } catch (err) {
      toast.error(JSON.stringify(err.response.data), {
        position: "bottom-center",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  const deleteCodeDommages = async (row) => {
    try {
      await apiInstance.delete(LIST_CODES_DOMMAGES_URL + `${row.id}/`, {
        headers: { Authorization: `Token ${token}` },
      });
      setIsListCodesDommagesUpdated(true);
      toast.success("Code Dommage supprimé!", {
        position: "bottom-center",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } catch (err) {
      toast.error(JSON.stringify(err.response.data), {
        position: "bottom-center",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  const deleteLettreVoiture = async (row) => {
    try {
      await apiInstance.delete(LIST_LETTRE_VOITURES_DATA_URL + `${row.id}/`, {
        headers: { Authorization: `Token ${token}` },
      });
      setIsListCodesDommagesUpdated(true);
      toast.success("Lettre voiture supprimé!", {
        position: "bottom-center",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } catch (err) {
      toast.error(JSON.stringify(err.response.data), {
        position: "bottom-center",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  return (
    <RecordsContext.Provider
      value={{
        listZonesOrderByRegionByDepartmentByCommune,
        listCodeDommages,
        listLettreVoitures,
        listEntreeStocks,
        listSortieDommages,
        listZones,
        createEntreeStocks,
        deleteEntreeStocks,
        updateEntreeStocks,
        createCodeDommages,
        updateCodeDommages,
        deleteCodeDommages,
        setListEntreeStocks,
        setListSortieDommages,
        setListLettreVoitures,
        setListCodeDommages,
        deleteLettreVoiture,
      }}
    >
      {children}
    </RecordsContext.Provider>
  );
}

export default RecordsProvider;
