import {
  FormControl,
  Modal,
  Button,
  ButtonGroup,
  FormHelperText,
  Typography,
  TextField,
} from "@mui/material";

import makeStyles from "@mui/styles/makeStyles";
import Backdrop from "@mui/material/Backdrop";
import Fade from "@mui/material/Fade";
import React from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useMarketsContext } from "../../../../utils/contexts/MarketsProvider";
import SelectGroupForRecords from "../../../SelectInput/SelectGroupForRecords/SelectGroupForRecords";
import { useRecordsContext } from "../../../../utils/contexts/RecordsProvider";
import moment from "moment";
import FormModalsWrapper from "../FormModalsWrapper";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    zIndex: "99999",
  },
  paper: {
    "& > *": {
      width: "60ch",
    },
    [theme.breakpoints.down("md")]: {
      "& > *": {
        width: "35ch",
      },
    },
    background: "rgba( 255, 255, 255, 0.90 )",
    boxSshadow: "0 8px 32px 0 rgba( 31, 38, 135, 0.50 )",
    backdropFilter: "blur( 4px )",
    borderRadius: "10px",
    border: "1px solid rgba( 255, 255, 255, 0.18 )",
    height: "80%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "flex-start",
  },
  form: {
    display: "flex",
    width: "100%",
    flexDirection: "column",
    height: "100%",
    gap: "10px",
    justifyContent: "space-between",
    alignItems: "center",
    overflowY: "scroll",
    "&::-webkit-scrollbar": {
      width: "20px",
    },
    "&::-webkit-scrollbar-track": {
      backgroundColor: "transparent",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#172b18",
      borderRadius: "20px",
      border: "6px solid transparent",
      backgroundClip: "content-box",
    },
  },
  formRow: {
    marginTop: "10px",
    marginBottom: "10px",
    [theme.breakpoints.down("md")]: {
      marginTop: "5px",
      marginBottom: "5px",
    },
  },
}));

function MarketsListFormModals({ row, rows, open, handleClose, formAction }) {
  const classes = useStyles();
  const { listZones } = useRecordsContext();
  const { createMarket, updateMarket } = useMarketsContext();
  const formik = useFormik({
    initialValues: {
      id: row.id,
      nom: row.nom,
      reference: row.reference,
      region: row.zone !== undefined ? row.zone.region : "",
      departement: row.zone !== undefined ? row.zone.departement : "",
      commune: row.zone !== undefined ? row.zone.commune : "",
      zoneId: "",
    },
    enableReinitialize: true,
    validateOnChange: true,

    validationSchema: Yup.object({
      id: Yup.string(),
      nom: Yup.string().required("Champ requis!"),
      reference: Yup.string().notRequired(),
      region: Yup.string().required("Champ requis!"),
      departement: Yup.string().required("Champ requis!"),
      commune: Yup.string().required("Champ requis!"),
      zoneId: Yup.number(),
    }),

    onSubmit: async (values, onSubmitProps) => {
      let tempZoneId = "";
      let data = {};

      tempZoneId = listZones.find(
        (e) =>
          e.region === values.region &&
          e.departement === values.departement &&
          e.commune === values.commune
      );
      values.zoneId = tempZoneId.id;
      data = {
        nom: values.nom,
        zone_id: values.zoneId,
        reference:
          values.reference === undefined || values.reference === ""
            ? `MC-${moment(new Date()).format("DDYYYYssmm")}`
            : values.reference,
      };

      onSubmitProps.setSubmitting(true);
      if (formAction === "update") {
        updateMarket(row.id, data);
      } else if (formAction === "add") {
        createMarket(data);
      }
      handleClose();
      //  onSubmitProps.resetForm();
      onSubmitProps.setSubmitting(false);
    },
  });

  return (
    <div>
      <FormModalsWrapper
        open={open}
        title={formAction === "add" ? "NOUVEAU MARCHE" : "MODIFIER MARCHE"}
        handleClose={handleClose}
      >
        <form
          onSubmit={formik.handleSubmit}
          noValidate
          autoComplete="off"
          className={classes.form}
        >
          <FormControl className={classes.formRow}>
            <TextField
              error={formik.errors.nom ? true : false}
              id="nom"
              type="text"
              variant="outlined"
              label="NOM"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.nom}
              size="small"
            />
            <FormHelperText error={formik.errors.nom ? true : false}>
              {formik.errors.nom}
            </FormHelperText>
          </FormControl>
          <SelectGroupForRecords formik={formik} formAction={formAction} />
          <ButtonGroup
            className={classes.formRow}
            variant="contained"
            aria-label="text primary button group"
          >
            <Button color="primary" type="submit">
              Enregistrer
            </Button>
            <Button
              color="secondary"
              onClick={() => {
                handleClose();
                formik.resetForm();
              }}
            >
              Annuler
            </Button>
          </ButtonGroup>
        </form>
      </FormModalsWrapper>
    </div>
  );
}

export default MarketsListFormModals;
