import React from "react";
import {
  FormControl,
  Modal,
  TextField,
  Button,
  ButtonGroup,
  FormHelperText,
  Typography,
  Input,
  NativeSelect,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import Backdrop from "@mui/material/Backdrop";
import Fade from "@mui/material/Fade";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useUsersContext } from "../../../../utils/contexts/UsersProvider";
import { useOrganizationsContext } from "../../../../utils/contexts/OrganizationsProvider";
import convertImgToBase64 from "../../../../utils/helpers";
import { useAuthenticationContext } from "../../../../utils/contexts/AuthenticationProvider";
import FormModalsWrapper from "../FormModalsWrapper";
import { useRecordsContext } from "../../../../utils/contexts/RecordsProvider";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    zIndex: "99999",
  },
  paper: {
    "& > *": {
      width: "60ch",
    },
    [theme.breakpoints.down("md")]: {
      "& > *": {
        width: "35ch",
      },
    },
    background: "rgba( 255, 255, 255, 0.90 )",
    boxSshadow: "0 8px 32px 0 rgba( 31, 38, 135, 0.50 )",
    backdropFilter: "blur( 4px )",
    borderRadius: "10px",
    border: "1px solid rgba( 255, 255, 255, 0.18 )",
    height: "80%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "flex-start",
  },
  form: {
    display: "flex",
    width: "100%",
    flexDirection: "column",
    height: "100%",
    gap: "10px",
    justifyContent: "space-between",
    alignItems: "center",
    overflowY: "scroll",
    "&::-webkit-scrollbar": {
      width: "20px",
    },
    "&::-webkit-scrollbar-track": {
      backgroundColor: "transparent",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#172b18",
      borderRadius: "20px",
      border: "6px solid transparent",
      backgroundClip: "content-box",
    },
  },
  formRow: {
    marginTop: "10px",
    marginBottom: "10px",
    [theme.breakpoints.down("md")]: {
      marginTop: "5px",
      marginBottom: "5px",
    },
  },
}));

function UsersFormModal({ row, open, handleClose, formAction }) {
  const classes = useStyles();
  const { createUser, updateUser } = useUsersContext();
  const { user } = useAuthenticationContext();
  const { listAgregators, listGroupments, listCollectPoints } =
    useOrganizationsContext();
  const { listZones, listZonesOrderByRegionByDepartmentByCommune } =
    useRecordsContext();

  const formik = useFormik({
    initialValues: {
      id: row.id === undefined || row.id === null ? "" : row.id,
      avatar:
        row.photo_base64 === null || row.photo_base64 === undefined
          ? ""
          : row.photo_base64,
      nom: row.user ? row.user.last_name : "",
      prenom: row.user ? row.user.first_name : "",
      email: row.user ? row.user.email : "",
      username: row.user ? row.user.username : "",
      password: "",
      passwordConfirmation: "",
      agregateur: user.is_admin_agregateur
        ? user?.agregateur !== null || user.agregateur !== undefined
          ? user.agregateur.id
          : ""
        : row?.agregateur?.id,
      zone_id: row?.zone_id ? row.zone_id : "",
      region: row?.zone_id
        ? listZones.filter((zone) => zone.id === row.zone_id)[0]?.region
        : "",
      departement: row?.zone_id
        ? listZones.filter((zone) => zone.id === row.zone_id)[0]?.departement
        : "",
      commune: row?.zone_id
        ? listZones.filter((zone) => zone.id === row.zone_id)[0]?.commune
        : "",
      point_collecte: row?.point_collecte?.id,
      groupement: row?.groupement?.id,
      role:
        (row.is_administrateur && "is_administrateur") ||
        (row.is_admin_agregateur && "is_admin_agregateur") ||
        (row.is_admin_groupement && "is_admin_groupement") ||
        (row.is_resp_point_collecte && "is_resp_point_collecte") ||
        (row.is_admin_banque && "is_admin_banque") ||
        (row.is_payeur_banque && "is_payeur_banque") ||
        (row.is_agent_arm && "is_agent_arm") ||
        (row.is_agent_marche && "is_agent_marche") ||
        (row.is_commercant && "is_commercant") ||
        (row.is_producteur && "is_producteur") ||
        (row.is_admin_arm && "is_admin_arm") ||
        (row.is_admin_saed && "is_admin_saed") ||
        "",
    },
    enableReinitialize: true,
    validateOnChange: true,

    validationSchema: Yup.object().shape(
      {
        id: Yup.string().default("").notRequired(),
        avatar: Yup.string().notRequired().default(""),
        nom: Yup.string().default(""),
        prenom: Yup.string().default(""),
        password: Yup.string().when("id", {
          is: "",
          then: Yup.string().required("Champ requis!"),
          otherwise: Yup.string().notRequired(),
        }),
        passwordConfirmation: Yup.string().oneOf(
          [Yup.ref("password"), null],
          "Mot de passe different!"
        ),

        region: Yup.string().when("role", {
          is: (val) =>
            val === "is_admin_saed" ||
            val === "is_admin_agregateur" ||
            val === "is_admin_groupement",
          then: Yup.string().required("Champ requis!"),
          otherwise: Yup.string().notRequired(),
        }),
        departement: Yup.string().when("role", {
          is: (val) =>
            val === "is_admin_agregateur" || val === "is_admin_groupement",
          then: Yup.string().required("Champ requis!"),
          otherwise: Yup.string().notRequired(),
        }),
        commune: Yup.string().when("role", {
          is: (val) =>
            val === "is_admin_agregateur" || val === "is_admin_groupement",
          then: Yup.string().required("Champ requis!"),
          otherwise: Yup.string().notRequired(),
        }),
        email: Yup.string().email("Format incorrect!"),
        username: Yup.number().required("Champ requis!"),
        role: Yup.string().when("id", {
          is: "",
          then: Yup.string().required("Choisir un role!"),
          otherwise: Yup.string().notRequired(),
        }),
        groupement: Yup.string().when("role", {
          is: "is_admin_groupement",
          then: Yup.string().required("Champ requis!"),
          otherwise: Yup.string().notRequired(),
        }),
        agregateur: Yup.string().when("role", {
          is: "is_admin_agregateur",
          then: Yup.string().required("Champ requis!"),
          otherwise: Yup.string().notRequired(),
        }),
        point_collecte: Yup.string().when("role", {
          is: "is_resp_point_collecte",
          then: Yup.string().required("Champ requis!"),
          otherwise: Yup.string().notRequired(),
        }),
      },
      [
        ["groupement", "role"],
        ["agregateur", "role"],
        ["point_collecte", "role"],
        ["password", "id"],
        ["id", "role"],
        ["region", "role"],
        ["departement", "role"],
        ["commune", "role"],
      ]
    ),

    onSubmit: async (values, onSubmitProps) => {
      let data = {};

      let img =
        values.avatar === ""
          ? null
          : typeof values.avatar === "string"
          ? values.avatar
          : await convertImgToBase64(values.avatar);
      onSubmitProps.setSubmitting(true);
      if (formAction === "add") {
        data = {
          user: {
            username: values.username,
            last_name: values.prenom,
            first_name: values.nom,
            email: values.email === undefined ? "" : values.email,
            password: values.password,
          },
          photo_base64: img,
          is_administrateur: values.role === "is_administrateur" ? true : false,
          is_admin_saed: values.role === "is_admin_saed" ? true : false,
          is_admin_agregateur:
            values.role === "is_admin_agregateur" ? true : false,
          is_admin_groupement:
            values.role === "is_admin_groupement" ? true : false,
          is_resp_point_collecte:
            values.role === "is_resp_point_collecte" ? true : false,
          is_admin_banque: values.role === "is_admin_banque" ? true : false,
          is_payeur_banque: values.role === "is_payeur_banque" ? true : false,
          is_agent_arm: values.role === "is_agent_arm" ? true : false,
          is_admin_arm: values.role === "is_admin_arm" ? true : false,
          is_commercant: values.role === "is_commercant" ? true : false,
          is_producteur: values.role === "is_producteur" ? true : false,
          zone_id:
            values.region !== ""
              ? values.role === "is_admin_saed"
                ? listZones.filter((z) => z.region === values.region)[0]?.id
                : listZones.filter(
                    (z) =>
                      z.region === values.region &&
                      z.departement === values.departement &&
                      z.commune === values.commune
                  )[0]?.id
              : null,
          groupement_id:
            values.role === "is_admin_groupement"
              ? JSON.parse(values.groupement)
              : null,
          point_collecte_id:
            values.role === "is_resp_point_collecte"
              ? JSON.parse(values.point_collecte)
              : null,
          agregateur_id:
            values.role === "is_admin_agregateur"
              ? JSON.parse(values.agregateur)
              : null,
        };

        createUser(data);
      } else if (formAction === "update") {
        data = {
          user: {
            last_name: values.nom,
            first_name: values.prenom,
            email: values.email === undefined ? "" : values.email,
          },
          photo_base64: img,
        };

        updateUser(row.id, data);
      }
      handleClose();
      onSubmitProps.setSubmitting(false);
      onSubmitProps.resetForm();
    },
  });

  return (
    <FormModalsWrapper
      open={open}
      title={
        formAction === "add" ? "NOUVEAU UTILISATEUR" : "MODIFIER UTILISATEUR"
      }
      handleClose={handleClose}
    >
      <form
        onSubmit={formik.handleSubmit}
        noValidate
        autoComplete="off"
        className={classes.form}
      >
        <FormControl className={classes.formRow}>
          <Button
            variant="contained"
            color={formik.values.avatar === "" ? "secondary" : "primary"}
            component="label"
          >
            Charger une photo
            <Input
              id="avatar"
              type="file"
              hidden
              onChange={(e) => {
                formik.setFieldValue("avatar", e.target.files[0]);
              }}
            />
          </Button>
        </FormControl>

        <FormControl className={classes.formRow}>
          <TextField
            error={formik.errors.prenom ? true : false}
            id="prenom"
            type="text"
            variant="outlined"
            label="PRENOM"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.prenom}
            size="small"
          />
          <FormHelperText error={formik.errors.prenom ? true : false}>
            {formik.errors.prenom}
          </FormHelperText>
        </FormControl>

        <FormControl className={classes.formRow}>
          <TextField
            error={formik.errors.nom ? true : false}
            id="nom"
            type="text"
            variant="outlined"
            label="NOM"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.nom}
            size="small"
          />
          <FormHelperText error={formik.errors.nom ? true : false}>
            {formik.errors.nom}
          </FormHelperText>
        </FormControl>

        <FormControl className={classes.formRow}>
          <TextField
            error={formik.errors.email ? true : false}
            id="email"
            type="text"
            variant="outlined"
            label="EMAIL"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.email}
            size="small"
          />
          <FormHelperText error={formik.errors.email ? true : false}>
            {formik.errors.email}
          </FormHelperText>
        </FormControl>

        {formAction === "add" && (
          <React.Fragment>
            <FormControl className={classes.formRow}>
              <TextField
                error={formik.errors.username ? true : false}
                id="username"
                type="text"
                variant="outlined"
                label="TELEPHONE"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.username}
                size="small"
              />
              <FormHelperText error={formik.errors.username ? true : false}>
                {formik.errors.username}
              </FormHelperText>
            </FormControl>
            <FormControl className={classes.formRow}>
              <TextField
                error={formik.errors.password ? true : false}
                id="password"
                type="password"
                variant="outlined"
                label="MOT DE PASSE"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.password}
                size="small"
              />
              <FormHelperText error={formik.errors.password ? true : false}>
                {formik.errors.password}
              </FormHelperText>
            </FormControl>
            <FormControl className={classes.formRow}>
              <TextField
                error={formik.errors.passwordConfirmation ? true : false}
                id="passwordConfirmation"
                type="password"
                variant="outlined"
                label="CONFIRMER MOT DE PASSE"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.passwordConfirmation}
                size="large"
                style={{ padding: "0 15px" }}
              />
              <FormHelperText
                error={formik.errors.passwordConfirmation ? true : false}
              >
                {formik.errors.passwordConfirmation}
              </FormHelperText>
            </FormControl>
            <FormControl className={classes.formRow}>
              <NativeSelect
                error={formik.errors.role ? true : false}
                id="role"
                value={formik.values.role}
                onChange={formik.handleChange}
              >
                <option
                  value=""
                  disabled={formAction === "update" ? true : false}
                >
                  Choisir un role
                </option>
                {user.is_administrateur && (
                  <React.Fragment>
                    <option value="is_administrateur">Administrateur</option>
                    <option value="is_admin_saed">Admin SAED</option>
                    <option value="is_admin_agregateur">
                      Admin Agrégateur
                    </option>
                    <option value="is_admin_groupement">
                      Admin Groupement
                    </option>
                    <option value="is_resp_point_collecte">
                      Responsable Point Collecte
                    </option>
                    <option value="is_admin_banque">Admin Banque</option>
                    <option value="is_payeur_banque">Payeur Banque</option>
                    <option value="is_admin_arm">Admin ARM</option>
                    <option value="is_agent_arm">Agent ARM</option>
                    <option value="is_commercant">Commerçant</option>
                    <option value="is_producteur">Producteur</option>
                  </React.Fragment>
                )}

                {user.is_admin_agregateur && (
                  <React.Fragment>
                    <option value="is_admin_agregateur">
                      Admin Agrégateur
                    </option>
                    <option value="is_admin_groupement">
                      Admin Groupement
                    </option>
                  </React.Fragment>
                )}

                {user.is_admin_banque && (
                  <React.Fragment>
                    <option value="is_admin_banque">Admin Banque</option>
                    <option value="is_payeur_banque">Payeur Banque</option>
                  </React.Fragment>
                )}

                {user.is_admin_arm && (
                  <React.Fragment>
                    <option value="is_agent_arm">Agent ARM</option>
                  </React.Fragment>
                )}
              </NativeSelect>
              <FormHelperText error={formik.errors.role ? true : false}>
                {formik.errors.role}
              </FormHelperText>
            </FormControl>
            {formik.values.role === "is_admin_agregateur" && (
              <>
                <FormControl className={classes.formRow}>
                  <NativeSelect
                    error={formik.errors.agregateur ? true : false}
                    disabled={user.is_admin_agregateur ? true : false}
                    id="agregateur"
                    value={
                      user.is_admin_agregateur
                        ? user?.agregateur?.id
                        : formik.values.agregateur
                    }
                    onChange={formik.handleChange}
                  >
                    <option
                      value=""
                      disabled={formAction === "update" ? true : false}
                    >
                      Choisir un agregateur
                    </option>
                    {listAgregators.map((c, index) => (
                      <option key={index} value={c.id}>
                        {c.nom}
                      </option>
                    ))}
                  </NativeSelect>
                  <FormHelperText
                    error={formik.errors.agregateur ? true : false}
                  >
                    {formik.errors.agregateur}
                  </FormHelperText>
                </FormControl>

                <FormControl className={classes.formRow}>
                  <NativeSelect
                    error={formik.errors.region ? true : false}
                    id="region"
                    value={formik.values.region}
                    onChange={formik.handleChange}
                  >
                    <option
                      value=""
                      disabled={formAction === "update" ? true : false}
                    >
                      Choisir une region
                    </option>
                    {listZonesOrderByRegionByDepartmentByCommune.map(
                      (z, index) => (
                        <option key={index} value={z.region}>
                          {z.region}
                        </option>
                      )
                    )}
                  </NativeSelect>
                  <FormHelperText error={formik.errors.region ? true : false}>
                    {formik.errors.region}
                  </FormHelperText>
                </FormControl>

                <FormControl className={classes.formRow}>
                  <NativeSelect
                    error={formik.errors.departement ? true : false}
                    id="departement"
                    value={formik.values.departement}
                    onChange={formik.handleChange}
                    disabled={formik.values.region === "" ? true : false}
                  >
                    <option
                      value=""
                      disabled={formAction === "update" ? true : false}
                    >
                      Choisir une departement
                    </option>
                    {listZonesOrderByRegionByDepartmentByCommune
                      .filter((z) => z.region === formik.values.region)[0]
                      ?.departements.map((r, index) => (
                        <option key={index} value={r.departement}>
                          {r.departement}
                        </option>
                      ))}
                  </NativeSelect>
                  <FormHelperText
                    error={formik.errors.departement ? true : false}
                  >
                    {formik.errors.departement}
                  </FormHelperText>
                </FormControl>

                <FormControl className={classes.formRow}>
                  <NativeSelect
                    error={formik.errors.commune ? true : false}
                    id="commune"
                    value={formik.values.commune}
                    onChange={formik.handleChange}
                    disabled={formik.values.departement === "" ? true : false}
                  >
                    <option
                      value=""
                      disabled={formAction === "update" ? true : false}
                    >
                      Choisir une commune
                    </option>
                    {listZonesOrderByRegionByDepartmentByCommune
                      .filter((z) => z.region === formik.values.region)[0]
                      ?.departements.filter(
                        (d) => d.departement === formik.values.departement
                      )[0]
                      ?.communes.map((c, index) => (
                        <option key={index} value={c.commune}>
                          {c.commune}
                        </option>
                      ))}
                  </NativeSelect>
                  <FormHelperText error={formik.errors.commune ? true : false}>
                    {formik.errors.commune}
                  </FormHelperText>
                </FormControl>
              </>
            )}

            {formik.values.role === "is_admin_saed" && (
              <>
                <FormControl className={classes.formRow}>
                  <NativeSelect
                    error={formik.errors.region ? true : false}
                    id="region"
                    value={formik.values.region}
                    onChange={formik.handleChange}
                  >
                    <option
                      value=""
                      disabled={formAction === "update" ? true : false}
                    >
                      Choisir une region
                    </option>
                    {listZonesOrderByRegionByDepartmentByCommune.map(
                      (z, index) => (
                        <option key={index} value={z.region}>
                          {z.region}
                        </option>
                      )
                    )}
                  </NativeSelect>
                  <FormHelperText error={formik.errors.region ? true : false}>
                    {formik.errors.region}
                  </FormHelperText>
                </FormControl>
              </>
            )}
            {formik.values.role === "is_admin_groupement" && (
              <>
                <FormControl className={classes.formRow}>
                  <NativeSelect
                    error={formik.errors.groupement ? true : false}
                    id="groupement"
                    value={formik.values.groupement}
                    onChange={formik.handleChange}
                  >
                    <option
                      value=""
                      disabled={formAction === "update" ? true : false}
                    >
                      Choisir un groupement
                    </option>
                    {listGroupments.map((c, index) => (
                      <option key={index} value={c.id}>
                        {c.nom}
                      </option>
                    ))}
                  </NativeSelect>
                  <FormHelperText
                    error={formik.errors.groupement ? true : false}
                  >
                    {formik.errors.groupement}
                  </FormHelperText>
                </FormControl>
                <FormControl className={classes.formRow}>
                  <NativeSelect
                    error={formik.errors.region ? true : false}
                    id="region"
                    value={formik.values.region}
                    onChange={formik.handleChange}
                  >
                    <option
                      value=""
                      disabled={formAction === "update" ? true : false}
                    >
                      Choisir une region
                    </option>
                    {listZonesOrderByRegionByDepartmentByCommune.map(
                      (z, index) => (
                        <option key={index} value={z.region}>
                          {z.region}
                        </option>
                      )
                    )}
                  </NativeSelect>
                  <FormHelperText error={formik.errors.region ? true : false}>
                    {formik.errors.region}
                  </FormHelperText>
                </FormControl>

                <FormControl className={classes.formRow}>
                  <NativeSelect
                    error={formik.errors.departement ? true : false}
                    id="departement"
                    value={formik.values.departement}
                    onChange={formik.handleChange}
                    disabled={formik.values.region === "" ? true : false}
                  >
                    <option
                      value=""
                      disabled={formAction === "update" ? true : false}
                    >
                      Choisir une departement
                    </option>
                    {listZonesOrderByRegionByDepartmentByCommune
                      .filter((z) => z.region === formik.values.region)[0]
                      ?.departements.map((r, index) => (
                        <option key={index} value={r.departement}>
                          {r.departement}
                        </option>
                      ))}
                  </NativeSelect>
                  <FormHelperText
                    error={formik.errors.departement ? true : false}
                  >
                    {formik.errors.departement}
                  </FormHelperText>
                </FormControl>

                <FormControl className={classes.formRow}>
                  <NativeSelect
                    error={formik.errors.commune ? true : false}
                    id="commune"
                    value={formik.values.commune}
                    onChange={formik.handleChange}
                    disabled={formik.values.departement === "" ? true : false}
                  >
                    <option
                      value=""
                      disabled={formAction === "update" ? true : false}
                    >
                      Choisir une commune
                    </option>
                    {listZonesOrderByRegionByDepartmentByCommune
                      .filter((z) => z.region === formik.values.region)[0]
                      ?.departements.filter(
                        (d) => d.departement === formik.values.departement
                      )[0]
                      ?.communes.map((c, index) => (
                        <option key={index} value={c.commune}>
                          {c.commune}
                        </option>
                      ))}
                  </NativeSelect>
                  <FormHelperText error={formik.errors.commune ? true : false}>
                    {formik.errors.commune}
                  </FormHelperText>
                </FormControl>
              </>
            )}
            {formik.values.role === "is_resp_point_collecte" && (
              <FormControl className={classes.formRow}>
                <NativeSelect
                  error={formik.errors.point_collecte ? true : false}
                  id="point_collecte"
                  value={formik.values.point_collecte}
                  onChange={formik.handleChange}
                >
                  <option
                    value=""
                    disabled={formAction === "update" ? true : false}
                  >
                    Choisir un point de collecte
                  </option>
                  {listCollectPoints.map((c, index) => (
                    <option key={index} value={c.id}>
                      {c.nom}
                    </option>
                  ))}
                </NativeSelect>
                <FormHelperText
                  error={formik.errors.point_collecte ? true : false}
                >
                  {formik.errors.point_collecte}
                </FormHelperText>
              </FormControl>
            )}
          </React.Fragment>
        )}

        <ButtonGroup
          className={classes.formRow}
          variant="contained"
          aria-label="text primary button group"
        >
          <Button type="submit" color="primary">
            Enregistrer
          </Button>
          <Button color="secondary" onClick={handleClose}>
            Annuler
          </Button>
        </ButtonGroup>
      </form>
    </FormModalsWrapper>
  );
}

export default UsersFormModal;
