import { Avatar, TableCell } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import React from "react";
import PropTypes from "prop-types";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  small: {
    width: theme.spacing(3),
    height: theme.spacing(3),
  },
  large: {
    width: theme.spacing(7),
    height: theme.spacing(7),
  },
}));

/** Component Description:
 *  This component render for each table container,
 *  the row's fields.
 */
function DisplayProductsListTableRows({ row }) {
  const classes = useStyles();
  return (
    <React.Fragment>
      <TableCell align="left">
        <Avatar src={row.image_base64} className={classes.large} />
      </TableCell>
      <TableCell align="center">{row.nom}</TableCell>
      <TableCell align="center">{row.description}</TableCell>
      <TableCell align="center">
        {row.varietes === undefined ? "NEANT" : row.varietes.length}
      </TableCell>
    </React.Fragment>
  );
}
DisplayProductsListTableRows.propTypes = {
  row: PropTypes.object,
};

export default DisplayProductsListTableRows;
