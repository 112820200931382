import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  formControl: {
    minWidth: 120,
  },
  textUpperCase: { textTransform: "uppercase" },
}));

export default useStyles;
