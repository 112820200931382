import { CircularProgress } from "@mui/material";
import React from "react";
import CommonPagesLayout from "../../../components/layouts/Common/CommonPagesLayout";
import { LIST_COLLECT_POINTS_DATA_URL } from "../../../utils/api/apiUrls";
import apiInstance from "../../../utils/api/axios.config";
import { useAuthenticationContext } from "../../../utils/contexts/AuthenticationProvider";
import { useOrganizationsContext } from "../../../utils/contexts/OrganizationsProvider";
import { useUpdatePagesStateContext } from "../../../utils/contexts/UpdatePagesStateProvider";
import CollectPointsTableContainer from "./CustomTable/CollectPointsTableContainer";

function CollectPointsContainer() {
  const { token } = useAuthenticationContext();
  const { setListCollectPoints } = useOrganizationsContext();
  const { isListCollectPointsUpdated, setIsListCollectPointsUpdated } =
    useUpdatePagesStateContext();
  const [progressState, setProgressState] = React.useState(true);
  React.useEffect(() => {
    if (token !== "") {
      const fetchAllCollectPoints = async () => {
        try {
          const result = await apiInstance.get(LIST_COLLECT_POINTS_DATA_URL, {
            headers: { Authorization: `Token ${token}` },
          });
          setListCollectPoints(result.data);
          setProgressState(false);
        } catch (err) {
          console.log(err);
        }
      };
      fetchAllCollectPoints();
    }
  }, [token, setListCollectPoints, setProgressState]);

  React.useEffect(() => {
    if (isListCollectPointsUpdated) {
      setProgressState(true);
      const fetchAllCollectPoints = async () => {
        try {
          const result = await apiInstance.get(LIST_COLLECT_POINTS_DATA_URL, {
            headers: { Authorization: `Token ${token}` },
          });
          setListCollectPoints(result.data);
          setProgressState(false);
        } catch (err) {
          setProgressState(false);
          return err;
        }
      };
      fetchAllCollectPoints();
      setIsListCollectPointsUpdated(false);
    }
  }, [
    isListCollectPointsUpdated,
    setIsListCollectPointsUpdated,
    setListCollectPoints,
    token,
    setProgressState,
  ]);
  return (
    <CommonPagesLayout>
      <h1>Points Collecte</h1>
      {progressState ? <CircularProgress /> : <CollectPointsTableContainer />}
    </CommonPagesLayout>
  );
}

export default CollectPointsContainer;
