import "./App.css";
import { BrowserRouter as Router, Switch } from "react-router-dom";
import AuthenticationProvider from "./utils/contexts/AuthenticationProvider";
import AllRoutes from "./routes/AllRoutes";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import UpdatePagesStateProvider from "./utils/contexts/UpdatePagesStateProvider";
import { createTheme } from "@mui/material";
import { ThemeProvider } from "@mui/styles";

function App() {
  const theme = createTheme();
  return (
    <div className="app">
      <ThemeProvider theme={theme}>
        <ToastContainer />
        <Router>
          <Switch>
            <AllRoutes />
          </Switch>
        </Router>
      </ThemeProvider>
    </div>
  );
}

export default App;
