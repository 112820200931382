import {
  FormControl,
  Modal,
  Button,
  ButtonGroup,
  FormHelperText,
  Typography,
  TextField,
  Input,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import makeStyles from '@mui/styles/makeStyles';
import Backdrop from "@mui/material/Backdrop";
import Fade from "@mui/material/Fade";
import React from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useProductsContext } from "../../../../utils/contexts/ProductsProvider";
import convertImgToBase64 from "../../../../utils/helpers";
import FormModalsWrapper from "../FormModalsWrapper";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    zIndex: "99999",
  },
  paper: {
    "& > *": {
      width: "60ch",
    },
    [theme.breakpoints.down("md")]: {
      "& > *": {
        width: "35ch",
      },
    },
    background: "rgba( 255, 255, 255, 0.90 )",
    boxSshadow: "0 8px 32px 0 rgba( 31, 38, 135, 0.50 )",
    backdropFilter: "blur( 4px )",
    borderRadius: "10px",
    border: "1px solid rgba( 255, 255, 255, 0.18 )",
    height: "80%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "flex-start",
  },
  form: {
    display: "flex",
    width: "100%",
    flexDirection: "column",
    height: "100%",
    gap: "10px",
    justifyContent: "space-between",
    alignItems: "center",
    overflowY: "scroll",
    "&::-webkit-scrollbar": {
      width: "20px",
    },
    "&::-webkit-scrollbar-track": {
      backgroundColor: "transparent",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#172b18",
      borderRadius: "20px",
      border: "6px solid transparent",
      backgroundClip: "content-box",
    },
  },
  formRow: {
    marginTop: "10px",
    marginBottom: "10px",
    [theme.breakpoints.down("md")]: {
      marginTop: "5px",
      marginBottom: "5px",
    },
  },
}));

function ProductsFormModal({ row, open, handleClose, formAction }) {
  const classes = useStyles();
  const { createProduct, updateProduct } = useProductsContext();
  const [expandAccordion, setExpandAccordion] = React.useState(false);
  const [localListVarietes, setLocalListvarietes] = React.useState([]);

  const varieteFormik = useFormik({
    initialValues: {
      image_base64Variete: "",
      nomVariete: "",
      descriptionVariete: "",
    },
    enableReinitialize: false,
    validationSchema: Yup.object({
      image_base64Variete: Yup.string().notRequired(),
      nomVariete: Yup.string().required("Champ requis!"),
      descriptionVariete: Yup.string().notRequired(),
    }),
    onSubmit: async (values, onSubmitProps) => {
      onSubmitProps.setSubmitting(true);
      values.image_base64Variete = await convertImgToBase64(
        values.image_base64Variete
      );
      setLocalListvarietes((prev) => [
        ...prev,
        {
          nom: values.nomVariete,
          description: values.descriptionVariete,
          image_base64: values.image_base64Variete,
        },
      ]);
      setExpandAccordion(false);
      onSubmitProps.setSubmitting(false);
      onSubmitProps.resetForm();
    },
  });

  const formik = useFormik({
    initialValues: {
      id: row.id,
      nom: row.nom,
      description: row.description,
      image_base64:
        row.image_base64 === null || row.image_base64 === undefined
          ? ""
          : row.image_base64,
    },
    enableReinitialize: true,

    validationSchema: Yup.object({
      id: Yup.string(),
      nom: Yup.string().required("Champ requis!"),
      description: Yup.string(),
      image_base64: Yup.string().notRequired(),
    }),

    onSubmit: async (values, onSubmitProps) => {
      let img =
        values.image_base64 === ""
          ? null
          : typeof values.image_base64 === "string"
          ? values.image_base64
          : await convertImgToBase64(values.image_base64);

      onSubmitProps.setSubmitting(true);
      if (formAction === "update") {
        updateProduct(row.id, {
          nom: values.nom,
          description: values.description,
          image_base64: img,
        });
      } else if (formAction === "add") {
        if (localListVarietes.length === 0) {
          createProduct({
            nom: values.nom,
            description: values.description,
            image_base64: img,
          });
        } else {
          createProduct({
            nom: values.nom,
            description: values.description,
            image_base64: img,
            varietes: localListVarietes,
          });
        }
      }
      handleClose();
      setLocalListvarietes([]);
      onSubmitProps.setSubmitting(false);
      onSubmitProps.resetForm();
    },
  });

  return (
    <FormModalsWrapper
      handleClose={handleClose}
      open={open}
      title={formAction === "add" ? "NOUVEAU PRODUIT" : "MODIFIER PRODUIT"}
    >
      <form
        onSubmit={formik.handleSubmit}
        noValidate
        autoComplete="off"
        className={classes.form}
      >
        <FormControl className={classes.formRow}>
          <Button
            variant="contained"
            component="label"
            color={formik.values.image_base64 === "" ? "secondary" : "primary"}
          >
            Charger une photo
            <Input
              id="image_base64"
              type="file"
              hidden
              onChange={(e) => {
                formik.setFieldValue("image_base64", e.target.files[0]);
              }}
            />
          </Button>
        </FormControl>

        <FormControl className={classes.formRow}>
          <TextField
            error={formik.errors.nom ? true : false}
            id="nom"
            type="text"
            variant="outlined"
            label="NOM"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.nom}
            size="small"
          />
          <FormHelperText error={formik.errors.nom ? true : false}>
            {formik.errors.nom}
          </FormHelperText>
        </FormControl>

        <FormControl className={classes.formRow}>
          <TextField
            error={formik.errors.description ? true : false}
            id="description"
            type="text"
            variant="outlined"
            label="DESCRIPTION"
            multiline
            rows={5}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.description}
            size="small"
          />
          <FormHelperText error={formik.errors.description ? true : false}>
            {formik.errors.description}
          </FormHelperText>
        </FormControl>
        {/* <Accordion
                className={classes.accordion}
                disabled={formAction === "add" ? false : true}
                expanded={expandAccordion}
              >
                <AccordionSummary
                  expandIcon={
                    <ExpandMoreIcon
                      onClick={() => setExpandAccordion((prev) => !prev)}
                    />
                  }
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                  className={classes.accordionHead}
                >
                  <Typography className={classes.heading}>
                    Ajouter variété
                  </Typography>
                </AccordionSummary>
                <AccordionDetails className={classes.accordionDetails}>
                  <form
                    className={classes.formAddVariety}
                    onSubmit={varieteFormik.handleSubmit}
                    noValidate
                    autoComplete="off"
                  >
                    <FormControl className={classes.formRow}>
                      <Button
                        variant="contained"
                        component="label"
                        color={
                          varieteFormik.values.image_base64Variete === ""
                            ? "secondary"
                            : "primary"
                        }
                      >
                        Charger une photo
                        <Input
                          id="image_base64Variete"
                          type="file"
                          hidden
                          onChange={(e) => {
                            varieteFormik.setFieldValue(
                              "image_base64Variete",
                              e.target.files[0]
                            );
                          }}
                        />
                      </Button>
                    </FormControl>

                    <FormControl className={classes.formRow}>
                      <TextField
                        error={varieteFormik.errors.nomVariete ? true : false}
                        id="nomVariete"
                        type="text"
                        variant="outlined"
                        label="NOM"
                        onChange={varieteFormik.handleChange}
                        onBlur={varieteFormik.handleBlur}
                        value={varieteFormik.values.nomVariete}
                        size="small"
                      />
                      <FormHelperText
                        error={varieteFormik.errors.nomVariete ? true : false}
                      >
                        {varieteFormik.errors.nomVariete}
                      </FormHelperText>
                    </FormControl>
                    <FormControl className={classes.formRow}>
                      <TextField
                        error={
                          varieteFormik.errors.descriptionVariete ? true : false
                        }
                        id="descriptionVariete"
                        type="text"
                        variant="outlined"
                        label="DESCRIPTION"
                        multiline
                        rows={5}
                        onChange={varieteFormik.handleChange}
                        onBlur={varieteFormik.handleBlur}
                        value={varieteFormik.values.descriptionVariete}
                        size="small"
                      />
                      <FormHelperText
                        error={
                          varieteFormik.errors.descriptionVariete ? true : false
                        }
                      >
                        {varieteFormik.errors.descriptionVariete}
                      </FormHelperText>
                    </FormControl>
                    <ButtonGroup
                      className={classes.formRow}
                      variant="text"
                      color="primary"
                      aria-label="text primary button group"
                    >
                      <Button
                        onClick={varieteFormik.submitForm}
                        variant="contained"
                        color="primary"
                      >
                        Ajouter
                      </Button>
                      <Button
                        variant="contained"
                        color="secondary"
                        onClick={() => {
                          varieteFormik.resetForm();
                          setExpandAccordion(false);
                        }}
                      >
                        Annuler
                      </Button>
                    </ButtonGroup>
                  </form>
                </AccordionDetails>
              </Accordion> */}
        <ButtonGroup
          className={classes.formRow}
          variant="text"
          color="primary"
          aria-label="text primary button group"
        >
          <Button type="submit" variant="contained" color="primary">
            Enregistrer
          </Button>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => {
              handleClose();
              formik.resetForm();
            }}
          >
            Annuler
          </Button>
        </ButtonGroup>
      </form>
    </FormModalsWrapper>
  );
}

export default ProductsFormModal;
