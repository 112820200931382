import React from "react";
import { toast } from "react-toastify";
import {
  LIST_PRODUCTS_DATA_URL,
  LIST_VARIETIES_DATA_URL,
} from "../api/apiUrls";
import apiInstance from "../api/axios.config";
import { useAuthenticationContext } from "./AuthenticationProvider";
import { useUpdatePagesStateContext } from "./UpdatePagesStateProvider";

const ProductsContext = React.createContext();

export const useProductsContext = () => {
  return React.useContext(ProductsContext);
};

function ProductsProvider({ children }) {
  const mountedRef = React.useRef(true);
  const [listProducts, setListProducts] = React.useState([]);
  const [listVarieties, setListVarieties] = React.useState([]);
  const { token } = useAuthenticationContext();
  const { setIsListProductsUpdated, setIsListVarietiesUpdated } =
    useUpdatePagesStateContext();

  React.useEffect(() => {
    return () => {
      mountedRef.current = false;
    };
  }, []);

  const createProduct = React.useCallback(
    async (data) => {
      try {
        await apiInstance.post(LIST_PRODUCTS_DATA_URL, data, {
          headers: { Authorization: `Token ${token}` },
        });
        if (!mountedRef.current) return null;
        setIsListProductsUpdated(true);
        toast.success("Produit crée!", {
          position: "bottom-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } catch (err) {
        toast.error(JSON.stringify(err.response.data), {
          position: "bottom-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        console.log(err);
      }
    },
    [mountedRef, setIsListProductsUpdated, token]
  );

  const updateProduct = React.useCallback(
    async (id, data) => {
      try {
        await apiInstance.patch(LIST_PRODUCTS_DATA_URL + `${id}/`, data, {
          headers: { Authorization: `Token ${token}` },
        });
        if (!mountedRef.current) return null;
        setIsListProductsUpdated(true);
        toast.success("Produit mis à jour!", {
          position: "bottom-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } catch (err) {
        toast.error(JSON.stringify(err.response.data), {
          position: "bottom-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    },
    [mountedRef, setIsListProductsUpdated, token]
  );

  const deleteProduct = React.useCallback(
    async (row) => {
      try {
        await apiInstance.delete(LIST_PRODUCTS_DATA_URL + `${row.id}/`, {
          headers: { Authorization: `Token ${token}` },
        });
        if (!mountedRef.current) return null;
        setIsListProductsUpdated(true);
        toast.success("Produit supprimé!", {
          position: "bottom-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } catch (err) {
        toast.error(JSON.stringify(err.response.data), {
          position: "bottom-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    },
    [mountedRef, setIsListProductsUpdated, token]
  );

  const createVariety = React.useCallback(
    async (data) => {
      try {
        await apiInstance.post(LIST_VARIETIES_DATA_URL, data, {
          headers: { Authorization: `Token ${token}` },
        });
        if (!mountedRef.current) return null;
        setIsListVarietiesUpdated(true);
        toast.success("Variété cree!", {
          position: "bottom-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } catch (err) {
        toast.error(JSON.stringify(err.response.data), {
          position: "bottom-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    },
    [mountedRef, setIsListVarietiesUpdated, token]
  );

  const updateVariety = React.useCallback(
    async (id, data) => {
      try {
        await apiInstance.put(LIST_VARIETIES_DATA_URL + `${id}/`, data, {
          headers: { Authorization: `Token ${token}` },
        });
        if (!mountedRef.current) return null;
        setIsListVarietiesUpdated(true);
        toast.success("Variété mis à jour!", {
          position: "bottom-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } catch (err) {
        toast.error(JSON.stringify(err.response.data), {
          position: "bottom-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    },
    [mountedRef, setIsListVarietiesUpdated, token]
  );

  const deleteVariety = React.useCallback(
    async (row) => {
      try {
        await apiInstance.delete(LIST_VARIETIES_DATA_URL + `${row.id}/`, {
          headers: { Authorization: `Token ${token}` },
        });
        if (!mountedRef.current) return null;
        setIsListVarietiesUpdated(true);
        toast.success("Variété supprimé!", {
          position: "bottom-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } catch (err) {
        toast.error(JSON.stringify(err.response.data), {
          position: "bottom-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    },
    [mountedRef, setIsListVarietiesUpdated, token]
  );

  return (
    <ProductsContext.Provider
      value={{
        listProducts,
        listVarieties,
        setListProducts,
        setListVarieties,
        createProduct,
        updateProduct,
        deleteProduct,
        createVariety,
        updateVariety,
        deleteVariety,
      }}
    >
      {children}
    </ProductsContext.Provider>
  );
}

export default ProductsProvider;
