import {
  Avatar,
  CircularProgress,
  Container,
  Paper,
  Typography,
} from "@mui/material";
import React from "react";

import { useParams } from "react-router-dom";
import apiInstance from "../../../../utils/api/axios.config";
import { LIST_USERS_DATA_URL } from "../../../../utils/api/apiUrls";
import { useAuthenticationContext } from "../../../../utils/contexts/AuthenticationProvider";
import useStyles from "./style";
import defaultUser from "../../../../assets/defaultUser.png";
import PhoneAndroidIcon from "@mui/icons-material/PhoneAndroid";
import AlternateEmailIcon from "@mui/icons-material/AlternateEmail";
import VerifiedUserIcon from "@mui/icons-material/VerifiedUser";
import LanguageIcon from "@mui/icons-material/Language";
import CommonPagesLayout from "../../../../components/layouts/Common/CommonPagesLayout";

function DetailsUserContainer() {
  const [detailsUser, setDetailsUser] = React.useState({
    user: { first_name: "", last_name: "", email: "" },
  });
  const { token } = useAuthenticationContext();
  let { id } = useParams();
  const classes = useStyles();
  React.useEffect(() => {
    if (token !== "") {
      const fetchDetailsUser = async () => {
        try {
          const result = await apiInstance.get(LIST_USERS_DATA_URL + `${id}/`, {
            headers: { Authorization: `Token ${token}` },
          });
          setDetailsUser(result.data);
        } catch (err) {
          console.log(err);
          return;
        }
      };

      fetchDetailsUser();
    }
  }, [id, token, setDetailsUser]);

  return (
    <CommonPagesLayout>
      <h1>Détails Utilisateur</h1>
      {detailsUser === "" ? (
        <CircularProgress />
      ) : (
        <Paper className={classes.rootContainer} elevation={3}>
          <div className={classes.rootContainerItems}>
            <Container className={classes.item}>
              <Avatar
                alt={`${detailsUser.user?.first_name} ${detailsUser.user?.last_name}`}
                className={classes.avatar}
                src={
                  detailsUser.photo_base64 === "" ||
                  detailsUser.photo_base64 == null
                    ? defaultUser
                    : detailsUser.photo_base64
                }
                styles={{ width: "10rem", height: "10rem" }}
              />
              <Typography variant="h4" className={classes.typo}>
                {`${detailsUser.user?.first_name} ${detailsUser.user?.last_name}`}
              </Typography>
            </Container>
            <Container className={classes.item}>
              <PhoneAndroidIcon className={classes.avatar} />
              <Typography variant="h4" className={classes.typo}>
                {detailsUser.user?.username}
              </Typography>
            </Container>
            <Container className={classes.item}>
              <AlternateEmailIcon className={classes.avatar} />
              <Typography variant="h4" className={classes.typo}>
                {detailsUser.user?.email}
              </Typography>
            </Container>
          </div>

          <Container className={classes.rootContainerItems}>
            <Container className={classes.item}>
              <VerifiedUserIcon
                alt="role"
                className={classes.avatar}
                src={defaultUser}
                style={{ color: "green" }}
              />
              <Typography variant="h4" className={classes.typo}>
                {detailsUser.is_administrateur && "Administrateur"}
                {detailsUser.is_admin_agregateur && "Administrateur Agregateur"}
                {detailsUser.is_admin_groupement && "Administrateur Groupement"}
                {detailsUser.is_resp_point_collecte &&
                  "Responsable  point de collecte"}
                {detailsUser.is_admin_banque && "Administrateur Banque"}
                {detailsUser.is_payeur_banque && "Payeur Banque"}
                {detailsUser.is_agent_arm && "Agent ARM"}
                {detailsUser.is_commercant && "Commercant"}
                {detailsUser.is_agent_marche && "Agent Marché"}
                {detailsUser.is_producteur && "Producteur"}
              </Typography>
            </Container>
            {(detailsUser.is_admin_agregateur ||
              detailsUser.is_administrateur ||
              detailsUser.is_admin_groupement ||
              detailsUser.is_resp_point_collecte ||
              detailsUser.is_admin_banque ||
              detailsUser.is_payeur_banque) && (
              <React.Fragment>
                <Container className={classes.item}>
                  <LanguageIcon
                    alt="organisation"
                    className={classes.avatar}
                    src={defaultUser}
                  />
                  <Typography variant="h4" className={classes.typo}>
                    fgdfg
                  </Typography>
                </Container>
              </React.Fragment>
            )}
          </Container>
        </Paper>
      )}
    </CommonPagesLayout>
  );
}

export default DetailsUserContainer;
