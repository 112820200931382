import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Avatar,
  CircularProgress,
  Divider,
  List,
  Typography,
} from "@mui/material";
import React from "react";
import { useParams } from "react-router-dom";
import { LIST_LETTRE_VOITURES_DATA_URL } from "../../../../utils/api/apiUrls";
import apiInstance from "../../../../utils/api/axios.config";
import { useAuthenticationContext } from "../../../../utils/contexts/AuthenticationProvider";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CommonPagesLayout from "../../../../components/layouts/Common/CommonPagesLayout";
import useStyles from "./styles";

function DetailsLettreVoitureContainer() {
  const [detailsLettre, setDetailsLettre] = React.useState("");
  const { token } = useAuthenticationContext();
  let { id } = useParams();
  const classes = useStyles();

  React.useEffect(() => {
    if (token !== "") {
      const fetchDetailsLettrevoiture = async () => {
        try {
          const result = await apiInstance.get(
            LIST_LETTRE_VOITURES_DATA_URL + `${id}/`,
            {
              headers: { Authorization: `Token ${token}` },
            }
          );
          setDetailsLettre(result.data);
        } catch (err) {
          console.log(err);
          return;
        }
      };

      fetchDetailsLettrevoiture();
    }
  }, [token, id]);

  return (
    <CommonPagesLayout>
      <h1>Details Lettre Voiture</h1>
      {detailsLettre === "" ? (
        <CircularProgress />
      ) : (
        <div className={classes.content}>
          {detailsLettre !== "" && (
            <div className={classes.contentContainer}>
              <Accordion className={classes.accordion} defaultExpanded>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography>
                    {"Référence: " + detailsLettre.reference}
                  </Typography>
                </AccordionSummary>
                <Divider />
                <AccordionDetails>
                  <List>
                    <Typography primary="Inbox">
                      {"Chauffeur: " + detailsLettre.chauffeur}
                    </Typography>{" "}
                    <Typography primary="Inbox">
                      {"Destination: " + detailsLettre.destination}
                    </Typography>
                  </List>
                </AccordionDetails>
                <Divider />
                <AccordionDetails>
                  <List>
                    <Typography primary="Inbox">
                      <Typography variant="h5">INFO COMMERCANT: </Typography>
                      {"Autre commerçant: " + detailsLettre.commercant_autre}
                    </Typography>

                    {detailsLettre.commercant !== null && (
                      <React.Fragment>
                        {" "}
                        <Typography primary="Inbox">
                          {"Nom commerçant: " + detailsLettre.commercant.nom}
                        </Typography>
                        <Typography primary="Inbox">
                          {"Prénom commerçant: " +
                            detailsLettre.commercant.prenom}
                        </Typography>
                        <Typography primary="Inbox">
                          {"Adresse commerçant: " +
                            detailsLettre.commercant.adresse}
                        </Typography>
                        <Typography primary="Inbox">
                          {"Téléphone commerçant: " +
                            detailsLettre.commercant.telephone}
                        </Typography>
                        <Typography primary="Inbox">
                          {"CNI commerçant: " + detailsLettre.commercant.cni}
                        </Typography>
                      </React.Fragment>
                    )}
                  </List>
                </AccordionDetails>
                <Divider />
                <AccordionDetails>
                  <List>
                    <Typography variant="h5">INFO MOUVEMENTS: </Typography>
                    {detailsLettre.mvts.map((mvt) => (
                      <List>
                        <Typography>{mvt.reference}</Typography>
                        <Typography>{"Type MVT: " + mvt.type_mvt}</Typography>
                        <Typography>
                          {mvt.sac_25_KG ? "SAC 25KG: VRAI" : "SAC 25KG: FAUX"}
                        </Typography>
                        <Typography>
                          {mvt.sac_40_KG ? "SAC 40KG: VRAI" : "SAC 40KG: FAUX"}
                        </Typography>
                        <Typography>
                          {"Nombre de Sac: " + mvt.nombre_sac}
                        </Typography>
                        <Typography>
                          {"Poids pesée: " + mvt?.poids_pesee}
                        </Typography>
                        <Typography>
                          {"Prix kilo: " + mvt?.prix_kilo}
                        </Typography>
                        <Typography>
                          {"Frais coxeur: " + mvt?.frais_coxeur}
                        </Typography>
                        <Typography>
                          {"Date mouvement: " + mvt?.date_mvt}
                        </Typography>
                        <Typography>
                          {`Coxeur: ${mvt?.stock?.coxeur?.nom} ${mvt?.stock?.coxeur?.prenom} ${mvt?.stock?.coxeur?.telephone}`}
                        </Typography>
                        <div className={classes.showVariete}>
                          <Typography>
                            {"Variété: " + mvt?.stock?.variete?.nom}
                          </Typography>
                          <Avatar src={mvt?.stock?.variete?.image_base64} />
                        </div>
                      </List>
                    ))}
                  </List>
                </AccordionDetails>
              </Accordion>
            </div>
          )}
        </div>
      )}
    </CommonPagesLayout>
  );
}

export default DetailsLettreVoitureContainer;
