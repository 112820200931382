import { CircularProgress } from "@mui/material";
import React from "react";
import CustomTable from "../../../../components/Table/CustomTable/CustomTable";
import DisplayLettreVoituresTableRows from "../../../../components/Table/CustomTable/TableContext/LettreVoituresTableCells/DisplayLettreVoituresTableRows";
import { exportTableListLettreVoitures } from "../../../../services/filesTransfer";
import LettresVoituresFormModal from "../../../../ui/Modals/FormModals/Records/LettresVoituresFormModal";
import { useRecordsContext } from "../../../../utils/contexts/RecordsProvider";
import moment from "moment";
import { useAuthenticationContext } from "../../../../utils/contexts/AuthenticationProvider";

function LettreVoituresTableContainer() {
  const { user } = useAuthenticationContext();
  const { listLettreVoitures, deleteLettreVoiture } = useRecordsContext();
  const formModalInitialRow = {
    id: "",
    reference: "",
    chaufffeur: "",
    camion_immat: "",
    destination: "",
    commercant: "",
    mvts: "",
  };

  const headCells = [
    { label: "Reference", id: "REFERENCE" },
    { label: "Chauffeur", id: "CHAUFFEUR" },
    { label: "Immatriculation camion", id: "CAMION_IMMAT" },
    { label: "Destination", id: "DESTINATION" },
    { label: "Commercant", id: "COMMERCANT" },
    { label: "Autre commerçant", id: "COMMERCANT_AUTRE" },
  ];

  const [filteredRows, setFilteredRows] = React.useState([]);
  const [inputSearchValue, setInputSearchValue] = React.useState("");
  const [formAction, setFormAction] = React.useState("");
  const [rows, setRows] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [startDate, setStartDate] = React.useState("");
  const [endDate, setEndDate] = React.useState("");
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [filterValue, setFilterValue] = React.useState(headCells[0].id || "");
  const [filter, setFilter] = React.useState({
    fn: (items) => {
      return items;
    },
  });
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState(headCells[0].id);

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

  const handleSearch = (event) => {
    let searchQuery = event.target.value;
    setInputSearchValue(searchQuery);
    setFilter({
      fn: (items) => {
        if (searchQuery === "") return items;
        else
          return items.filter((x) => {
            return (
              x["reference"]
                .toLowerCase()
                .includes(searchQuery.toLowerCase()) ||
              x["chauffeur"]
                .toLowerCase()
                .includes(searchQuery.toLowerCase()) ||
              x["camion_immat"]
                .toLowerCase()
                .includes(searchQuery.toLowerCase()) ||
              x["destination"]
                .toLowerCase()
                .includes(searchQuery.toLowerCase()) ||
              x["commercant_autre"]
                ?.toLowerCase()
                .includes(searchQuery.toLowerCase()) ||
              (x.commercant !== null &&
                x["commercant"]["nom"]
                  ?.toLowerCase()
                  .includes(searchQuery.toLowerCase())) ||
              (x.commercant !== null &&
                x["commercant"]["prenom"]
                  ?.toLowerCase()
                  .includes(searchQuery.toLowerCase()))
            );
          });
      },
    });
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSortRequest = (cellId) => {
    const isASC = orderBy === cellId && order === "asc";
    setOrder(isASC ? "desc" : "asc");
    setOrderBy(cellId);
  };

  function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  const getComparator = React.useCallback((order, orderBy) => {
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }, []);

  function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }

  const recordsAfterPaginationSortingAndFiltering = () => {
    return stableSort(filter.fn(rows), getComparator(order, orderBy)).slice(
      page * rowsPerPage,
      (page + 1) * rowsPerPage
    );
  };

  React.useEffect(() => {
    setRows(listLettreVoitures);
  }, [listLettreVoitures]);

  React.useEffect(() => {
    function stableSort(array, comparator) {
      const stabilizedThis = array.map((el, index) => [el, index]);
      stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
      });
      setFilteredRows(stabilizedThis.map((el) => el[0]));
    }
    stableSort(filter.fn(rows), getComparator(order, orderBy));
  }, [
    inputSearchValue,
    setFilteredRows,
    getComparator,
    rows,
    order,
    orderBy,
    filter,
  ]);

  React.useEffect(() => {
    if (startDate !== "" && endDate !== "" && listLettreVoitures.length > 0) {
      let tempList = [];
      tempList = listLettreVoitures.filter((lv) => {
        return (
          moment(lv.created_at).isBetween(startDate, endDate, "days", "[]") &&
          lv
        );
      });
      setRows(tempList);
    } else {
      setRows(listLettreVoitures);
    }
  }, [listLettreVoitures, startDate, endDate]);

  return (
    <React.Fragment>
      <CustomTable
        tableName="lettre-voitures"
        TableContext={DisplayLettreVoituresTableRows}
        headCells={headCells}
        rows={rows}
        rowsPerPage={rowsPerPage}
        page={page}
        emptyRows={emptyRows}
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        order={order}
        orderBy={orderBy}
        handleSortRequest={handleSortRequest}
        recordsAfterPaginationSortingAndFiltering={
          recordsAfterPaginationSortingAndFiltering
        }
        filterLabel="Filtrer par:"
        filterValue={filterValue}
        setFilterValue={setFilterValue}
        handleSearch={handleSearch}
        stableSort={stableSort}
        getComparator={getComparator}
        filter={filter}
        setRows={setRows}
        enableEdit={true}
        formModalInitialRow={formModalInitialRow}
        FormModal={LettresVoituresFormModal}
        formAction={formAction}
        setFormAction={setFormAction}
        disableSelectFilterValue={true}
        enableMoreDetailsIcon={true}
        disableAddButton={true}
        disableEditIcon={true}
        moreDetailsPath="/enregistrements/lettrevoitures/"
        customExport={exportTableListLettreVoitures}
        setInputSearchValue={setInputSearchValue}
        filteredRows={filteredRows}
        inputSearchValue={inputSearchValue}
        disableLoadButton={true}
        toolTipMessage="Filter par date de création"
        disableTemplateButton={true}
        enableDateStartEndSearch={true}
        startDate={startDate}
        setStartDate={setStartDate}
        endDate={endDate}
        setEndDate={setEndDate}
        handleDeleteRow={deleteLettreVoiture}
        disableDeleteIcon={user.is_administrateur ? false : true}
      />
    </React.Fragment>
  );
}

export default LettreVoituresTableContainer;
