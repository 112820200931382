import * as XLSX from "xlsx";
import * as FileSaver from "file-saver";

const fileType =
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";

const alphabetArray = [
  "A",
  "B",
  "C",
  "D",
  "E",
  "F",
  "G",
  "H",
  "I",
  "J",
  "K",
  "L",
  "M",
  "N",
  "O",
  "P",
  "Q",
  "R",
  "S",
  "T",
  "U",
  "V",
  "W",
  "X",
  "Y",
  "Z",
];

export const exportTable = (tableData, fileName, fileExtension) => {
  const workSheet = XLSX.utils.json_to_sheet(tableData);

  const workBook = { Sheets: { data: workSheet }, SheetNames: ["data"] };
  // Buffer
  const buffer = XLSX.write(workBook, { bookType: "xlsx", type: "array" });

  const data = new Blob([buffer], { type: fileType });

  // Download
  FileSaver.saveAs(data, fileName + `.` + fileExtension);
};

export const importFileTable = (file /*:File*/, setJSONToSend) => {
  /* Boilerplate to set up FileReader */
  const reader = new FileReader();
  const rABS = !!reader.readAsBinaryString;
  reader.onload = (e) => {
    /* Parse data */
    const bstr = e.target.result;
    const wb = XLSX.read(bstr, { type: rABS ? "binary" : "array" });
    /* Get first worksheet */
    const wsname = wb.SheetNames[0];
    const ws = wb.Sheets[wsname];

    /* Convert array of arrays */
    const data = XLSX.utils.sheet_to_json(ws, { header: 0 });
    /* Update state */
    setJSONToSend(data);
  };
  if (rABS) reader.readAsBinaryString(file);
  else reader.readAsArrayBuffer(file);
};

export const exportTableProduits = (tableData, fileName, fileExtension) => {
  const workSheet = XLSX.utils.json_to_sheet(tableData);

  workSheet["A1"] = { t: "s", v: "nom" };
  workSheet["B1"] = { t: "s", v: "description" };
  workSheet["C1"] = { t: "s", v: "varietes" };

  tableData.forEach((tb, i) => {
    workSheet[`A${i + 2}`] = workSheet[`B${i + 2}`];
    workSheet[`B${i + 2}`] = workSheet[`D${i + 2}`];
  });

  tableData.forEach((tb, i) => {
    let concactVr = "";
    workSheet[`E${i + 2}`].length > 0 &&
      workSheet[`E${i + 2}`].forEach((vr) => {
        concactVr = concactVr + `${vr.nom},`;
      });

    concactVr = { t: "s", v: concactVr };
    workSheet[`C${i + 2}`] = concactVr;
    delete workSheet[`D${i + 2}`];
    delete workSheet[`E${i + 2}`];
    delete workSheet[`F${i + 2}`];
    delete workSheet[`G${i + 2}`];
    delete workSheet[`H${i + 2}`];
    delete workSheet[`I${i + 2}`];
  });

  delete workSheet["D1"];
  delete workSheet["E1"];
  delete workSheet["F1"];
  delete workSheet["G1"];
  delete workSheet["H1"];
  delete workSheet["I1"];

  const workBook = { Sheets: { data: workSheet }, SheetNames: ["data"] };
  // Buffer
  const buffer = XLSX.write(workBook, { bookType: "xlsx", type: "array" });

  const data = new Blob([buffer], { type: fileType });

  // Download
  FileSaver.saveAs(data, fileName + `.` + fileExtension);
};

export const exportTableListVarietesOfProduct = (
  tableData,
  fileName,
  fileExtension
) => {
  const workSheet = XLSX.utils.json_to_sheet(tableData);

  workSheet["A1"] = { t: "s", v: "nom" };
  workSheet["B1"] = { t: "s", v: "description" };

  tableData.forEach((tb, i) => {
    workSheet[`A${i + 2}`] = workSheet[`B${i + 2}`];
    workSheet[`B${i + 2}`] = workSheet[`D${i + 2}`];
  });

  tableData.forEach((tb, i) => {
    delete workSheet[`C${i + 2}`];
    delete workSheet[`D${i + 2}`];
  });

  delete workSheet["C1"];
  delete workSheet["D1"];

  const workBook = { Sheets: { data: workSheet }, SheetNames: ["data"] };
  // Buffer
  const buffer = XLSX.write(workBook, { bookType: "xlsx", type: "array" });

  const data = new Blob([buffer], { type: fileType });

  // Download
  FileSaver.saveAs(data, fileName + `.` + fileExtension);
};

export const exportTableListVarietes = (tableData, fileName, fileExtension) => {
  const workSheet = XLSX.utils.json_to_sheet(tableData);

  workSheet["A1"] = { t: "s", v: "nom" };
  workSheet["B1"] = { t: "s", v: "description" };

  tableData.forEach((tb, i) => {
    workSheet[`A${i + 2}`] = workSheet[`B${i + 2}`];
    workSheet[`B${i + 2}`] = workSheet[`D${i + 2}`];
  });

  tableData.forEach((tb, i) => {
    delete workSheet[`C${i + 2}`];
    delete workSheet[`D${i + 2}`];
    delete workSheet[`E${i + 2}`];
    delete workSheet[`F${i + 2}`];
    delete workSheet[`G${i + 2}`];
    delete workSheet[`H${i + 2}`];
    delete workSheet[`I${i + 2}`];
  });

  delete workSheet["C1"];
  delete workSheet["D1"];
  delete workSheet["E1"];
  delete workSheet["F1"];
  delete workSheet["G1"];
  delete workSheet["H1"];
  delete workSheet["I1"];

  const workBook = { Sheets: { data: workSheet }, SheetNames: ["data"] };
  // Buffer
  const buffer = XLSX.write(workBook, { bookType: "xlsx", type: "array" });

  const data = new Blob([buffer], { type: fileType });

  // Download
  FileSaver.saveAs(data, fileName + `.` + fileExtension);
};

export const exportTableListAgregateurs = (
  tableData,
  fileName,
  fileExtension
) => {
  const workSheet = XLSX.utils.json_to_sheet(tableData);

  workSheet["A1"] = { t: "s", v: "nom" };

  tableData.forEach((tb, i) => {
    workSheet[`A${i + 2}`] = workSheet[`B${i + 2}`];
    delete workSheet[`B${i + 2}`];
    delete workSheet[`C${i + 2}`];
    delete workSheet[`D${i + 2}`];
    delete workSheet[`E${i + 2}`];
    delete workSheet[`F${i + 2}`];
    delete workSheet[`G${i + 2}`];
    delete workSheet[`H${i + 2}`];
  });

  delete workSheet["B1"];
  delete workSheet["C1"];
  delete workSheet["D1"];
  delete workSheet["E1"];
  delete workSheet["F1"];
  delete workSheet["G1"];
  delete workSheet["H1"];

  const workBook = { Sheets: { data: workSheet }, SheetNames: ["data"] };
  // Buffer
  const buffer = XLSX.write(workBook, { bookType: "xlsx", type: "array" });

  const data = new Blob([buffer], { type: fileType });

  // Download
  FileSaver.saveAs(data, fileName + `.` + fileExtension);
};

export const exportTableListCollectPoints = (
  tableData,
  fileName,
  fileExtension
) => {
  const workSheet = XLSX.utils.json_to_sheet(tableData);

  workSheet["A1"] = { t: "s", v: "nom" };
  workSheet["B1"] = { t: "s", v: "telephone" };
  workSheet["C1"] = { t: "s", v: "superficie" };
  workSheet["D1"] = { t: "s", v: "latitude" };
  workSheet["E1"] = { t: "s", v: "longitude" };
  workSheet["F1"] = { t: "s", v: "region" };
  workSheet["G1"] = { t: "s", v: "departement" };
  workSheet["H1"] = { t: "s", v: "commune" };

  tableData.forEach((tb, i) => {
    workSheet[`A${i + 2}`] = workSheet[`B${i + 2}`];
    workSheet[`B${i + 2}`] = workSheet[`C${i + 2}`];
    workSheet[`C${i + 2}`] = workSheet[`D${i + 2}`];
    workSheet[`D${i + 2}`] = workSheet[`E${i + 2}`];
    workSheet[`E${i + 2}`] = workSheet[`F${i + 2}`];
  });

  tableData.forEach((tb, i) => {
    let concactVr = "";
    concactVr = workSheet[`H${i + 2}`];

    workSheet[`F${i + 2}`] = { t: "s", v: concactVr.region };
    workSheet[`G${i + 2}`] = { t: "s", v: concactVr.departement };
    workSheet[`H${i + 2}`] = { t: "s", v: concactVr.commune };
    delete workSheet[`I${i + 2}`];
    delete workSheet[`J${i + 2}`];
    delete workSheet[`K${i + 2}`];
    delete workSheet[`L${i + 2}`];
  });

  delete workSheet["I1"];
  delete workSheet["J1"];
  delete workSheet["K1"];
  delete workSheet["L1"];

  const workBook = { Sheets: { data: workSheet }, SheetNames: ["data"] };
  // Buffer
  const buffer = XLSX.write(workBook, { bookType: "xlsx", type: "array" });

  const data = new Blob([buffer], { type: fileType });

  // Download
  FileSaver.saveAs(data, fileName + `.` + fileExtension);
};

export const exportTableListGroupements = (
  tableData,
  fileName,
  fileExtension
) => {
  const workSheet = XLSX.utils.json_to_sheet(tableData);

  workSheet["A1"] = { t: "s", v: "nom" };
  workSheet["B1"] = { t: "s", v: "agregateur" };

  tableData.forEach((tb, i) => {
    workSheet[`A${i + 2}`] = workSheet[`B${i + 2}`];
  });

  tableData.forEach((tb, i) => {
    let concactVr = "";
    concactVr = workSheet[`C${i + 2}`];

    concactVr = {
      t: "s",
      v: `${concactVr.nom}`,
    };

    workSheet[`B${i + 2}`] = concactVr;
    delete workSheet[`C${i + 2}`];
    delete workSheet[`D${i + 2}`];
    delete workSheet[`E${i + 2}`];
    delete workSheet[`F${i + 2}`];
    delete workSheet[`G${i + 2}`];
  });

  delete workSheet["C1"];
  delete workSheet["D1"];
  delete workSheet["E1"];
  delete workSheet["F1"];
  delete workSheet["G1"];

  const workBook = { Sheets: { data: workSheet }, SheetNames: ["data"] };
  // Buffer
  const buffer = XLSX.write(workBook, { bookType: "xlsx", type: "array" });

  const data = new Blob([buffer], { type: fileType });

  // Download
  FileSaver.saveAs(data, fileName + `.` + fileExtension);
};

export const exportTableListProducteurs = (
  tableData,
  fileName,
  fileExtension
) => {
  const workSheet = XLSX.utils.json_to_sheet(tableData);

  workSheet["A1"] = { t: "s", v: "reference" };
  workSheet["B1"] = { t: "s", v: "prenom" };
  workSheet["C1"] = { t: "s", v: "nom" };
  workSheet["D1"] = { t: "s", v: "sexe" };
  workSheet["E1"] = { t: "s", v: "age" };
  workSheet["F1"] = { t: "s", v: "telephone" };
  workSheet["G1"] = { t: "s", v: "cni" };
  workSheet["H1"] = { t: "s", v: "adresse" };
  workSheet["I1"] = { t: "s", v: "groupement" };
  workSheet["J1"] = { t: "s", v: "region" };
  workSheet["K1"] = { t: "s", v: "departement" };
  workSheet["L1"] = { t: "s", v: "commune" };

  tableData.forEach((tb, i) => {
    workSheet[`A${i + 2}`] = workSheet[`B${i + 2}`];
    workSheet[`B${i + 2}`] = workSheet[`C${i + 2}`];
    workSheet[`C${i + 2}`] = workSheet[`D${i + 2}`];
    workSheet[`D${i + 2}`] = workSheet[`E${i + 2}`];
    workSheet[`E${i + 2}`] = workSheet[`F${i + 2}`];
    workSheet[`F${i + 2}`] = workSheet[`G${i + 2}`];
    workSheet[`G${i + 2}`] = workSheet[`H${i + 2}`];
    workSheet[`H${i + 2}`] = workSheet[`I${i + 2}`];
  });

  tableData.forEach((tb, i) => {
    let concactVr1 = "";
    let concactVr2 = "";
    concactVr1 = workSheet[`J${i + 2}`];
    concactVr2 = workSheet[`K${i + 2}`];

    concactVr1 = {
      t: "s",
      v: `${concactVr1.nom}`,
    };

    workSheet[`I${i + 2}`] = concactVr1;
    workSheet[`J${i + 2}`] = { t: "s", v: concactVr2.region };
    workSheet[`K${i + 2}`] = { t: "s", v: concactVr2.departement };
    workSheet[`L${i + 2}`] = { t: "s", v: concactVr2.commune };

    delete workSheet[`M${i + 2}`];
    delete workSheet[`N${i + 2}`];
    delete workSheet[`O${i + 2}`];
    delete workSheet[`P${i + 2}`];
    delete workSheet[`Q${i + 2}`];
  });

  delete workSheet["M1"];
  delete workSheet["N1"];
  delete workSheet["O1"];
  delete workSheet["P1"];
  delete workSheet["Q1"];

  const workBook = { Sheets: { data: workSheet }, SheetNames: ["data"] };
  // Buffer
  const buffer = XLSX.write(workBook, { bookType: "xlsx", type: "array" });

  const data = new Blob([buffer], { type: fileType });

  // Download
  FileSaver.saveAs(data, fileName + `.` + fileExtension);
};

export const exportTableListCommercants = (
  tableData,
  fileName,
  fileExtension
) => {
  const workSheet = XLSX.utils.json_to_sheet(tableData);

  workSheet["A1"] = { t: "s", v: "prenom" };
  workSheet["B1"] = { t: "s", v: "nom" };
  workSheet["C1"] = { t: "s", v: "telephone" };
  workSheet["D1"] = { t: "s", v: "adresse" };
  workSheet["E1"] = { t: "s", v: "cni" };
  workSheet["F1"] = { t: "s", v: "agregateurs" };

  tableData.forEach((tb, i) => {
    workSheet[`A${i + 2}`] = workSheet[`B${i + 2}`];
    workSheet[`B${i + 2}`] = workSheet[`C${i + 2}`];
    workSheet[`C${i + 2}`] = workSheet[`D${i + 2}`];
    workSheet[`D${i + 2}`] = workSheet[`E${i + 2}`];
    workSheet[`E${i + 2}`] = workSheet[`F${i + 2}`];
  });

  tableData.forEach((tb, i) => {
    let concactVr = "";
    workSheet[`G${i + 2}`].length > 0 &&
      workSheet[`G${i + 2}`].forEach((vr) => {
        concactVr = concactVr + `${vr.nom},`;
      });

    concactVr = { t: "s", v: concactVr };
    workSheet[`F${i + 2}`] = concactVr;
    delete workSheet[`G${i + 2}`];
    delete workSheet[`H${i + 2}`];
    delete workSheet[`I${i + 2}`];
    delete workSheet[`J${i + 2}`];
    delete workSheet[`K${i + 2}`];
  });

  delete workSheet["G1"];
  delete workSheet["H1"];
  delete workSheet["I1"];
  delete workSheet["J1"];
  delete workSheet["K1"];

  const workBook = { Sheets: { data: workSheet }, SheetNames: ["data"] };
  // Buffer
  const buffer = XLSX.write(workBook, { bookType: "xlsx", type: "array" });

  const data = new Blob([buffer], { type: fileType });

  // Download
  FileSaver.saveAs(data, fileName + `.` + fileExtension);
};

export const exportTableListCoxeurs = (tableData, fileName, fileExtension) => {
  const workSheet = XLSX.utils.json_to_sheet(tableData);

  workSheet["A1"] = { t: "s", v: "prenom" };
  workSheet["B1"] = { t: "s", v: "nom" };
  workSheet["C1"] = { t: "s", v: "telephone" };
  workSheet["D1"] = { t: "s", v: "cni" };
  workSheet["E1"] = { t: "s", v: "point_collecte" };

  tableData.forEach((tb, i) => {
    workSheet[`A${i + 2}`] = workSheet[`B${i + 2}`];
    workSheet[`B${i + 2}`] = workSheet[`C${i + 2}`];
    workSheet[`C${i + 2}`] = workSheet[`D${i + 2}`];
    workSheet[`D${i + 2}`] = workSheet[`E${i + 2}`];
  });

  tableData.forEach((tb, i) => {
    let concactVr = "";
    concactVr = workSheet[`F${i + 2}`];

    concactVr = { t: "s", v: concactVr.nom };
    workSheet[`E${i + 2}`] = concactVr;
    delete workSheet[`F${i + 2}`];
    delete workSheet[`G${i + 2}`];
    delete workSheet[`H${i + 2}`];
    delete workSheet[`I${i + 2}`];
    delete workSheet[`J${i + 2}`];
  });
  delete workSheet["F1"];
  delete workSheet["G1"];
  delete workSheet["H1"];
  delete workSheet["I1"];
  delete workSheet["J1"];

  const workBook = { Sheets: { data: workSheet }, SheetNames: ["data"] };
  // Buffer
  const buffer = XLSX.write(workBook, { bookType: "xlsx", type: "array" });

  const data = new Blob([buffer], { type: fileType });

  // Download
  FileSaver.saveAs(data, fileName + `.` + fileExtension);
};

export const exportTableListLettreVoitures = (
  tableData,
  fileName,
  fileExtension
) => {
  const workSheet = XLSX.utils.json_to_sheet(tableData);

  delete workSheet["I1"];
  delete workSheet["J1"];
  delete workSheet["K1"];
  delete workSheet["L1"];

  tableData.forEach((tb, i) => {
    delete workSheet[`I${i + 2}`];
    delete workSheet[`J${i + 2}`];
    delete workSheet[`K${i + 2}`];
    delete workSheet[`L${i + 2}`];
  });

  workSheet["A1"] = { t: "s", v: "reference" };
  workSheet["B1"] = { t: "s", v: "nom_prenom_chauffeur" };
  workSheet["C1"] = { t: "s", v: "telephone_chauffeur" };
  workSheet["D1"] = { t: "s", v: "camion_immat" };
  workSheet["E1"] = { t: "s", v: "destination" };
  workSheet["F1"] = { t: "s", v: "nom_prenom_commercant_autre" };
  workSheet["G1"] = { t: "s", v: "telephone_commercant_autre" };
  workSheet["H1"] = { t: "s", v: "nom_prenom_commercant" };
  workSheet["I1"] = { t: "s", v: "telephone_commercant" };

  tableData.forEach((tb, i) => {
    let mvtLength = workSheet[`H${i + 2}`].length;
    let mvtArray = workSheet[`H${i + 2}`];
    mvtArray.forEach((mvt) => {
      if (mvt.sac_25_KG) {
        mvt["qty"] = 25 * mvt.nombre_sac;
      }
      if (mvt.sac_40_KG) {
        mvt["qty"] = 40 * mvt.nombre_sac;
      }
      if (mvt.sac_25_KG === false && mvt.sac_40_KG === false) {
        mvt["qty"] = mvt.poids_pesee;
      }
    });
    let trackNumberOfColumn = 0;
    for (let index = 9; index < alphabetArray.length; index++) {
      if (mvtLength > 0 && trackNumberOfColumn < mvtLength) {
        workSheet[`${alphabetArray[index]}1`] = {
          t: "s",
          v: `mvt-${trackNumberOfColumn + 1}`,
        };
        workSheet[`${alphabetArray[index]}${i + 2}`] = {
          t: "s",
          v: `${mvtArray[trackNumberOfColumn]?.qty}`,
        };
      }

      trackNumberOfColumn++;
    }
  });

  tableData.forEach((tb, i) => {
    let jsonCommercantNom = workSheet[`G${i + 2}`]["nom"] ?? "";
    let jsonCommercantPrenom = workSheet[`G${i + 2}`]["prenom"] ?? "";
    let jsonCommercantTelephone = workSheet[`G${i + 2}`]["telephone"] ?? "";
    workSheet[`H${i + 2}`] = {
      t: "s",
      v: `${jsonCommercantNom} ${jsonCommercantPrenom}`,
    };
    workSheet[`I${i + 2}`] = {
      t: "s",
      v: `${jsonCommercantTelephone}`,
    };
    let concatChauffeur = workSheet[`C${i + 2}`]["v"];
    let splitedChauffeur = concatChauffeur.split("-");
    let concatCommercantAutre = workSheet[`F${i + 2}`]["v"];
    let splitedCommercantAutre = concatCommercantAutre.split("-");
    workSheet[`A${i + 2}`] = workSheet[`B${i + 2}`];
    workSheet[`B${i + 2}`] = {
      t: "s",
      v: `${splitedChauffeur[0] ?? ""}`,
    };
    workSheet[`C${i + 2}`] = {
      t: "s",
      v: `${splitedChauffeur[1] ?? ""}`,
    };
    workSheet[`F${i + 2}`] = {
      t: "s",
      v: `${splitedCommercantAutre[0] ?? ""}`,
    };
    workSheet[`G${i + 2}`] = {
      t: "s",
      v: `${splitedCommercantAutre[1] ?? ""}`,
    };
  });

  const workBook = { Sheets: { data: workSheet }, SheetNames: ["data"] };
  // Buffer
  const buffer = XLSX.write(workBook, { bookType: "xlsx", type: "array" });

  const data = new Blob([buffer], { type: fileType });

  // Download
  FileSaver.saveAs(data, fileName + `.` + fileExtension);
};

export const exportTableListCodeDommages = (
  tableData,
  fileName,
  fileExtension
) => {
  const workSheet = XLSX.utils.json_to_sheet(tableData);

  workSheet["A1"] = { t: "s", v: "code" };
  workSheet["B1"] = { t: "s", v: "nom" };
  workSheet["C1"] = { t: "s", v: "description" };

  tableData.forEach((tb, i) => {
    workSheet[`A${i + 2}`] = workSheet[`B${i + 2}`];
    workSheet[`B${i + 2}`] = workSheet[`C${i + 2}`];
    workSheet[`C${i + 2}`] = workSheet[`D${i + 2}`];
  });

  tableData.forEach((tb, i) => {
    delete workSheet[`D${i + 2}`];
  });

  delete workSheet["D1"];

  const workBook = { Sheets: { data: workSheet }, SheetNames: ["data"] };
  // Buffer
  const buffer = XLSX.write(workBook, { bookType: "xlsx", type: "array" });

  const data = new Blob([buffer], { type: fileType });

  // Download
  FileSaver.saveAs(data, fileName + `.` + fileExtension);
};

export const exportTableListMarches = (tableData, fileName, fileExtension) => {
  const workSheet = XLSX.utils.json_to_sheet(tableData);

  workSheet["A1"] = { t: "s", v: "nom" };
  workSheet["B1"] = { t: "s", v: "reference" };
  workSheet["C1"] = { t: "s", v: "region" };
  workSheet["D1"] = { t: "s", v: "departement" };
  workSheet["E1"] = { t: "s", v: "commune" };

  tableData.forEach((tb, i) => {
    workSheet[`A${i + 2}`] = workSheet[`B${i + 2}`];
    workSheet[`B${i + 2}`] = workSheet[`C${i + 2}`];
  });

  tableData.forEach((tb, i) => {
    let zone = workSheet[`D${i + 2}`];

    workSheet[`C${i + 2}`] = { t: "s", v: zone.region };
    workSheet[`D${i + 2}`] = { t: "s", v: zone.departement };
    workSheet[`E${i + 2}`] = { t: "s", v: zone.commune };
  });

  tableData.forEach((tb, i) => {
    delete workSheet[`F${i + 2}`];
    delete workSheet[`G${i + 2}`];
    delete workSheet[`H${i + 2}`];
  });

  delete workSheet["F1"];
  delete workSheet["G1"];
  delete workSheet["H1"];

  const workBook = { Sheets: { data: workSheet }, SheetNames: ["data"] };
  // Buffer
  const buffer = XLSX.write(workBook, { bookType: "xlsx", type: "array" });

  const data = new Blob([buffer], { type: fileType });

  // Download
  FileSaver.saveAs(data, fileName + `.` + fileExtension);
};

export const exportTableListMarchesPrix = (
  tableData,
  fileName,
  fileExtension
) => {
  const workSheet = XLSX.utils.json_to_sheet(tableData);

  workSheet["A1"] = { t: "s", v: "variete" };
  workSheet["B1"] = { t: "s", v: "marche" };
  workSheet["C1"] = { t: "s", v: "prix_detaillant_min" };
  workSheet["D1"] = { t: "s", v: "prix_detaillant_max" };
  workSheet["E1"] = { t: "s", v: "prix_dominant" };
  workSheet["F1"] = { t: "s", v: "prix_demis_gros_min" };
  workSheet["G1"] = { t: "s", v: "prix_demis_gros_max" };
  workSheet["H1"] = { t: "s", v: "unite_demis_gros" };
  workSheet["I1"] = { t: "s", v: "quantite_demis_gros" };
  workSheet["J1"] = { t: "s", v: "prix_gros_min" };
  workSheet["K1"] = { t: "s", v: "prix_gros_max" };
  workSheet["L1"] = { t: "s", v: "statut" };
  workSheet["M1"] = { t: "s", v: "date_statut" };
  workSheet["N1"] = { t: "s", v: "date_statut_expiration" };

  tableData.forEach((tb, i) => {
    let concactVr1 = "";
    let concactVr2 = "";
    concactVr1 = workSheet[`C${i + 2}`];
    concactVr2 = workSheet[`E${i + 2}`];

    concactVr1 = {
      t: "s",
      v: concactVr1.nom,
    };

    concactVr2 = {
      t: "s",
      v: concactVr2.nom,
    };

    workSheet[`A${i + 2}`] = concactVr1;
    workSheet[`B${i + 2}`] = concactVr2;
  });

  tableData.forEach((tb, i) => {
    workSheet[`C${i + 2}`] = workSheet[`F${i + 2}`];
    workSheet[`D${i + 2}`] = workSheet[`G${i + 2}`];
    workSheet[`E${i + 2}`] = workSheet[`H${i + 2}`];
    workSheet[`F${i + 2}`] = workSheet[`I${i + 2}`];
    workSheet[`G${i + 2}`] = workSheet[`J${i + 2}`];
    workSheet[`H${i + 2}`] = workSheet[`K${i + 2}`];
    workSheet[`I${i + 2}`] = workSheet[`L${i + 2}`];
    workSheet[`J${i + 2}`] = workSheet[`M${i + 2}`];
    workSheet[`K${i + 2}`] = workSheet[`N${i + 2}`];
    workSheet[`L${i + 2}`] = workSheet[`O${i + 2}`];
    workSheet[`M${i + 2}`] = workSheet[`P${i + 2}`];
    workSheet[`N${i + 2}`] = workSheet[`Q${i + 2}`];
  });

  tableData.forEach((tb, i) => {
    delete workSheet[`O${i + 2}`];
    delete workSheet[`P${i + 2}`];
    delete workSheet[`Q${i + 2}`];
    delete workSheet[`R${i + 2}`];
    delete workSheet[`S${i + 2}`];
    delete workSheet[`T${i + 2}`];
    delete workSheet[`U${i + 2}`];
  });

  delete workSheet["O1"];
  delete workSheet["P1"];
  delete workSheet["Q1"];
  delete workSheet["R1"];
  delete workSheet["S1"];
  delete workSheet["T1"];
  delete workSheet["U1"];

  const workBook = { Sheets: { data: workSheet }, SheetNames: ["data"] };
  // Buffer
  const buffer = XLSX.write(workBook, { bookType: "xlsx", type: "array" });

  const data = new Blob([buffer], { type: fileType });

  // Download
  FileSaver.saveAs(data, fileName + `.` + fileExtension);
};

export const exportTableListEntreeStocks = (
  tableData,
  fileName,
  fileExtension
) => {
  const workSheet = XLSX.utils.json_to_sheet(tableData);

  workSheet["A1"] = { t: "s", v: "sac_25_KG" };
  workSheet["B1"] = { t: "s", v: "sac_40_KG" };
  workSheet["C1"] = { t: "s", v: "reference" };
  workSheet["D1"] = { t: "s", v: "type_mvt" };
  workSheet["E1"] = { t: "s", v: "date_mvt" };
  workSheet["F1"] = { t: "s", v: "nombre_sac" };
  workSheet["G1"] = { t: "s", v: "poids_pesee" };
  workSheet["H1"] = { t: "s", v: "nom_prenom_deposant" };
  workSheet["I1"] = { t: "s", v: "telephone_deposant" };
  workSheet["J1"] = { t: "s", v: "type_deposant" };
  workSheet["K1"] = { t: "s", v: "variete" };
  workSheet["L1"] = { t: "s", v: "coxeur" };
  workSheet["M1"] = { t: "s", v: "producteur" };
  workSheet["N1"] = { t: "s", v: "groupement" };

  tableData.forEach((tb, i) => {
    workSheet[`A${i + 2}`] = workSheet[`B${i + 2}`];
    workSheet[`B${i + 2}`] = workSheet[`C${i + 2}`];
    workSheet[`C${i + 2}`] = workSheet[`D${i + 2}`];
    workSheet[`D${i + 2}`] = workSheet[`E${i + 2}`];
    workSheet[`E${i + 2}`] = workSheet[`F${i + 2}`];
    workSheet[`F${i + 2}`] = workSheet[`G${i + 2}`];
    workSheet[`G${i + 2}`] = workSheet[`H${i + 2}`];
  });

  tableData.forEach((tb, i) => {
    const { v: deposant } = workSheet[`I${i + 2}`];
    const { nom: fullNameProducteur } = workSheet[`L${i + 2}`];
    const { nom: fullNameGroupement } = workSheet[`N${i + 2}`];
    const {
      coxeur: { nom: nomCoxeur, prenom: prenomCoxeur },
      variete: { nom: nomVariete },
    } = workSheet[`J${i + 2}`];

    const sliptedDeposant = deposant.split("-");
    sliptedDeposant.forEach((s, index) => {
      if (index === 0) {
        workSheet[`H${i + 2}`] = { t: "s", v: s };
      }
      if (index === 1) {
        workSheet[`I${i + 2}`] = { t: "s", v: s };
      }
      if (index === 2) {
        workSheet[`J${i + 2}`] = { t: "s", v: s };
      }
    });
    workSheet[`K${i + 2}`] = { t: "s", v: nomVariete };
    workSheet[`L${i + 2}`] = { t: "s", v: `${nomCoxeur} ${prenomCoxeur}` };
    workSheet[`M${i + 2}`] = { t: "s", v: fullNameProducteur };
    workSheet[`N${i + 2}`] = { t: "s", v: fullNameGroupement };
  });

  tableData.forEach((tb, i) => {
    delete workSheet[`O${i + 2}`];
    delete workSheet[`P${i + 2}`];
    delete workSheet[`Q${i + 2}`];
    delete workSheet[`R${i + 2}`];
  });

  delete workSheet["O1"];
  delete workSheet["P1"];
  delete workSheet["Q1"];
  delete workSheet["R1"];

  const workBook = { Sheets: { data: workSheet }, SheetNames: ["data"] };
  // Buffer
  const buffer = XLSX.write(workBook, { bookType: "xlsx", type: "array" });

  const data = new Blob([buffer], { type: fileType });

  // Download
  FileSaver.saveAs(data, fileName + `.` + fileExtension);
};
