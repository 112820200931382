import { CircularProgress } from "@mui/material";
import moment from "moment";
import React from "react";
import CustomTable from "../../../../components/Table/CustomTable/CustomTable";
import DisplaySortieDommagesTableRows from "../../../../components/Table/CustomTable/TableContext/SortieDommageTableCells/DisplaySortieDommagesTableRows";
import { exportTable } from "../../../../services/filesTransfer";
import EntreeStocksFormModal from "../../../../ui/Modals/FormModals/Records/EntreeStocksFormModal";
import { useAuthenticationContext } from "../../../../utils/contexts/AuthenticationProvider";
import { useRecordsContext } from "../../../../utils/contexts/RecordsProvider";

function SortieDommagesTableContainer() {
  const { user } = useAuthenticationContext();
  const { listSortieDommages } = useRecordsContext();
  const formModalInitialRow = {
    id: "",
    sac_25_KG: false,
    sac_40_KG: false,
    reference: "",
    date_mvt: "",
    nombre_sac: "",
    poids_pesee: "",
    code_dommage: "",
    coxeur: "",
    variete: "",
    producteur: "",
    groupement: "",
  };

  const headCells = [
    { label: "Sac 25 KG", id: "SAC_25_KG", disabled: true },
    { label: "Sac 40 KG", id: "SAC_40_KG", disabled: true },
    { label: "Reference", id: "REFERENCE" },
    { label: "Type de mouvement", id: "TYPE_MVT" },
    { label: "Date de mouvement", id: "DATE_MVT", disabled: true },
    { label: "Nombre de sac", id: "NOMBRE_SAC" },
    { label: "Poids Pesée (KG)", id: "POIDS_PESEE" },
    { label: "Code dommage", id: "CODE_DOMMAGE" },
  ];

  const [filteredRows, setFilteredRows] = React.useState([]);
  const [inputSearchValue, setInputSearchValue] = React.useState("");
  const [formAction, setFormAction] = React.useState("");
  const [rows, setRows] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [filterValue, setFilterValue] = React.useState(headCells[2].id || "");
  const [startDate, setStartDate] = React.useState("");
  const [endDate, setEndDate] = React.useState("");
  const [filter, setFilter] = React.useState({
    fn: (items) => {
      return items;
    },
  });
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState(headCells[0].id);

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

  const handleSearch = (event) => {
    let searchQuery = event.target.value;
    setInputSearchValue(searchQuery);
    setFilter({
      fn: (items) => {
        if (searchQuery === "") return items;
        else {
          return items.filter((x) => {
            return (
              x["reference"]
                .toLowerCase()
                .includes(searchQuery.toLowerCase()) ||
              x["type_mvt"].toLowerCase().includes(searchQuery.toLowerCase()) ||
              x["date_mvt"].toLowerCase().includes(searchQuery.toLowerCase()) ||
              x.nombre_sac === searchQuery ||
              x.poids_pesee === searchQuery ||
              x["code_dommage"]["code"]
                .toLowerCase()
                .includes(searchQuery.toLowerCase())
            );
          });
        }
      },
    });
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSortRequest = (cellId) => {
    const isASC = orderBy === cellId && order === "asc";
    setOrder(isASC ? "desc" : "asc");
    setOrderBy(cellId);
  };

  function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  const getComparator = React.useCallback((order, orderBy) => {
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }, []);

  function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }

  const recordsAfterPaginationSortingAndFiltering = () => {
    return stableSort(filter.fn(rows), getComparator(order, orderBy)).slice(
      page * rowsPerPage,
      (page + 1) * rowsPerPage
    );
  };

  React.useEffect(() => {
    if (startDate !== "" && endDate !== "" && listSortieDommages.length > 0) {
      let tempList = [];
      tempList = listSortieDommages.filter((ag) => {
        return moment(ag.date_mvt).isBetween(startDate, endDate) && ag;
      });
      setRows(tempList);
    } else {
      setRows(listSortieDommages);
    }
  }, [listSortieDommages, startDate, endDate]);

  React.useEffect(() => {
    function stableSort(array, comparator) {
      const stabilizedThis = array.map((el, index) => [el, index]);
      stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
      });
      setFilteredRows(stabilizedThis.map((el) => el[0]));
    }
    stableSort(filter.fn(rows), getComparator(order, orderBy));
  }, [
    inputSearchValue,
    setFilteredRows,
    filter,
    order,
    orderBy,
    getComparator,
    rows,
  ]);

  return (
    <React.Fragment>
      <CustomTable
        tableName="sortie-dommages"
        TableContext={DisplaySortieDommagesTableRows}
        headCells={headCells}
        rows={rows}
        rowsPerPage={rowsPerPage}
        page={page}
        emptyRows={emptyRows}
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        order={order}
        orderBy={orderBy}
        handleSortRequest={handleSortRequest}
        recordsAfterPaginationSortingAndFiltering={
          recordsAfterPaginationSortingAndFiltering
        }
        filterLabel="Filtrer par:"
        filterValue={filterValue}
        setFilterValue={setFilterValue}
        handleSearch={handleSearch}
        stableSort={stableSort}
        getComparator={getComparator}
        filter={filter}
        setRows={setRows}
        enableEdit={true}
        formModalInitialRow={formModalInitialRow}
        FormModal={EntreeStocksFormModal}
        // handleDeleteRow={deleteEntreeStocks}
        formAction={formAction}
        setFormAction={setFormAction}
        disableSelectFilterValue={true}
        disableLoadButton={true}
        customExport={exportTable}
        setInputSearchValue={setInputSearchValue}
        filteredRows={filteredRows}
        inputSearchValue={inputSearchValue}
        disableTemplateButton={true}
        startDate={startDate}
        setStartDate={setStartDate}
        endDate={endDate}
        setEndDate={setEndDate}
        enableDateStartEndSearch={true}
        disableDeleteIcon={user.is_adminstrateur ? true : false}
        disableEditIcon={true}
        disableAddButton={true}
        enableMoreDetailsIcon={true}
        toolTipMessage="Filtrer par date mouvement"
        moreDetailsPath="/enregistrements/sortiedommages/"
      />
    </React.Fragment>
  );
}

export default SortieDommagesTableContainer;
