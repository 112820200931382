import { Button, InputAdornment, TextField, Tooltip } from "@mui/material";
import React from "react";
import useStyles from "./styles";
import PropTypes from "prop-types";
import ClearIcon from "@mui/icons-material/Clear";

/** Component Description:
 *  This component render the search table's head's functions container for
 *  the filtering.
 */

function SearchForTables({
  filterLabel,
  filterValue,
  setFilterValue,
  headCells,
  handleSearch,
  disableSelectFilterValue,
  inputSearchValue,
  enableDateStartEndSearch,
  startDate,
  endDate,
  setStartDate,
  setEndDate,
  toolTipMessage,
}) {
  const classes = useStyles();
  return (
    <div className={classes.searchGroup}>
      <TextField
        id="standard-basic"
        label="Rechercher"
        size="small"
        value={inputSearchValue}
        onChange={(e) => {
          handleSearch(e);
        }}
      />
      {enableDateStartEndSearch && (
        <React.Fragment>
          <Tooltip title={toolTipMessage}>
            <TextField
              type="date"
              label="Date début"
              InputLabelProps={{ shrink: true }}
              value={startDate}
              onChange={(e) => setStartDate(e.target.value)}
              size="small"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="start">
                    <ClearIcon
                      onClick={() => setStartDate("")}
                      style={{ cursor: "pointer" }}
                    />
                  </InputAdornment>
                ),
              }}
            />
          </Tooltip>
          <Tooltip title={toolTipMessage}>
            <TextField
              type="date"
              label="Date fin"
              InputLabelProps={{ shrink: true }}
              value={endDate}
              onChange={(e) => setEndDate(e.target.value)}
              size="small"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="start">
                    <ClearIcon
                      onClick={() => setEndDate("")}
                      style={{ cursor: "pointer" }}
                    />
                  </InputAdornment>
                ),
              }}
            />
          </Tooltip>
        </React.Fragment>
      )}
    </div>
  );
}

SearchForTables.propTypes = {
  /** Choosen field for filtering */
  filterLabel: PropTypes.string,
  /** Filtering value*/
  filterValue: PropTypes.string,
  setFilterValue: PropTypes.func,
  /** Table head cells */
  headCells: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      id: PropTypes.string,
    })
  ),
  handleSearch: PropTypes.func,

  /** Disable filtering by a specifici field */
  disableSelectFilterValue: PropTypes.bool,
  /** Filtering value*/
  inputSearchValue: PropTypes.string,

  enableDateStartEndSearch: PropTypes.bool,
  /** Date in ISO format */
  startDate: PropTypes.string,
  /** Date in ISO format */
  endDate: PropTypes.string,
  setStartDate: PropTypes.func,
  setEndDate: PropTypes.func,
  /** Tooltip message on focus*/
  toolTipMessage: PropTypes.string,
};

export default SearchForTables;
