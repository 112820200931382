import React from "react";
import { TableCell } from "@mui/material";
import PropTypes from "prop-types";

/** Component Description:
 *  This component render for each table container,
 *  the row's fields.
 */
function DisplayAgregatorsTableRows({ row }) {
  return (
    <React.Fragment>
      <TableCell align="left">{row.nom}</TableCell>
      <TableCell align="center">
        <ul
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            listStyle: "none",
          }}
        >
          {row?.zones?.map((zn) => (
            <li
              key={zn.id}
            >{`${zn.region} | ${zn.departement} | ${zn.commune}`}</li>
          ))}
        </ul>
      </TableCell>
    </React.Fragment>
  );
}
DisplayAgregatorsTableRows.propTypes = {
  row: PropTypes.object,
};

export default DisplayAgregatorsTableRows;
