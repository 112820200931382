import { CircularProgress } from "@mui/material";
import React from "react";
import { useParams } from "react-router-dom";
import CommonPagesLayout from "../../../../components/layouts/Common/CommonPagesLayout";
import { LIST_PRODUCTS_DATA_URL } from "../../../../utils/api/apiUrls";
import apiInstance from "../../../../utils/api/axios.config";
import { useAuthenticationContext } from "../../../../utils/contexts/AuthenticationProvider";
import { useUpdatePagesStateContext } from "../../../../utils/contexts/UpdatePagesStateProvider";
import VarietiesListOfAProductTableContainer from "./CustomTable/VarietiesListOfAProductTableContainer";

function VarietiesListOfAProductContainer() {
  let { id } = useParams();
  const { token } = useAuthenticationContext();
  let mountedRef = React.useRef(false);
  const [product, setProduct] = React.useState({});
  const { isListVarietiesUpdated, setIsListVarietiesUpdated } =
    useUpdatePagesStateContext();
  const [progressState, setProgressState] = React.useState(true);

  React.useEffect(() => {
    mountedRef.current = true;
    if (mountedRef.current) {
      if (token !== "") {
        const fetchAProduct = async () => {
          try {
            const result = await apiInstance.get(
              LIST_PRODUCTS_DATA_URL + `${id}/`,
              {
                headers: { Authorization: `Token ${token}` },
              }
            );
            setProduct(result.data);
            setProgressState(false);
          } catch (err) {
            setProgressState(false);
            return err;
          }
        };
        fetchAProduct();
      }
    }
    return () => {
      mountedRef.current = false;
    };
  }, [token, mountedRef, setProduct, id, setProgressState]);

  React.useEffect(() => {
    if (isListVarietiesUpdated) {
      const fetchAProduct = async () => {
        try {
          const result = await apiInstance.get(
            LIST_PRODUCTS_DATA_URL + `${id}/`,
            {
              headers: { Authorization: `Token ${token}` },
            }
          );
          setProduct(result.data);
          setProgressState(false);
        } catch (err) {
          setProgressState(false);
          return err;
        }
      };
      fetchAProduct();
      setIsListVarietiesUpdated(false);
    }
  }, [
    isListVarietiesUpdated,
    setIsListVarietiesUpdated,
    setProduct,
    id,
    token,
    setProgressState,
  ]);

  return (
    <React.Fragment>
      {id === "liste" || id === "varietes" ? null : (
        <CommonPagesLayout>
          <h1>Variétés du Produit:{product?.nom}</h1>
          {progressState ? (
            <CircularProgress />
          ) : (
            <VarietiesListOfAProductTableContainer id={id} product={product} />
          )}
        </CommonPagesLayout>
      )}
    </React.Fragment>
  );
}

export default VarietiesListOfAProductContainer;