import React from "react";
import { TableCell } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import PropTypes from "prop-types";

/** Component Description:
 *  This component render for each table container,
 *  the row's fields.
 */
function DisplaySortieDommagesTableRows({ row }) {
  return (
    <React.Fragment>
      <TableCell align="left">
        {row.sac_25_KG ? (
          <CheckIcon fontSize="small" style={{ color: "green" }} />
        ) : (
          <CloseIcon fontSize="small" style={{ color: "red" }} />
        )}
      </TableCell>
      <TableCell align="center">
        {row.sac_40_KG ? (
          <CheckIcon fontSize="small" style={{ color: "green" }} />
        ) : (
          <CloseIcon fontSize="small" style={{ color: "red" }} />
        )}
      </TableCell>
      <TableCell align="center">{row.reference}</TableCell>
      <TableCell align="center">{row.type_mvt}</TableCell>
      <TableCell align="center">
        {row.date_mvt !== null ? row.date_mvt : "NEANT"}
      </TableCell>
      <TableCell align="center">{row.nombre_sac}</TableCell>
      <TableCell align="center">{row.poids_pesee}</TableCell>
      <TableCell align="center">
        {row.code_dommage !== undefined && row.code_dommage.code}
      </TableCell>
    </React.Fragment>
  );
}
DisplaySortieDommagesTableRows.propTypes = {
  row: PropTypes.object,
};

export default DisplaySortieDommagesTableRows;
