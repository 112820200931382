import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles({
  iconsGroup: {
    display: "flex",
    justifyContent: "flex-end",
    gap: "1em",
    width: "100%",
  },
  editIcon: {
    cursor: "pointer",
  },
  deleteIcon: {
    cursor: "pointer",
  },
  visibilityIcon: {
    cursor: "pointer",
  },
});

export default useStyles;
