import { Avatar, TableCell } from "@mui/material";
import React from "react";
import PropTypes from "prop-types";

/** Component Description:
 *  This component render for each table container,
 *  the row's fields.
 */
function DisplayUsersAutresTableRow({ row }) {
  return (
    <React.Fragment>
      <TableCell align="left">
        <Avatar
          alt={`${row.user.first_name} ${row.user.last_name}`}
          src={
            (row.photo_base64 !== null || row.photo_base64 !== "") &&
            row.photo_base64
          }
        />
      </TableCell>
      <TableCell align="center">{row.user.first_name}</TableCell>
      <TableCell align="center">{row.user.last_name}</TableCell>
      <TableCell align="center">
        {row.user.email === "" ? "NEANT" : row.user.email}
      </TableCell>
      <TableCell align="center">{row.user.username}</TableCell>
      <TableCell align="center">
        {(row.is_admin_agregateur && "ADMIN AGREGATEUR") ||
          (row.is_administrateur && "ADMIN") ||
          (row.is_admin_saed && "ADMIN SAED") ||
          (row.is_admin_groupement && "ADMIN GROUPEMENT") ||
          (row.is_resp_point_collecte && "RESP POINT-COLLECTE") ||
          (row.is_admin_banque && "ADMIN BANQUE") ||
          (row.is_payeur_banque && "PAYEUR BANQUE") ||
          (row.is_agent_arm && "AGENT ARM") ||
          (row.is_admin_arm && "ADMIN ARM") ||
          (row.is_commercant && "COMMERCANT") ||
          (row.is_producteur && "PRODUCTEUR") ||
          "INCONNU"}
      </TableCell>
      <TableCell align="center">
        {(row.is_admin_agregateur && `${row.agregateur?.nom}`) ||
          (row.is_admin_groupement && `${row.groupement?.nom}`) ||
          (row.is_resp_point_collecte && `${row.point_collecte?.nom}`)}
      </TableCell>
    </React.Fragment>
  );
}
DisplayUsersAutresTableRow.propTypes = {
  row: PropTypes.object,
};

export default DisplayUsersAutresTableRow;
