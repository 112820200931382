import { TableCell } from "@mui/material";
import React from "react";
import PropTypes from "prop-types";

/** Component Description:
 *  This component render for each table container,
 *  the row's fields.
 */
function DisplayCoxeursTableRows({ row }) {
  return (
    <React.Fragment>
      <TableCell align="left">{row.nom}</TableCell>
      <TableCell align="center">{row.prenom}</TableCell>
      <TableCell align="center">{row.telephone}</TableCell>
      <TableCell align="center">
        {row.cni === null || row.cni === "" ? "NEANT" : row.cni}
      </TableCell>
      <TableCell align="center">
        {row.point_collecte ? row.point_collecte.nom : "NEANT"}
      </TableCell>
    </React.Fragment>
  );
}
DisplayCoxeursTableRows.propTypes = {
  row: PropTypes.object,
};

export default DisplayCoxeursTableRows;
