import { CircularProgress } from "@mui/material";
import React from "react";
import CommonPagesLayout from "../../../components/layouts/Common/CommonPagesLayout";
import {
  LIST_AGREGATORS_DATA_URL,
  LIST_GROUPMENTS_DATA_URL,
} from "../../../utils/api/apiUrls";
import apiInstance from "../../../utils/api/axios.config";
import { useAuthenticationContext } from "../../../utils/contexts/AuthenticationProvider";
import { useOrganizationsContext } from "../../../utils/contexts/OrganizationsProvider";
import { useUpdatePagesStateContext } from "../../../utils/contexts/UpdatePagesStateProvider";
import GroupmentsTableContainer from "./CustomTable/GroupmentsTableContainer";

function GroupmentsContainer() {
  const { token } = useAuthenticationContext();
  const { isListGroupmentsUpdated, setIsListGroupmentsUpdated } =
    useUpdatePagesStateContext();
  const { setListGroupments, setListAgregators } = useOrganizationsContext();
  const [progressState, setProgressState] = React.useState(true);
  React.useEffect(() => {
    if (token !== "") {
      const fetchAllGroupments = async () => {
        try {
          const result = await apiInstance.get(LIST_GROUPMENTS_DATA_URL, {
            headers: { Authorization: `Token ${token}` },
          });
          setListGroupments(result.data);
          setProgressState(false);
        } catch (err) {
          setProgressState(false);
          return err;
        }
      };

      const fetchAllAgregators = async () => {
        try {
          const result = await apiInstance.get(LIST_AGREGATORS_DATA_URL, {
            headers: {
              Authorization: `Token ${token}`,
            },
          });
          setListAgregators(result.data);
        } catch (err) {
          console.log(err);
        }
      };

      fetchAllGroupments();
      fetchAllAgregators();
    }
  }, [token, setListGroupments, setListAgregators, setProgressState]);

  React.useEffect(() => {
    if (isListGroupmentsUpdated) {
      setProgressState(true);
      const fetchAllGroupments = async () => {
        try {
          const result = await apiInstance.get(LIST_GROUPMENTS_DATA_URL, {
            headers: { Authorization: `Token ${token}` },
          });
          setListGroupments(result.data);
          setProgressState(false);
        } catch (err) {
          setProgressState(false);
          return err;
        }
      };
      fetchAllGroupments();
      setIsListGroupmentsUpdated(false);
    }
  }, [
    token,
    isListGroupmentsUpdated,
    setIsListGroupmentsUpdated,
    setListGroupments,
    setProgressState,
  ]);

  return (
    <CommonPagesLayout>
      <h1>Groupements</h1>
      {progressState ? <CircularProgress /> : <GroupmentsTableContainer />}
    </CommonPagesLayout>
  );
}

export default GroupmentsContainer;
