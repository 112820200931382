import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Avatar,
  Divider,
  List,
  Typography,
} from "@mui/material";
import React from "react";

import { useParams } from "react-router-dom";
import { LIST_ENTREE_STOCKS_DATA_URL } from "../../../../utils/api/apiUrls";
import apiInstance from "../../../../utils/api/axios.config";
import { useAuthenticationContext } from "../../../../utils/contexts/AuthenticationProvider";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CommonPagesLayout from "../../../../components/layouts/Common/CommonPagesLayout";
import useStyles from "./styles";

function DetailsEntreeStockContainer() {
  const classes = useStyles();
  const [detailsEntreeStock, setDetailsEntreeStock] = React.useState("");
  const { token } = useAuthenticationContext();
  let { id } = useParams();

  React.useEffect(() => {
    if (token !== "") {
      const fetchDetailsEntreeStock = async () => {
        try {
          const result = await apiInstance.get(
            LIST_ENTREE_STOCKS_DATA_URL + `${id}/`,
            {
              headers: { Authorization: `Token ${token}` },
            }
          );
          setDetailsEntreeStock(result.data);
        } catch (err) {
          console.log(err);
          return;
        }
      };

      fetchDetailsEntreeStock();
    }
  }, [id, token, setDetailsEntreeStock]);

  return (
    <CommonPagesLayout>
      <h1>Details Entrée Stock</h1>

      <div
        className="content"
        style={{
          display: "flex",
          width: "100%",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {detailsEntreeStock !== "" && (
          <div
            style={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Accordion className={classes.accordion} defaultExpanded>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography>
                  {"Référence: " + detailsEntreeStock.reference}
                </Typography>
              </AccordionSummary>
              <Divider />
              <AccordionDetails>
                <List>
                  <Typography primary="Inbox">
                    {"Type de Mouvement: " + detailsEntreeStock.type_mvt}
                  </Typography>
                  <Typography primary="Inbox">
                    <Typography primary="Inbox">
                      {"Date du Mouvement: " + detailsEntreeStock.date_mvt}
                    </Typography>
                    <Typography primary="Inbox"></Typography>
                    {"Type de Sac: " +
                      (detailsEntreeStock.sac_25_KG && "25 KG") ||
                      (detailsEntreeStock.sac_40_KG && "40 KG")}
                  </Typography>
                  <Typography primary="Inbox">
                    {"Poids pesé: " + detailsEntreeStock.poids_pesee}
                  </Typography>
                  <Typography primary="Inbox">
                    {"Nombre de Sac: " + detailsEntreeStock.nombre_sac}
                  </Typography>
                </List>
              </AccordionDetails>
              <Divider />
              <Typography variant="h5">INFOS STOCK: </Typography>
              <AccordionDetails>
                <List className={classes.accordionDetails}>
                  <List>
                    <Typography variant="h6">COXEUR:</Typography>
                    <Divider />
                    <Typography primary="Inbox">
                      {"Nom: " + detailsEntreeStock.stock.coxeur.nom}
                    </Typography>
                    <Typography primary="Inbox">
                      {"Prénom: " + detailsEntreeStock.stock.coxeur.prenom}
                    </Typography>
                    <Typography primary="Inbox">
                      {"Téléphone: " +
                        detailsEntreeStock.stock.coxeur.telephone}
                    </Typography>
                    <Typography primary="Inbox">
                      {"CNI: " + detailsEntreeStock.stock.coxeur.cni}
                    </Typography>
                    <Typography primary="Inbox">
                      {"Point de Collecte: " +
                        detailsEntreeStock.stock.coxeur.point_collecte.nom}
                    </Typography>
                  </List>

                  <List>
                    <Typography variant="h6">VARIETE:</Typography>
                    <Divider />
                    <Avatar
                      alt={detailsEntreeStock.stock.variete.nom}
                      src={detailsEntreeStock.stock.variete.image_base64}
                    />
                    <Typography primary="Inbox">
                      {"Nom: " + detailsEntreeStock.stock.variete.nom}
                    </Typography>
                    <Typography primary="Inbox">
                      {"Description: " +
                        detailsEntreeStock.stock.variete.description}
                    </Typography>
                  </List>
                  <List>
                    <Typography variant="h6">PRODUCTEUR:</Typography>
                    <Divider />
                    <Typography primary="Inbox">
                      {"Nom: " + detailsEntreeStock.producteur?.nom}
                    </Typography>
                    <Typography primary="Inbox">
                      {"Description: " +
                        detailsEntreeStock.stock.variete.description}
                    </Typography>
                  </List>
                  <List>
                    <Typography variant="h6">GROUPEMENT:</Typography>
                    <Divider />
                    <Typography primary="Inbox">
                      {"Nom: " + detailsEntreeStock.groupement?.nom}
                    </Typography>
                  </List>
                </List>
              </AccordionDetails>
            </Accordion>
          </div>
        )}
      </div>
    </CommonPagesLayout>
  );
}

export default DetailsEntreeStockContainer;
