import React from "react";
import { TableCell } from "@mui/material";
import PropTypes from "prop-types";

/** Component Description:
 *  This component render for each table container,
 *  the row's fields.
 */
function DisplayLettreVoituresTableRows({ row }) {
  return (
    <React.Fragment>
      <TableCell align="left">{row.reference}</TableCell>
      <TableCell align="center">{row.chauffeur}</TableCell>
      <TableCell align="center">{row.camion_immat}</TableCell>
      <TableCell align="center">{row.destination}</TableCell>
      <TableCell align="center">
        {row.commercant !== null &&
          `${row.commercant.nom} ${row.commercant.prenom}`}
      </TableCell>
      <TableCell align="center">
        {row.commercant === null && row.commercant_autre}
      </TableCell>
    </React.Fragment>
  );
}
DisplayLettreVoituresTableRows.propTypes = {
  row: PropTypes.object,
};

export default DisplayLettreVoituresTableRows;
