import React from "react";
import "./Dashboard.css";
import moment from "moment";
import frLocale from "moment/locale/fr";
import LeafletMap from "../../components/Map/LeafletMap";
import CustomDoughnut from "../../components/Charts/CustomDoughnut";
import CustomTwoAxisLinesGraph from "../../components/Charts/CustomLinesGraph";
import SelectGroupForDashboard from "../../ui/SelectInput/SelectGroupForDashboard/SelectGroupForDashboard";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import BarChartIcon from "@mui/icons-material/BarChart";
import DonutLargeIcon from "@mui/icons-material/DonutLarge";
import PieChartIcon from "@mui/icons-material/PieChart";
import { useDashboardContext } from "../../utils/contexts/DashboardProvider";
import CustomBar from "../../components/Charts/CustomBar";
import CustomPie from "../../components/Charts/CustomPie";
import { useOrganizationsContext } from "../../utils/contexts/OrganizationsProvider";
import { useAuthenticationContext } from "../../utils/contexts/AuthenticationProvider";
import { useProductsContext } from "../../utils/contexts/ProductsProvider";
import apiInstance from "../../utils/api/axios.config";
import AirplayIcon from "@mui/icons-material/Airplay";
import {
  LIST_AGREGATORS_DATA_URL,
  LIST_COLLECT_POINTS_DATA_URL,
  LIST_GROUPMENTS_DATA_URL,
  LIST_PRODUCTORS_DATA_URL,
  LIST_PRODUCTS_DATA_URL,
  STATS_DOMMAGES,
  STATS_ENTREES,
  STATS_SORTIES,
} from "../../utils/api/apiUrls";
// import randomColor from "random-color";
import useStyles from "./styles";
import { CircularProgress, InputAdornment, TextField } from "@mui/material";
import StickerChart from "../../components/Charts/StickerChart";
import ClearIcon from "@mui/icons-material/Clear";

var randomColor = require('randomcolor'); // import the script
var color = randomColor(); // a hex code for an attractive color

function ResponsablePointCollectesDasboardContainer() {
  moment.locale("fr");
  const { setListProducts, listProducts } = useProductsContext();
  const { selectedProduct } = useDashboardContext();
  const classes = useStyles();
  const mountedRef = React.useRef(true);
  function fortmatDateForRequest(date) {
    return moment(date).format("YYYY-MM-DD");
  }
  const [totalEntree, setTotalEntree] = React.useState(0);
  const [totalSortie, setTotalSortie] = React.useState(0);
  const [totalDommage, setTotalDommage] = React.useState(0);
  const [localDateDebut, setLocalDateDebut] = React.useState("");
  const [localDateFin, setLocalDateFin] = React.useState("");
  const { token, user } = useAuthenticationContext();

  moment.updateLocale("fr", [frLocale]);

  const [chartType, setChartType] = React.useState(
    localStorage.getItem("chartType")
      ? localStorage.getItem("chartType")
      : "bar"
  );

  React.useEffect(() => {
    localStorage.setItem("chartType", chartType);
  }, [chartType]);

  React.useEffect(() => {
    if (mountedRef.current) {
      if (token !== "") {
        const fetchAllProducts = async () => {
          try {
            const result = await apiInstance.get(LIST_PRODUCTS_DATA_URL);
            if (!mountedRef.current) return null;
            setListProducts(result.data);
          } catch (err) {}
        };

        fetchAllProducts();
      }
    }
    return () => {
      mountedRef.current = false;
    };
  }, [token, mountedRef, setListProducts]);

  const [chartStocks, setChartsStocks] = React.useState({
    labels: [],
    datasets: [],
  });

  const [chartStocksOptions, setChartStocksOptions] = React.useState({
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: true,
        text: "",
        position: "top",
      },
    },
  });

  React.useEffect(() => {
    if (listProducts.length > 0) {
      let localProductId = null;
      let localCollectPointId = user?.point_collecte?.id;
      listProducts.forEach((pr) => {
        if (pr.nom === selectedProduct) localProductId = pr.id;
      });

      const fetchStatsStocks = async () => {
        let resultAll = {};
        let URLs = [];
        if (localCollectPointId === null) {
          if (localDateDebut !== "" && localDateFin !== "") {
            URLs = [
              `${STATS_ENTREES}?produit_id=${localProductId}&date_deb=${localDateDebut}&date_fin=${localDateFin}`,
              `${STATS_SORTIES}?produit_id=${localProductId}&date_deb=${localDateDebut}&date_fin=${localDateFin}`,
              `${STATS_DOMMAGES}?produit_id=${localProductId}&date_deb=${localDateDebut}&date_fin=${localDateFin}`,
            ];
          } else {
            URLs = [
              `${STATS_ENTREES}?produit_id=${localProductId}`,
              `${STATS_SORTIES}?produit_id=${localProductId}`,
              `${STATS_DOMMAGES}?produit_id=${localProductId}`,
            ];
          }
          if (localDateDebut !== "" && localDateFin === "") {
            URLs = [
              `${STATS_ENTREES}?produit_id=${localProductId}&date_deb=${localDateDebut}`,
              `${STATS_SORTIES}?produit_id=${localProductId}&date_deb=${localDateDebut}`,
              `${STATS_DOMMAGES}?produit_id=${localProductId}&date_deb=${localDateDebut}`,
            ];
          } else if (localDateDebut === "" && localDateFin !== "") {
            URLs = [
              `${STATS_ENTREES}?produit_id=${localProductId}&date_fin=${localDateFin}`,
              `${STATS_SORTIES}?produit_id=${localProductId}&date_fin=${localDateFin}`,
              `${STATS_DOMMAGES}?produit_id=${localProductId}&date_fin=${localDateFin}`,
            ];
          }
        } else {
          if (localDateDebut !== "" && localDateFin !== "") {
            URLs = [
              `${STATS_ENTREES}?pc_id=${localCollectPointId}&produit_id=${localProductId}&date_deb=${localDateDebut}&date_fin=${localDateFin}`,
              `${STATS_SORTIES}?pc_id=${localCollectPointId}&produit_id=${localProductId}&date_deb=${localDateDebut}&date_fin=${localDateFin}`,
              `${STATS_DOMMAGES}?pc_id=${localCollectPointId}&produit_id=${localProductId}&date_deb=${localDateDebut}&date_fin=${localDateFin}`,
            ];
          } else {
            URLs = [
              `${STATS_ENTREES}?pc_id=${localCollectPointId}&produit_id=${localProductId}`,
              `${STATS_SORTIES}?pc_id=${localCollectPointId}&produit_id=${localProductId}`,
              `${STATS_DOMMAGES}?pc_id=${localCollectPointId}&produit_id=${localProductId}`,
            ];
          }
          if (localDateDebut !== "" && localDateFin === "") {
            URLs = [
              `${STATS_ENTREES}?pc_id=${localCollectPointId}&produit_id=${localProductId}&date_deb=${localDateDebut}`,
              `${STATS_SORTIES}?pc_id=${localCollectPointId}&produit_id=${localProductId}&date_deb=${localDateDebut}`,
              `${STATS_DOMMAGES}?pc_id=${localCollectPointId}&produit_id=${localProductId}&date_deb=${localDateDebut}`,
            ];
          } else if (localDateDebut === "" && localDateFin !== "") {
            URLs = [
              `${STATS_ENTREES}?pc_id=${localCollectPointId}&produit_id=${localProductId}&date_fin=${localDateFin}`,
              `${STATS_SORTIES}?pc_id=${localCollectPointId}&produit_id=${localProductId}&date_fin=${localDateFin}`,
              `${STATS_DOMMAGES}?pc_id=${localCollectPointId}&produit_id=${localProductId}&date_fin=${localDateFin}`,
            ];
          }
        }

        resultAll = await Promise.all(
          URLs.map((url) => {
            return apiInstance
              .get(url)
              .then((response) => {
                return { success: response.data.success, data: response.data };
              })
              .catch((err) => {
                return { success: false };
              });
          })
        );

        if (!mountedRef.current) return null;
        let localLabels = [];
        let localDataSets = [];
        let localDataSetsEntrees = [];
        let localDataSetsSorties = [];
        let localDataSetsDommages = [];
        resultAll.forEach((result) => {
          if (result.success) {
            result.data.pcs.forEach((pc) => localLabels.push(pc.pc_nom));
          }
        });
        localLabels = [...new Set(localLabels)];

        resultAll.forEach((result, index) => {
          if (result.success) {
            switch (index) {
              case 0:
                setTotalEntree(result.data.poids_total / 1000);
                result.data.pcs.forEach((pc) => {
                  let foundIndexEntree = localLabels.indexOf(pc.pc_nom);
                  if (foundIndexEntree === 0 || foundIndexEntree > 0) {
                    localDataSetsEntrees[foundIndexEntree] =
                      pc.poids_total_pc / 1000;
                  }
                });
                break;
              case 1:
                setTotalSortie(result.data.poids_total / 1000);
                result.data.pcs.forEach((pc) => {
                  let foundIndexSortie = localLabels.indexOf(pc.pc_nom);
                  if (foundIndexSortie === 0 || foundIndexSortie > 0) {
                    localDataSetsSorties[foundIndexSortie] =
                      pc.poids_total_pc / 1000;
                  }
                });
                break;
              case 2:
                setTotalDommage(result.data.poids_total / 1000);
                result.data.pcs.forEach((pc) => {
                  let foundIndexDommage = localLabels.indexOf(pc.pc_nom);
                  if (foundIndexDommage === 0 || foundIndexDommage > 0) {
                    localDataSetsDommages[foundIndexDommage] =
                      pc.poids_total_pc / 1000;
                  }
                });
                break;
              default:
                break;
            }
            localDataSets.push({
              label:
                index === 0
                  ? "Entrées Stock"
                  : index === 1
                  ? "Sorties Stock"
                  : index === 2
                  ? "Dommages Stock"
                  : "",
              data:
                index === 0
                  ? localDataSetsEntrees
                  : index === 1
                  ? localDataSetsSorties
                  : localDataSetsDommages,
            });
          } else {
            switch (index) {
              case 0:
                setTotalEntree(0);
                break;
              case 1:
                setTotalSortie(0);
                break;
              case 2:
                setTotalDommage(0);
                break;
              default:
                break;
            }
          }
        });

        setChartStocksOptions((prev) => ({
          ...prev,
          plugins: {
            legend: {
              position: "top",
            },
            title: {
              display: true,
              text: `Répartition des Entrees, Sorties et Dommages du produit ${selectedProduct}`,
              position: "top",
              font: {
                size: 20,
                weight: "bold",
              },
            },
            subtitle: {
              display: true,
              text: `Du ${moment(resultAll[0]?.data?.date_deb).format(
                "L"
              )} au ${moment(resultAll[0]?.data?.date_fin).format("L")}`,
              position: "top",
              font: {
                size: 15,
                weight: "bold",
              },
            },
          },
          scales: {
            y: {
              display: true,
              title: {
                display: true,
                text: "Stock (Tonne)",
              },
            },
          },
        }));

        //Setting Color for each dataset
        localDataSets.forEach((dt) => {
          switch (dt.label) {
            case "Entrées Stock":
              dt["backgroundColor"] = ["#03a9f4"];
              break;
            case "Sorties Stock":
              dt["backgroundColor"] = ["#4caf50"];
              break;
            case "Dommages Stock":
              dt["backgroundColor"] = ["#ef5350"];
              break;
            default:
              break;
          }
        });

        // charts variables for display
        setChartsStocks({ labels: localLabels, datasets: localDataSets });
      };

      fetchStatsStocks();
    }
  }, [
    selectedProduct,
    listProducts,
    localDateDebut,
    localDateFin,
    setTotalDommage,
    setTotalEntree,
    setTotalSortie,
    setChartsStocks,
  ]);

  return (
    <React.Fragment>
      <div className={classes.contentHead}>
        <SelectGroupForDashboard
          hideAgregators
          hideGroupements
          hideCollectPoints
        />
        <TextField
          variant="filled"
          type="date"
          label="Date début"
          InputLabelProps={{ shrink: true }}
          value={localDateDebut}
          onChange={(e) =>
            setLocalDateDebut(fortmatDateForRequest(e.target.value))
          }
          InputProps={{
            endAdornment: (
              <InputAdornment position="start">
                <ClearIcon
                  onClick={() => setLocalDateDebut("")}
                  style={{ cursor: "pointer" }}
                />
              </InputAdornment>
            ),
          }}
        />
        <TextField
          variant="filled"
          type="date"
          label="Date fin"
          InputLabelProps={{ shrink: true }}
          value={localDateFin}
          onChange={(e) =>
            setLocalDateFin(fortmatDateForRequest(e.target.value))
          }
          InputProps={{
            endAdornment: (
              <InputAdornment position="start">
                <ClearIcon
                  onClick={() => setLocalDateFin("")}
                  style={{ cursor: "pointer" }}
                />
              </InputAdornment>
            ),
          }}
        />
      </div>
      <div className={classes.contentBody}>
        <div className={classes.chartsGroup}></div>
        <div
          className="chart-line"
          style={{
            width: "80%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: "20px",
            justifyContent: "center",
            marginTop: "20px",
          }}
        >
          <div className={classes.stickerContainer}>
            <StickerChart
              type="entree"
              text="Total Entrées Stocks"
              value={totalEntree}
            />
            <StickerChart
              type="sortie"
              text="Total Sorties Stocks"
              value={totalSortie}
            />
            <StickerChart
              type="dommage"
              text="Total Dommages Stocks"
              value={totalDommage}
            />
            <StickerChart
              type="reste"
              text="Total Stock Disponible"
              value={(totalEntree - (totalSortie + totalDommage)).toFixed(3)}
            />
          </div>
          {listProducts.length === 0 ? (
            <CircularProgress />
          ) : (
            <CustomBar data={chartStocks} options={chartStocksOptions} />
          )}
        </div>
      </div>
    </React.Fragment>
  );
}

export default ResponsablePointCollectesDasboardContainer;
