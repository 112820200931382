import React from "react";
import { Route, useHistory } from "react-router-dom";
import ConnexionContainer from "../containers/Connexion/ConnexionContainer";
import UnauthorizedPageContainer from "../containers/Unauthorized/UnauthorizedPageContainer";
import { useAuthenticationContext } from "../utils/contexts/AuthenticationProvider";

function PrivateRouteFirewall({ component, allowedRoles, ...options }) {
  const { isLogin, user, token } = useAuthenticationContext();
  const history = useHistory();
  const finalComponent = isLogin
    ? allowedRoles.find((e) => {
        if (user[`${e}`]) return true;
        return false;
      })
      ? component
      : UnauthorizedPageContainer
    : ConnexionContainer;

  // React.useEffect(() => {
  //   console.log("Must Run + login is: " + isLogin);
  //   console.log("token is: " + token);
  //   if (!isLogin) {
  //     history.push("/connexion");
  //     console.log("Push to connexion");
  //   }
  // }, [isLogin]);

  return <Route {...options} component={finalComponent} />;
}

export default PrivateRouteFirewall;
