import { CircularProgress } from "@mui/material";
import React from "react";
import CommonPagesLayout from "../../../components/layouts/Common/CommonPagesLayout";
import { LIST_AGREGATORS_DATA_URL } from "../../../utils/api/apiUrls";
import apiInstance from "../../../utils/api/axios.config";
import { useAuthenticationContext } from "../../../utils/contexts/AuthenticationProvider";
import { useOrganizationsContext } from "../../../utils/contexts/OrganizationsProvider";
import { useUpdatePagesStateContext } from "../../../utils/contexts/UpdatePagesStateProvider";
import AgregatorsTableContainer from "./CustomTable/AgregatorsTableContainer";

function AgregatorsContainer() {
  const { token } = useAuthenticationContext();
  const { setListAgregators } = useOrganizationsContext();
  const { setIsListAgregatorsUpdated, isListAgregatorsUpdated } =
    useUpdatePagesStateContext();
  const [progressState, setProgressState] = React.useState(true);
  React.useEffect(() => {
    if (token !== "") {
      const fetchAllAgregators = async () => {
        try {
          const result = await apiInstance.get(LIST_AGREGATORS_DATA_URL, {
            headers: {
              Authorization: `Token ${token}`,
            },
          });
          setListAgregators(result.data);
          setProgressState(false);
        } catch (err) {
          setProgressState(false);
          return err;
        }
      };

      fetchAllAgregators();
    }
  }, [token, setListAgregators, setProgressState]);

  React.useEffect(() => {
    if (isListAgregatorsUpdated) {
      const fetchAllAgregators = async () => {
        try {
          const result = await apiInstance.get(LIST_AGREGATORS_DATA_URL, {
            headers: {
              Authorization: `Token ${token}`,
            },
          });
          setProgressState(false);
          setListAgregators(result.data);
        } catch (err) {
          setProgressState(false);
          return err;
        }
      };

      fetchAllAgregators();
      setIsListAgregatorsUpdated(false);
    }
  }, [
    isListAgregatorsUpdated,
    setIsListAgregatorsUpdated,
    setListAgregators,
    setProgressState,
    token,
  ]);

  return (
    <CommonPagesLayout>
      <h1>Agrégateurs</h1>
      {progressState ? <CircularProgress /> : <AgregatorsTableContainer />}
    </CommonPagesLayout>
  );
}

export default AgregatorsContainer;
