import {
  FormControl,
  Modal,
  TextField,
  Button,
  ButtonGroup,
  FormHelperText,
  Typography,
  NativeSelect,
} from "@mui/material";

import makeStyles from "@mui/styles/makeStyles";
import Backdrop from "@mui/material/Backdrop";
import Fade from "@mui/material/Fade";
import React from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useOrganizationsContext } from "../../../../utils/contexts/OrganizationsProvider";
import { v4 as uuidv4 } from "uuid";
import FormModalsWrapper from "../FormModalsWrapper";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    zIndex: "99999",
  },
  paper: {
    "& > *": {
      width: "60ch",
    },
    [theme.breakpoints.down("md")]: {
      "& > *": {
        width: "35ch",
      },
    },
    background: "rgba( 255, 255, 255, 0.90 )",
    boxSshadow: "0 8px 32px 0 rgba( 31, 38, 135, 0.50 )",
    backdropFilter: "blur( 4px )",
    borderRadius: "10px",
    border: "1px solid rgba( 255, 255, 255, 0.18 )",
    height: "80%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "flex-start",
  },
  form: {
    display: "flex",
    width: "100%",
    flexDirection: "column",
    height: "100%",
    gap: "10px",
    justifyContent: "space-between",
    alignItems: "center",
    overflowY: "scroll",
    "&::-webkit-scrollbar": {
      width: "20px",
    },
    "&::-webkit-scrollbar-track": {
      backgroundColor: "transparent",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#172b18",
      borderRadius: "20px",
      border: "6px solid transparent",
      backgroundClip: "content-box",
    },
  },
  formRow: {
    marginTop: "10px",
    marginBottom: "10px",
    [theme.breakpoints.down("md")]: {
      marginTop: "5px",
      marginBottom: "5px",
    },
  },
}));

function CommercantsFormModal({
  row,
  rows,
  open,
  handleClose,
  setRows,
  formAction,
}) {
  const classes = useStyles();

  const { listAgregators, createCommercant, updateCommercant } =
    useOrganizationsContext();

  const formik = useFormik({
    initialValues: {
      id: row.id,
      nom: row.nom,
      prenom: row.prenom,
      telephone: row.telephone,
      adresse: row.adresse,
      cni: row.cni,
      newAgregateur: row.agregateurs.length > 0 ? row.agregateurs[0].id : "",
      agregateurs:
        row.agregateurs !== undefined
          ? row.agregateurs.map((ag) => {
              return ag["id"];
            })
          : [],
    },
    enableReinitialize: true,
    validateOnChange: true,

    validationSchema: Yup.object({
      id: Yup.string(),
      nom: Yup.string().required("Champ requis!"),
      prenom: Yup.string().required("Champ requis!"),
      telephone: Yup.string().notRequired().nullable(true),
      adresse: Yup.string().notRequired(),
      cni: Yup.string().notRequired(),
      newAgregateur: Yup.string().required("Champ requis!"),
      agregateurs: Yup.array().required("Champs requis"),
    }),

    onSubmit: (values, onSubmitProps) => {
      let data = {};
      let tempAgregateurs = [];
      tempAgregateurs = values.agregateurs;

      tempAgregateurs.push(JSON.parse(values.newAgregateur));

      data = {
        nom: values.nom,
        prenom: values.prenom,
        agregateur_ids: tempAgregateurs,
        cni: values.cni === "" ? "null" : values.cni,
        telephone: values.telephone === "" ? "null" : values.telephone,
        adresse: values.adresse === "" ? "null" : values.adresse,
        reference: uuidv4(),
      };

      onSubmitProps.setSubmitting(true);
      if (formAction === "add") {
        createCommercant(data);
      } else if (formAction === "update") {
        updateCommercant(row.id, data);
      }
      handleClose();
      onSubmitProps.setSubmitting(false);
    },
  });

  return (
    <FormModalsWrapper
      open={open}
      handleClose={handleClose}
      title={
        formAction === "add" ? "NOUVEAU COMMERCANT" : "MODIFIER COMMERCANT"
      }
    >
      <form
        onSubmit={formik.handleSubmit}
        noValidate
        autoComplete="off"
        className={classes.form}
      >
        <FormControl className={classes.formRow}>
          <TextField
            error={formik.errors.nom ? true : false}
            id="nom"
            type="text"
            variant="outlined"
            label="Nom"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.nom}
            size="small"
          />
          <FormHelperText error={formik.errors.nom ? true : false}>
            {formik.errors.nom}
          </FormHelperText>
        </FormControl>
        <FormControl className={classes.formRow}>
          <TextField
            error={formik.errors.prenom ? true : false}
            id="prenom"
            type="text"
            variant="outlined"
            label="Prenom"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.prenom}
            size="small"
          />
          <FormHelperText error={formik.errors.prenom ? true : false}>
            {formik.errors.prenom}
          </FormHelperText>
        </FormControl>

        <FormControl className={classes.formRow}>
          <TextField
            error={formik.errors.telephone ? true : false}
            id="telephone"
            type="text"
            variant="outlined"
            label="Telephone"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.telephone}
            size="small"
          />
          <FormHelperText error={formik.errors.telephone ? true : false}>
            {formik.errors.telephone}
          </FormHelperText>
        </FormControl>
        <FormControl className={classes.formRow}>
          <TextField
            error={formik.errors.adresse ? true : false}
            id="adresse"
            type="text"
            variant="outlined"
            label="Adresse"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.adresse}
            size="small"
          />
          <FormHelperText error={formik.errors.adresse ? true : false}>
            {formik.errors.adresse}
          </FormHelperText>
        </FormControl>

        <FormControl className={classes.formRow}>
          <TextField
            error={formik.errors.cni ? true : false}
            id="cni"
            type="text"
            variant="outlined"
            label="Cni"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.cni}
            size="small"
          />
          <FormHelperText error={formik.errors.cni ? true : false}>
            {formik.errors.cni}
          </FormHelperText>
        </FormControl>

        <FormControl className={classes.formRow}>
          <NativeSelect
            id="newAgregateur"
            value={formik.values.newAgregateur}
            onChange={formik.handleChange}
          >
            <option value="" disabled={formAction === "update" ? true : false}>
              Choisir un agregateur
            </option>
            {listAgregators.map((c, index) => (
              <option key={index} value={c.id}>
                {c.nom}
              </option>
            ))}
          </NativeSelect>
          <FormHelperText error={formik.errors.newAgregateur ? true : false}>
            {formik.errors.newAgregateur}
          </FormHelperText>
        </FormControl>
        <ButtonGroup
          className={classes.formRow}
          variant="contained"
          aria-label="text primary button group"
        >
          <Button color="primary" type="submit">
            Enregistrer
          </Button>
          <Button color="secondary" onClick={handleClose}>
            Annuler
          </Button>
        </ButtonGroup>
      </form>
    </FormModalsWrapper>
  );
}

export default CommercantsFormModal;
