import { TableCell } from "@mui/material";
import React from "react";
import PropTypes from "prop-types";

/** Component Description:
 *  This component render for each table container,
 *  the row's fields.
 */
function DisplayCodeDommagesTableRow({ row }) {
  return (
    <React.Fragment>
      <TableCell align="left">{row.code}</TableCell>
      <TableCell align="center">{row.nom}</TableCell>
      <TableCell align="center">{row.description}</TableCell>
    </React.Fragment>
  );
}

DisplayCodeDommagesTableRow.propTypes = {
  row: PropTypes.object,
};

export default DisplayCodeDommagesTableRow;
