import { FormControl, InputLabel, NativeSelect, Select } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";
import { useOrganizationsContext } from "../../../../../utils/contexts/OrganizationsProvider";
import { useUpdatePagesStateContext } from "../../../../../utils/contexts/UpdatePagesStateProvider";
import Items from "./Items/Items";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(2),
    minWidth: 150,
  },
}));

function SelectAgregators({ inputValue, setInputValue }) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const { listAgregators } = useOrganizationsContext();
  useUpdatePagesStateContext();

  const handleChangeAgregator = (event) => {
    setInputValue(event.target.value);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  return (
    <React.Fragment>
      <FormControl className={classes.formControl}>
        <InputLabel id="demo-controlled-open-select-label">
          Agrégateurs
        </InputLabel>
        <NativeSelect
          id="demo-controlled-open-select"
          value={inputValue}
          onChange={handleChangeAgregator}
        >
          <Items items={listAgregators} />
        </NativeSelect>
      </FormControl>
    </React.Fragment>
  );
}

export default SelectAgregators;
