import { CircularProgress } from "@mui/material";
import React from "react";
import CommonPagesLayout from "../../../components/layouts/Common/CommonPagesLayout";
import {
  LIST_GROUPMENTS_DATA_URL,
  LIST_PRODUCTORS_DATA_URL,
} from "../../../utils/api/apiUrls";
import apiInstance from "../../../utils/api/axios.config";
import { useAuthenticationContext } from "../../../utils/contexts/AuthenticationProvider";
import { useOrganizationsContext } from "../../../utils/contexts/OrganizationsProvider";
import { useUpdatePagesStateContext } from "../../../utils/contexts/UpdatePagesStateProvider";
import ProductorsTableContainer from "./CustomTable/ProductorsTableContainer";

function ProductorsContainer() {
  const { token } = useAuthenticationContext();
  const { isListProductorsUpdated, setIsListProductorsUpdated } =
    useUpdatePagesStateContext();
  const { setListProductors, setListGroupments } = useOrganizationsContext();
  const [progressState, setProgressState] = React.useState(true);

  React.useEffect(() => {
    if (token !== "") {
      const fetchAllProductors = async () => {
        try {
          const result = await await apiInstance.get(LIST_PRODUCTORS_DATA_URL, {
            headers: {
              Authorization: `Token ${token}`,
            },
          });

          setListProductors(result.data);
          setProgressState(false);
        } catch (err) {
          setProgressState(false);
          return err;
        }
      };
      const fetchAllGroupments = async () => {
        try {
          const result = await apiInstance.get(LIST_GROUPMENTS_DATA_URL, {
            headers: { Authorization: `Token ${token}` },
          });
          setListGroupments(result.data);
        } catch (err) {
          console.log(err);
        }
      };

      fetchAllGroupments();
      fetchAllProductors();
    }
  }, [token, setListProductors, setListGroupments, setProgressState]);

  React.useEffect(() => {
    if (isListProductorsUpdated) {
      setProgressState(true);
      const fetchAllProductors = async () => {
        try {
          const result = await await apiInstance.get(LIST_PRODUCTORS_DATA_URL, {
            headers: {
              Authorization: `Token ${token}`,
            },
          });

          setListProductors(result.data);
          setProgressState(false);
        } catch (err) {
          setProgressState(false);
          return err;
        }
      };
      fetchAllProductors();
      setIsListProductorsUpdated(false);
    }
  }, [
    isListProductorsUpdated,
    setIsListProductorsUpdated,
    setListProductors,
    setProgressState,
    token,
  ]);

  return (
    <CommonPagesLayout>
      <h1>Producteurs</h1>
      {progressState ? <CircularProgress /> : <ProductorsTableContainer />}
    </CommonPagesLayout>
  );
}

export default ProductorsContainer;
