import { MenuItem } from "@mui/material";
import React from "react";
import useStyles from "./styles";

function Items({ items }) {
  const classes = useStyles();
  return (
    <React.Fragment>
      {/* {items.map((item, index) => {
        return (
          <MenuItem key={index} value={item.nom} className={classes.itemText}>
            {item.nom}
          </MenuItem>
        );
      })} */}
      <MenuItem value="ORANGE">ORANGE</MenuItem>
      <MenuItem value="BANANE">BANANE</MenuItem>
      <MenuItem value="OIGNON">OIGNON</MenuItem>
    </React.Fragment>
  );
}

export default Items;
