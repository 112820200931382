import { TableCell } from "@mui/material";
import React from "react";
import PropTypes from "prop-types";

/** Component Description:
 *  This component render for each table container,
 *  the row's fields.
 */
function DisplayProductorsTableRow({ row }) {
  return (
    <React.Fragment>
      <TableCell align="left">{row.reference}</TableCell>
      <TableCell align="center">{row.nom}</TableCell>
      <TableCell align="center">{row.prenom}</TableCell>
      <TableCell align="center">{row.age}</TableCell>
      <TableCell align="center">{row.sexe}</TableCell>
      <TableCell align="center">{row.telephone}</TableCell>
      <TableCell align="center">{row.adresse}</TableCell>
      <TableCell align="center">{row.cni}</TableCell>
      <TableCell align="center">{row.groupement?.nom}</TableCell>
      <TableCell align="center">
        {row.zone !== undefined
          ? `${row.zone.region} | ${row.zone.departement} | ${row.zone.commune}`
          : "NEANT"}
      </TableCell>
    </React.Fragment>
  );
}
DisplayProductorsTableRow.propTypes = {
  row: PropTypes.object,
};

export default DisplayProductorsTableRow;
