import React from "react";
import SelectProduct from "./SelectItems/Products/SelectProduct";
import SelectAgregators from "./SelectItems/Agregators/SelectAgregators";
import SelectGroupments from "./SelectItems/Groupments/SelectGroupments";
import SelectCollectPoint from "./SelectItems/CollectPoints/SelectCollectPoint";
import { useDashboardContext } from "../../../utils/contexts/DashboardProvider";
import useSelectGroupForDasboardStyles from "./SelectGroupForDashboard.styles";
import CustomDateRangePicker from "../CustomDateRangePicker/CustomDateRangePicker";
function SelectGroupForDashboard({
  hideProducts,
  hideCollectPoints,
  hideAgregators,
  hideGroupements,
}) {
  const styles = useSelectGroupForDasboardStyles();
  const {
    selectedAgregator,
    selectedCollectPoint,
    selectedGroupment,
    selectedProduct,
    setSelectedCollectPoint,
    setSelectedAgregator,
    setSelectedGroupment,
    setSelectedProduct,
  } = useDashboardContext();

  // React.useEffect(() => {
  //   let filter = {
  //     produit: selectedProduct,
  //     point_collecte: selectedCollectPoint,
  //     agregateur: selectedAgregator,
  //     groupement: selectedGroupment,
  //   };

  //   const formatFilter = () => {
  //     if (filter.produit === "null") {
  //       filter.produit = JSON.parse(selectedProduct);
  //     }
  //     if (filter.point_collecte === "null") {
  //       filter.point_collecte = JSON.parse(selectedCollectPoint);
  //     }
  //     if (filter.agregateur === "null") {
  //       filter.agregateur = JSON.parse(selectedAgregator);
  //     }
  //     if (filter.groupement === "null") {
  //       filter.groupement = JSON.parse(selectedGroupment);
  //     }
  //   };

  //   const filterForDoghnuts = async () => {
  //     formatFilter();
  //     try {
  //       const result = await apiInstance.post(DOUGHNUTS_DATA_URL, filter, {
  //         headers: { Authorization: `Token ${token}` },
  //       });
  //       const { data } = result;

  //       let tab1 = data[0].map((element) => {
  //         return element.value;
  //       });

  //       let tab2 = data[1].map((element) => {
  //         return element.value;
  //       });

  //       setDoghnutFilteredValues([tab1, tab2]);
  //     } catch (err) {
  //       console.log(err);
  //     }
  //   };

  //   const filterCourbe = async () => {
  //     formatFilter();

  //     try {
  //       const result = await apiInstance.post(COURBE_DATA_URL, filter, {
  //         headers: { Authorization: `Token ${token}` },
  //       });

  //       const { data } = result;

  //       let entriesTab = data.filter((element) => {
  //         return element.label === "Entrées (T)";
  //       });

  //       let entriesTabValues = entriesTab[0].data.map((e) => e[1]);

  //       let outgoingsTab = data.filter((element) => {
  //         return element.label === "Sorties (T)";
  //       });
  //       let outgoingsTabValues = outgoingsTab[0].data.map((e) => e[1]);

  //       setCourbeFilteredValue([entriesTabValues, outgoingsTabValues]);
  //     } catch (err) {
  //       console.log(err);
  //     }
  //   };

  //   const fetchMapMarkers = async () => {
  //     formatFilter();
  //     try {
  //       const result = await apiInstance.post(MAP_DATA_URL, filter, {
  //         headers: { Authorization: `Token ${token}` },
  //       });
  //       setMapMarkers(result.data);
  //     } catch (err) {}
  //   };

  //   filterForDoghnuts();
  //   filterCourbe();
  //   fetchMapMarkers();
  // }, [
  //   selectedProduct,
  //   selectedCollectPoint,
  //   selectedAgregator,
  //   selectedGroupment,
  //   token,
  //   setCourbeFilteredValue,
  //   setDoghnutFilteredValues,
  //   setSelectCollectPointForMap,
  //   setMapMarkers,
  // ]);

  return (
    <React.Fragment>
      <div className={styles.selectGroupContainer}>
        {hideProducts ? null : (
          <SelectProduct
            setInputValue={setSelectedProduct}
            inputValue={selectedProduct}
          />
        )}
        {hideCollectPoints ? null : (
          <SelectCollectPoint
            setInputValue={setSelectedCollectPoint}
            inputValue={selectedCollectPoint}
          />
        )}
        {hideAgregators ? null : (
          <SelectAgregators
            setInputValue={setSelectedAgregator}
            inputValue={selectedAgregator}
            compareInputValue={selectedCollectPoint}
          />
        )}
        {hideGroupements ? null : (
          <SelectGroupments
            setInputValue={setSelectedGroupment}
            inputValue={selectedGroupment}
            compareInputValue={selectedAgregator}
            // listGroupments={listGroupmentsByAgregator}
            // setListGroupments={setListGroupmentsByAgregator}
          />
        )}
      </div>
    </React.Fragment>
  );
}

export default React.memo(SelectGroupForDashboard);
