import {
  FormControl,
  InputLabel,
  MenuItem,
  NativeSelect,
  Select,
} from "@mui/material";
import React from "react";
import useStyles from "./styles";

function CustomSelectIdInput({ inputLabel, inputValue, setInputValue, items }) {
  const [open, setOpen] = React.useState(false);
  const handleChange = (event) => {
    setInputValue(event.target.value);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };
  const classes = useStyles();

  return (
    <React.Fragment>
      <FormControl
        className={classes.formControl}
        variant="filled"
        sx={{ m: 1, minWidth: 195 }}
      >
        <InputLabel id="demo-controlled-open-select-label">
          {inputLabel}
        </InputLabel>
        <Select
          labelId="demo-simple-select-standard-label"
          id="demo-simple-select-standard"
          value={inputValue}
          onChange={handleChange}
          label={inputLabel}
        >
          {items.map((item, index) => {
            return (
              <MenuItem
                key={index}
                value={item.id}
                className={classes.textUpperCase}
              >
                {item.nom}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    </React.Fragment>
  );
}

export default CustomSelectIdInput;
