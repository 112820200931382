import { CircularProgress } from "@mui/material";
import React from "react";
import CommonPagesLayout from "../../../components/layouts/Common/CommonPagesLayout";
import {
  LIST_AGREGATORS_DATA_URL,
  LIST_COMMERCANTS_DATA_URL,
} from "../../../utils/api/apiUrls";
import apiInstance from "../../../utils/api/axios.config";
import { useAuthenticationContext } from "../../../utils/contexts/AuthenticationProvider";
import { useOrganizationsContext } from "../../../utils/contexts/OrganizationsProvider";
import { useUpdatePagesStateContext } from "../../../utils/contexts/UpdatePagesStateProvider";
import CommercantsTableContainer from "./CustomTable/CommercantsTableContainer";

function CommercantsContainer() {
  const { token } = useAuthenticationContext();
  const { setListCommercants, setListAgregators } = useOrganizationsContext();
  const { isListCommercantsUpdated, setIsListCommercantsUpdated } =
    useUpdatePagesStateContext();
  const [progressState, setProgressState] = React.useState(true);
  React.useEffect(() => {
    if (token !== "") {
      const fetchAllCommercants = async () => {
        try {
          const result = await apiInstance.get(LIST_COMMERCANTS_DATA_URL, {
            headers: {
              Authorization: `Token ${token}`,
            },
          });
          setListCommercants(result.data);
          setProgressState(false);
        } catch (err) {
          console.log(err);
        }
      };

      const fetchAllAgregators = async () => {
        try {
          const result = await apiInstance.get(LIST_AGREGATORS_DATA_URL, {
            headers: {
              Authorization: `Token ${token}`,
            },
          });
          setListAgregators(result.data);
        } catch (err) {
          setProgressState(false);
          return err;
        }
      };

      fetchAllAgregators();
      fetchAllCommercants();
    }
  }, [token, setListCommercants, setListAgregators]);

  React.useEffect(() => {
    if (isListCommercantsUpdated) {
      const fetchAllCommercants = async () => {
        try {
          const result = await apiInstance.get(LIST_COMMERCANTS_DATA_URL, {
            headers: {
              Authorization: `Token ${token}`,
            },
          });
          setListCommercants(result.data);
        } catch (err) {
          setProgressState(false);
          return err;
        }
      };
      fetchAllCommercants();
      setIsListCommercantsUpdated(false);
    }
  }, [
    isListCommercantsUpdated,
    setIsListCommercantsUpdated,
    setListCommercants,
    token,
  ]);

  return (
    <CommonPagesLayout>
      <h1>Commerçants</h1>
      {progressState ? <CircularProgress /> : <CommercantsTableContainer />}
    </CommonPagesLayout>
  );
}

export default CommercantsContainer;
