import { Button, ButtonGroup } from "@mui/material";
import React from "react";
import useStyles from "./styles";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import AddIcon from "@mui/icons-material/Add";
import { importFileTable } from "../../../../../services/filesTransfer";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import MailIcon from "@mui/icons-material/Mail";
import * as FileSaver from "file-saver";
import PropTypes from "prop-types";

/**
 *
 * Component Description:
 * This component render the table heads buttons action (add row, donwload file, import file)
 */

function ActionsButtonsGroup({
  tableName,
  tableData,
  setRows,
  formModalInitialRow,
  FormModal,
  disableAddButton,
  disableDownloadButton,
  disableLoadButton,
  disableTemplateButton,
  setFormAction,
  formAction,
  enableEdit,
  customId,
  filteredRows,
  customExport,
  setJSONToSend,
  templateFilePath,
  templateFileName,
}) {
  const classes = useStyles();
  const fileExtension = "xlsx";

  // Modal open state
  const [open, setOpen] = React.useState(false);

  /** Description:
   * Function use to manage the form modal open (opened or closed modal) and form type (add or update a row).
   */
  const handleOpen = () => {
    setOpen(true);
    setFormAction("add");
  };

  /** Description:
   * Function use to close the modal.
   */
  const handleClose = () => {
    setOpen(false);
  };

  const formRef = React.useRef(null);

  return (
    <ButtonGroup className={classes.buttonsGroup}>
      {disableTemplateButton ? null : (
        <Button
          variant="contained"
          type="file"
          className={classes.button}
          startIcon={<MailIcon />}
          onClick={() =>
            FileSaver.saveAs(templateFilePath, templateFileName + ".xlsx")
          }
        >
          Telecharger template
        </Button>
      )}
      {disableLoadButton ? null : (
        <Button
          variant="contained"
          type="file"
          className={classes.button}
          startIcon={<CloudUploadIcon />}
        >
          Importer
          <input
            type="file"
            hidden
            style={{ width: "100%", height: "100%" }}
            onChange={(e) => importFileTable(e.target.files[0], setJSONToSend)}
          ></input>
        </Button>
      )}

      {disableDownloadButton ? null : (
        <Button
          variant="contained"
          color="primary"
          className={classes.button}
          startIcon={<CloudDownloadIcon />}
          onClick={() =>
            customExport(
              filteredRows.length === 0 ? tableData : filteredRows,
              tableName,
              fileExtension
            )
          }
        >
          Exporter
        </Button>
      )}

      {disableAddButton || !enableEdit ? null : (
        <Button
          variant="contained"
          color="primary"
          className={classes.button}
          startIcon={<AddIcon />}
          onClick={handleOpen}
        >
          Ajouter {tableName}
        </Button>
      )}

      {enableEdit && (
        <FormModal
          row={formModalInitialRow}
          rows={tableData}
          open={open}
          handleClose={handleClose}
          setRows={setRows}
          formAction={formAction}
          customId={customId}
          formRef={formRef}
        />
      )}
    </ButtonGroup>
  );
}

ActionsButtonsGroup.propTypes = {
  /**Table name */
  tableName: PropTypes.string.isRequired,
  /** Table rows */
  tableData: PropTypes.array,
  /** Function to set table rows */
  setRows: PropTypes.func.isRequired,
  /** initial modal form values. Required if 'enableEdit' is true */
  formModalInitialRow: PropTypes.object,
  /** Form modal React component */
  FormModal: PropTypes.func,
  disableAddButton: PropTypes.bool,
  disableDownloadButton: PropTypes.bool,
  disableLoadButton: PropTypes.bool,
  disableTemplateButton: PropTypes.bool,
  setFormAction: PropTypes.func,
  formAction: PropTypes.string,
  enableEdit: PropTypes.bool,
  /** Id for a specific use instead of row's id*/
  customId: PropTypes.number,
  filteredRows: PropTypes.array,
  /** export function for a table container*/
  customExport: PropTypes.func,
  /** Set the import file objects for sending*/
  setJSONToSend: PropTypes.func,
  /** Template import file for a table container*/
  templateFilePath: PropTypes.string,
  /** Exported file name */
  templateFileName: PropTypes.string,
};

export default ActionsButtonsGroup;
