import React from "react";
import { toast } from "react-toastify";
import {
  LIST_MARKETS_DATA_URL,
  LIST_MARKETS_PRICES_DATA_URL,
} from "../api/apiUrls";
import apiInstance from "../api/axios.config";
import { useAuthenticationContext } from "./AuthenticationProvider";
import { useUpdatePagesStateContext } from "./UpdatePagesStateProvider";

const MarketsContext = React.createContext();

export const useMarketsContext = () => {
  return React.useContext(MarketsContext);
};

function MarketsProvider({ children }) {
  const [listMarkets, setListMarkets] = React.useState([]);
  const [listMarketsPrices, setListMarketsPrices] = React.useState([]);
  const { token } = useAuthenticationContext();
  const { setIsListMarketsUpdated, setIsListMarketsPricesUpdated } =
    useUpdatePagesStateContext();

  const createMarket = async (data) => {
    try {
      await apiInstance.post(LIST_MARKETS_DATA_URL, data, {
        headers: { Authorization: `Token ${token}` },
      });
      setIsListMarketsUpdated(true);
      toast.success("Marché crée!", {
        position: "bottom-center",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } catch (err) {
      toast.error(JSON.stringify(err.response.data), {
        position: "bottom-center",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  const updateMarket = async (id, data) => {
    try {
      await apiInstance.put(LIST_MARKETS_DATA_URL + `${id}/`, data, {
        headers: { Authorization: `Token ${token}` },
      });
      setIsListMarketsUpdated(true);
      toast.success("Marché mis à jour!", {
        position: "bottom-center",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } catch (err) {
      toast.error(JSON.stringify(err.response.data), {
        position: "bottom-center",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  const deleteMarket = async (row) => {
    try {
      await apiInstance.delete(LIST_MARKETS_DATA_URL + `${row.id}/`, {
        headers: { Authorization: `Token ${token}` },
      });
      setIsListMarketsUpdated(true);
      toast.success("Marché supprimé!", {
        position: "bottom-center",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } catch (err) {
      toast.error(JSON.stringify(err.response.data), {
        position: "bottom-center",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  const createMarketPrice = async (data) => {
    try {
      await apiInstance.post(LIST_MARKETS_PRICES_DATA_URL, data, {
        headers: { Authorization: `Token ${token}` },
      });
      setIsListMarketsPricesUpdated(true);
      toast.success("Marche-Prix crée!", {
        position: "bottom-center",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } catch (err) {
      toast.error(JSON.stringify(err.response.data), {
        position: "bottom-center",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  const updateMarketPrice = async (id, data) => {
    try {
      await apiInstance.put(LIST_MARKETS_PRICES_DATA_URL + `${id}/`, data, {
        headers: { Authorization: `Token ${token}` },
      });
      setIsListMarketsPricesUpdated(true);
      toast.success("Prix-Marché mis à jour!", {
        position: "bottom-center",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } catch (err) {
      toast.error(JSON.stringify(err.response.data), {
        position: "bottom-center",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  const deleteMarketPrice = async (row) => {
    try {
      await apiInstance.delete(LIST_MARKETS_PRICES_DATA_URL + `${row.id}/`, {
        headers: { Authorization: `Token ${token}` },
      });
      setIsListMarketsPricesUpdated(true);
      toast.success("Prix-Marché supprime!", {
        position: "bottom-center",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } catch (err) {
      toast.error(JSON.stringify(err.response.data), {
        position: "bottom-center",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  return (
    <MarketsContext.Provider
      value={{
        listMarkets,
        listMarketsPrices,
        createMarket,
        updateMarket,
        deleteMarket,
        createMarketPrice,
        updateMarketPrice,
        deleteMarketPrice,
        setListMarkets,
        setListMarketsPrices,
      }}
    >
      {children}
    </MarketsContext.Provider>
  );
}

export default MarketsProvider;
