import { makeStyles } from "@mui/styles";

const useSelectGroupForDasboardStyles = makeStyles((theme) => ({
  selectGroupContainer: {
    display: "flex",
    gap: "10px",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
  },
}));

export default useSelectGroupForDasboardStyles;
