import React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { useFormik } from "formik";
import * as Yup from "yup";
import { FormControl, FormHelperText, TextField } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import { LIST_USERS_DATA_URL } from "../../../utils/api/apiUrls";
import { toast } from "react-toastify";
import apiInstance from "../../../utils/api/axios.config";
import { useAuthenticationContext } from "../../../utils/contexts/AuthenticationProvider";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    zIndex: "99999",
  },
  paper: {
    "& > *": {
      width: "70ch",
    },
    [theme.breakpoints.down('md')]: {
      "& > *": {
        width: "35ch",
      },
    },
    background: "rgba( 255, 255, 255, 0.90 )",
    boxSshadow: "0 8px 32px 0 rgba( 31, 38, 135, 0.50 )",
    backdropFilter: "blur( 4px )",
    borderRadius: "10px",
    border: "1px solid rgba( 255, 255, 255, 0.18 )",
    height: "80%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  form: {
    display: "flex",
    flexDirection: "column",
    paddingTop: "40px",
    justifyContent: "flex-start",
    alignItems: "center",
    position: "relative",
    overflowY: "scroll",
    "&::-webkit-scrollbar": {
      width: "20px",
    },
    "&::-webkit-scrollbar-track": {
      backgroundColor: "transparent",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#172b18",
      borderRadius: "20px",
      border: "6px solid transparent",
      backgroundClip: "content-box",
    },
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
      width: 250,
    },
  },
  formRow: {
    marginTop: "10px",
    marginBottom: "10px",
    [theme.breakpoints.down('md')]: {
      marginTop: "5px",
      marginBottom: "5px",
    },
  },
}));

export default function ResetOwnPassword({ setOpen, open }) {
  const classes = useStyles();
  const { token, user } = useAuthenticationContext();

  const updateUserPassword = async (id, data) => {
    try {
      await apiInstance.patch(LIST_USERS_DATA_URL + `${id}/`, data, {
        headers: {
          Authorization: `Token ${token}`,
        },
      });
      toast.success("Mot de Passe mis a jour", {
        position: "bottom-center",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } catch (err) {
      toast.error(JSON.stringify(err.response.data), {
        position: "bottom-center",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  const formik = useFormik({
    initialValues: { password: "", passwordConfirmation: "" },
    enableReinitialize: true,
    validateOnChange: true,
    validationSchema: Yup.object().shape({
      password: Yup.string().required("Champ requis!"),
      passwordConfirmation: Yup.string()
        .oneOf([Yup.ref("password"), null], "Mot de passe different!")
        .default(""),
    }),
    onSubmit: (values, onSubtmitProps) => {
      delete values["passwordConfirmation"];
      let data = { user: { password: values.password } };
      onSubtmitProps.setSubmitting(true);
      updateUserPassword(user.id, data);
      console.log(data);
      onSubtmitProps.setSubmitting(false);
      setOpen(false);
      onSubtmitProps.resetForm();
    },
  });

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Modifier mot de passe</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Voulez vous vraiment changer de mot de passe?
          </DialogContentText>
          <form
            onSubmit={formik.handleSubmit}
            noValidate
            autoComplete="off"
            className={classes.form}
          >
            <FormControl className={classes.formRow}>
              <TextField
                error={formik.errors.password ? true : false}
                id="password"
                type="password"
                variant="outlined"
                label="MOT DE PASSE"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.password}
                size="small"
              />
              <FormHelperText error={formik.errors.password ? true : false}>
                {formik.errors.password}
              </FormHelperText>
            </FormControl>

            <FormControl className={classes.formRow}>
              <TextField
                error={formik.errors.passwordConfirmation ? true : false}
                id="passwordConfirmation"
                type="password"
                variant="outlined"
                label="CONFIRMER MOT DE PASSE"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.passwordConfirmation}
                size="large"
                style={{ padding: "15px 0" }}
              />
              <FormHelperText
                error={formik.errors.passwordConfirmation ? true : false}
              >
                {formik.errors.passwordConfirmation}
              </FormHelperText>
            </FormControl>

            <DialogActions>
              <Button variant="contained" color="primary" type="submit">
                Enregistrer
              </Button>
              <Button
                onClick={() => setOpen(false)}
                variant="contained"
                color="secondary"
                autoFocus
              >
                Annuler
              </Button>
            </DialogActions>
          </form>
        </DialogContent>
      </Dialog>
    </div>
  );
}
