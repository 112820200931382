import React from "react";
import useStyles from "./styles";

function Items({ items, compareInput, inputValue }) {
  const classes = useStyles();
  const [localDepartments, setLocalDepartements] = React.useState([]);

  React.useEffect(() => {
    setLocalDepartements(items.filter((r) => r.region === compareInput));
  }, [compareInput, items]);

  return (
    <React.Fragment>
      <option value="" disabled={inputValue === "" ? false : true}>
        Choisir un departement
      </option>
      {localDepartments.length > 0 &&
        localDepartments[0].departements.map((d, index) => {
          return (
            <option
              value={d.departement}
              key={`${compareInput}-${index}`}
              className={classes.itemText}
            >
              {d.departement}
            </option>
          );
        })}
    </React.Fragment>
  );
}

export default Items;
