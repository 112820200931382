import React from "react";
import "./Dashboard.css";
import moment from "moment";
import frLocale from "moment/locale/fr";
import LeafletMap from "../../components/Map/LeafletMap";
import CustomDoughnut from "../../components/Charts/CustomDoughnut";
import CustomTwoAxisLinesGraph from "../../components/Charts/CustomLinesGraph";
import SelectGroupForDashboard from "../../ui/SelectInput/SelectGroupForDashboard/SelectGroupForDashboard";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import BarChartIcon from "@mui/icons-material/BarChart";
import DonutLargeIcon from "@mui/icons-material/DonutLarge";
import PieChartIcon from "@mui/icons-material/PieChart";
import { useDashboardContext } from "../../utils/contexts/DashboardProvider";
import CustomBar from "../../components/Charts/CustomBar";
import CustomPie from "../../components/Charts/CustomPie";
import { useOrganizationsContext } from "../../utils/contexts/OrganizationsProvider";
import { useAuthenticationContext } from "../../utils/contexts/AuthenticationProvider";
import { useProductsContext } from "../../utils/contexts/ProductsProvider";
import apiInstance from "../../utils/api/axios.config";
import { groupBy, mapValues, omit } from "lodash";
import {
  LIST_AGREGATORS_DATA_URL,
  LIST_COLLECT_POINTS_DATA_URL,
  LIST_GROUPMENTS_DATA_URL,
  LIST_LETTRE_VOITURES_DATA_URL,
  LIST_PRODUCTORS_DATA_URL,
  LIST_PRODUCTS_DATA_URL,
} from "../../utils/api/apiUrls";
// import randomColor from "random-color";
import useStyles from "./styles";
import { CircularProgress } from "@mui/material";
import { useRecordsContext } from "../../utils/contexts/RecordsProvider";

var randomColor = require('randomcolor'); // import the script
var color = randomColor(); // a hex code for an attractive color

function ARMDasboardContainer() {
  const classes = useStyles();
  const mountedRef = React.useRef(true);
  const { token } = useAuthenticationContext();
  const { setListProducts, listProducts } = useProductsContext();
  const {
    setListCollectPoints,
    listCollectPoints,
    listAgregators,
    setListAgregators,
    listGroupments,
    setListGroupments,
    listProductors,
    setListProductors,
  } = useOrganizationsContext();
  const { selectedProduct, selectedAgregator, selectedGroupment } =
    useDashboardContext();

  const { setListLettreVoitures, listLettreVoitures } = useRecordsContext();

  moment.updateLocale("fr", [frLocale]);
  moment().format("l");

  const [chartType, setChartType] = React.useState(
    localStorage.getItem("chartType")
      ? localStorage.getItem("chartType")
      : "bar"
  );

  const [chartVarietesOfProduit, setChartVarietesOfProduit] = React.useState({
    labels: [],
    datasets: [],
  });

  const optionsLines = {
    responsive: true,
    legend: {
      display: true,
      labels: {
        fontSize: 16, //point style's size is based on font style not boxed width.
        usePointStyle: true,
      },
    },
    plugins: {
      legend: {
        position: "bottom",
      },
      title: {
        display: true,
        text: "Entrees et Sortie des Stocks",
      },
    },
    scales: {
      x: {
        display: true,
        title: {
          display: true,
          text: "Jour",
        },
      },
      y: {
        display: true,
        title: {
          display: true,
          text: "Stock",
        },
      },
    },
  };

  const optionsVarietesOfProduit = {
    responsive: true,
    plugins: {
      title: {
        display: true,
        text: "Répartition des variétés par produits",
      },
    },
  };

  const [chartGroupsEtZonesOfAgregateur, setChartGroupsEtZonesOfAgregateur] =
    React.useState({
      labels: [],
      datasets: [],
    });

  const optionsGroupsEtZonesOfAgregateur = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: true,
        text: "Répartion des zones et groupements par agrégateur",
        position: "top",
      },
    },
  };

  const [chartProductorsByGender, setChartProductorsByGender] = React.useState({
    labels: [],
    datasets: [],
  });

  const optionsProductorsByGender = {
    responsive: true,
    plugins: {
      legend: {
        position: "bottom",
      },
      title: {
        display: true,
        text: "Répartition des producteurs par sexe",
        position: "bottom",
      },
    },
  };

  const [chartProductorsOfGroupment, setChartProductorsOfGroupment] =
    React.useState({
      labels: [],
      datasets: [],
    });

  const optionsProductorsOfGroupment = {
    responsive: true,
    plugins: {
      legend: {
        position: "bottom",
      },
      title: {
        display: true,
        text: "Répartition des producteurs par groupement",
        position: "bottom",
      },
    },
  };

  const [chartOutStockQuantityByProduct, setChartOutStockQuantityByProduct] =
    React.useState({
      labels: [],
      datasets: [],
    });

  const optionsOutStockQuantityByProduct = {
    responsive: true,
    plugins: {
      legend: {
        position: "bottom",
      },
      title: {
        display: true,
        text: "Répartition du stock (Tonne) disponible par produit",
        position: "bottom",
      },
    },
    scales: {
      y: {
        display: chartType === "bar" ? true : false,
        title: {
          display: true,
          text: "Stock (Tonne)",
        },
      },
    },
  };

  React.useEffect(() => {
    if (listAgregators.length > 0) {
      let chartColors =
        chartType !== "bar" &&
        listAgregators.map(() => randomColor(0.99, 0.99));

      let agregatorsLabels = [];
      let dataZones = [];
      let dataGroupements = [];

      listAgregators.forEach((p) => {
        let tempTotal = 0;
        let tempTotalForOne = 0;
        if (selectedAgregator === "null") {
          agregatorsLabels.push(p.nom);
          dataZones.push(p.zones?.length);
          listGroupments.forEach((gp) => {
            if (gp.agregateur.id === p.id) {
              tempTotal++;
            }
          });
          dataGroupements.push(tempTotal);
        } else if (JSON.parse(selectedAgregator) === p.id) {
          agregatorsLabels.push(p.nom);
          dataZones.push(p.zones?.length);
          listGroupments.forEach((gp) => {
            if (gp.agregateur.id === p.id) {
              tempTotalForOne++;
            }
          });
          dataGroupements.push(tempTotalForOne);
        }
      });
      setChartGroupsEtZonesOfAgregateur({
        labels: agregatorsLabels,
        datasets: [
          {
            label: "Nombre de zones",
            data: dataZones,
            backgroundColor:
              chartType === "bar"
                ? [randomColor(0.99, 0.99)]
                : chartColors,
            hoverOffset: 4,
          },
          {
            label: "Nombre de groupements",
            data: dataGroupements,
            backgroundColor:
              chartType === "bar"
                ? [randomColor(0.99, 0.99)]
                : chartColors,
            hoverOffset: 4,
          },
        ],
      });
    }
  }, [listAgregators, chartType, selectedAgregator, listGroupments]);

  React.useEffect(() => {
    if (listProducts.length > 0) {
      let chartColors =
        chartType !== "bar" &&
        listProducts.map(() => randomColor(0.99, 0.99));
      let productLabels = [];
      let producVarieties = [];

      listProducts.forEach((p) => {
        if (p.nom === selectedProduct) {
          productLabels.push(p.nom);
          producVarieties.push(p.varietes?.length);
        }
      });

      setChartVarietesOfProduit({
        labels: productLabels,
        datasets: [
          {
            label: "Nombre de variétés",
            data: producVarieties,
            backgroundColor:
              chartType === "bar"
                ? [randomColor(0.99, 0.99)]
                : chartColors,
            hoverOffset: 4,
          },
        ],
      });
    }
  }, [listProducts, chartType, selectedProduct]);

  React.useEffect(() => {
    if (listProductors.length > 0) {
      let chartColors = chartType !== "bar" && [
        randomColor(0.99, 0.99),
        randomColor(0.99, 0.99),
      ];
      let tempTotalH = 0;
      let tempTotalF = 0;
      listProductors.forEach((p) => {
        if (p.sexe === "H") {
          tempTotalH++;
        } else if (p.sexe === "F") {
          tempTotalF++;
        }
      });

      setChartProductorsByGender({
        labels: ["Producteurs Hommes", "Producteurs Femmes"],
        datasets: [
          {
            label: "Nombre de producteurs",
            data: [tempTotalH, tempTotalF],
            backgroundColor:
              chartType === "bar"
                ? [randomColor(0.99, 0.99)]
                : chartColors,
            hoverOffset: 4,
          },
        ],
      });
    }
  }, [listProductors, chartType]);

  React.useEffect(() => {
    if (listGroupments.length > 0) {
      let chartColors =
        chartType !== "bar" &&
        listGroupments
          .filter(
            (gp) =>
              (selectedAgregator === "null" && gp) ||
              (selectedAgregator !== "null" &&
                gp.agregateur?.id === JSON.parse(selectedAgregator))
          )
          .map(() => randomColor(0.99, 0.99));

      let groupmentLabels = [];
      let dataProductors = [];

      listGroupments
        .filter(
          (gp) =>
            (selectedAgregator === "null" && gp) ||
            (selectedAgregator !== "null" &&
              gp.agregateur?.id === JSON.parse(selectedAgregator))
        )
        .forEach((p) => {
          let tempTotal = 0;
          let tempTotalForOne = 0;
          if (selectedGroupment === "null") {
            groupmentLabels.push(p.nom);
            listProductors.forEach((pr) => {
              if (pr.groupement.id === p.id) {
                tempTotal++;
              }
            });
            dataProductors.push(tempTotal);
          } else {
            if (JSON.parse(selectedGroupment) === p.id) {
              groupmentLabels.push(p.nom);
              listProductors.forEach((pr) => {
                if (pr.groupement.id === p.id) {
                  tempTotalForOne++;
                }
              });
              dataProductors.push(tempTotalForOne);
            }
          }
        });

      setChartProductorsOfGroupment({
        labels: groupmentLabels,
        datasets: [
          {
            label: "Nombre de producteurs",
            data: dataProductors,
            backgroundColor:
              chartType === "bar"
                ? [randomColor(0.99, 0.99)]
                : chartColors,
            hoverOffset: 4,
          },
        ],
      });
    }
  }, [
    listProductors,
    chartType,
    selectedGroupment,
    selectedAgregator,
    listGroupments,
  ]);

  React.useEffect(() => {
    if (listLettreVoitures.length > 0) {
      // let lvGroupedByProduct = groupBy(listLettreVoitures, (lv) => lv.mvts);
      // console.log(lvGroupedByProduct)
      //   let temp = listLettreVoitures.map((lv) => {
      //     if (groupBy(lv.mvts, (mvt) => mvt.stock.variete.nom) !== {}) {
      //       return groupBy(lv.mvts, (mvt) => mvt.stock.variete.nom);
      //     }
      //   });
      //   let temp2 = temp.filter((tp) => Object.keys(tp).length > 0);
      //   let Keys = [];
      //   temp2.forEach((tp) => Keys.push(Object.keys(tp)));
      let listLV = [];
      let stock = [];
      // listProducts.forEach((pr) => {
      //   listLettreVoitures.forEach((lv) => {
      //     lv.mvts.forEach((mvt) => {
      //       if ((mvt.stock.variete.produit_id = pr.id)) {
      //         if (mvt.sac_25_KG) {
      //           result.push({
      //             nomProduit: pr.nom,
      //             qty: mvt.sac_25_KG * mvt.nombre_sac,
      //             produitId: pr.id,
      //           });
      //         }
      //         if (mvt.sac_40_KG) {
      //           result.push({
      //             nomProduit: pr.nom,
      //             qty: mvt.sac_40_KG * mvt.nombre_sac,
      //             produitId: pr.id,
      //           });
      //         }
      //         if (!mvt.sac_25_KG && !mvt.sac_40_KG) {
      //           result.push({
      //             nomProduit: pr.nom,
      //             qty: mvt.poids_pesee,
      //             produitId: pr.id,
      //           });
      //         }
      //       }
      //     });
      //   });
      // });
      // let groupedResult = groupBy(result, (r) => r.nomProduit);
      // let finalResult = [];
      // finalResult.push(Object.keys(groupedResult));
      // let Qty = finalResult[0].map(
      //   (fr) => groupedResult[fr].reduce((sum, i) => sum + i.qty, 0) / 1000
      // );
      // finalResult.push(Qty);
      // if (finalResult.length > 0) {
      //   let chartColors =
      //     chartType !== "bar" &&
      //     finalResult[0].map(() => randomColor(0.99, 0.99));
      //   setChartOutStockQuantityByProduct({
      //     labels: finalResult[0],
      //     datasets: [
      //       {
      //         label: "Stock par produit",
      //         data: finalResult[1],
      //         backgroundColor:
      //           chartType === "bar"
      //             ? [randomColor(0.99, 0.99)]
      //             : chartColors,
      //         hoverOffset: 4,
      //       },
      //     ],
      //   });
      // }

      listLettreVoitures.forEach((lv) => {
        let lvMVTS = [];
        if (lv.mvts.length > 0) {
          lv.mvts.forEach((mvt) => {
            let mvtTotal;
            if (mvt.sac_25_KG) {
              mvtTotal = mvt.nombre_sac * 25;
            } else if (mvt.sac_40_KG) {
              mvtTotal = mvt.nombre_sac * 40;
            } else {
              mvtTotal = mvt.poids_pesee;
            }
            stock.push({
              stockQuantity: mvtTotal,
              produit_id: mvt.stock.variete.produit_id,
            });
          });
        }
      });

      let result = {
        labels: [],
        datasets: [],
      };
      listProducts.forEach(
        (pr) => result.labels.push(pr.id) | result.datasets.push([])
      );
      result.labels.forEach((lb, index) => {
        stock.forEach((st) => {
          if (st.produit_id === lb) {
            result.datasets[index].push(st.stockQuantity);
          }
        });
      });
      result.datasets.forEach((data, index) => {
        if (data.length === 0) {
          result.datasets[index] = 0;
        } else if (data.length === 1) {
          result.datasets[index] = data[0] / 1000;
        } else if (data.length > 1) {
          result.datasets[index] =
            data.reduce(
              (previousValue, currentValue) => previousValue + currentValue,
              0
            ) / 1000;
        }
      });

      listProducts.forEach(
        (pr) => (result.labels[result.labels.indexOf(pr.id)] = pr.nom)
      );
      console.log(result);

      let chartColors =
        chartType !== "bar" &&
        result.datasets.map(() => randomColor(0.99, 0.99));
      setChartOutStockQuantityByProduct({
        labels: result.labels,
        datasets: [
          {
            label: "Stock par produit",
            data: result.datasets,
            backgroundColor:
              chartType === "bar"
                ? [randomColor(0.99, 0.99)]
                : chartColors,
            hoverOffset: 4,
          },
        ],
      });
    }
  }, [listLettreVoitures, listProducts, chartType]);

  const [linesData] = React.useState({
    labels: [
      moment().add(-5, "days").format("l"),
      moment().add(-4, "days").format("l"),
      moment().add(-3, "days").format("l"),
      moment().add(-2, "days").format("l"),
      moment().add(-1, "days").format("l"),
      moment().format("l"),
    ],
    datasets: [
      {
        label: "Sorties (T)",
        data: [12, 19, 3, 5, 2, 3],
        fill: false,
        backgroundColor: ["rgb(255, 99, 132)"],
        borderColor: "rgb(255, 99, 132, 0.5)",
        borderWidth: 10,
      },
      {
        label: "Entrées (T)",
        data: [4, 10, 34, 56, 26, 7],
        fill: false,
        backgroundColor: ["rgb(88, 206, 177)"],
        borderColor: "rgb(88, 206, 177, 0.5)",
        borderWidth: 10,
      },
    ],
  });

  // React.useEffect(() => {
  //   let noTrimmedQuantity = doghnutFilteredValues[1];
  //   let trimmedQuantity = [];
  //   let arrayLength = noTrimmedQuantity.length;
  //   if (arrayLength !== 0) {
  //     noTrimmedQuantity.map((element) => {
  //       trimmedQuantity.push(element.substring(0, arrayLength - 1));
  //       return null;
  //     });
  //   }

  //   setQuantitiesData({
  //     labels: [
  //       "Stocks disponibles (en Tonne)",
  //       "Stocks reçus (en Tonne)",
  //       "Qte sorties (en Tonne)",
  //       "Qte de dommages (en Tonne)",
  //     ],
  //     datasets: [
  //       {
  //         label: "Quantites",
  //         data: trimmedQuantity,
  //         backgroundColor: ["#5d9cec", "#6610f2", "#58ceb1", "#f77600"],
  //         hoverOffset: 4,
  //       },
  //     ],
  //   });

  //   setEntitiesData({
  //     labels: [
  //       "Producteurs",
  //       "Points de collecte",
  //       "Groupements",
  //       "Agrégateurs",
  //     ],
  //     datasets: [
  //       {
  //         label: "Entites",
  //         data: doghnutFilteredValues[0],
  //         backgroundColor: ["#5d9cec", "#6610f2", "#58ceb1", "#f77600"],
  //         hoverOffset: 4,
  //       },
  //     ],
  //   });
  // }, [doghnutFilteredValues]);

  // React.useEffect(() => {
  //   setLinesData({
  //     labels: [
  //       moment().add(-5, "days").format("l"),
  //       moment().add(-4, "days").format("l"),
  //       moment().add(-3, "days").format("l"),
  //       moment().add(-2, "days").format("l"),
  //       moment().add(-1, "days").format("l"),
  //       moment().format("l"),
  //     ],
  //     datasets: [
  //       {
  //         label: "Sorties (T)",
  //         data: courbeFilteredValue[1],
  //         fill: false,
  //         backgroundColor: "rgb(255, 99, 132)",
  //         borderColor: "rgba(255, 99, 132, 0.2)",
  //       },
  //       {
  //         label: "Entrées (T)",
  //         data: courbeFilteredValue[0],
  //         fill: false,
  //         backgroundColor: "rgb(88, 206, 177)",
  //         borderColor: "rgb(88, 206, 177, 0.2)",
  //       },
  //     ],
  //   });
  // }, [courbeFilteredValue]);

  React.useEffect(() => {
    localStorage.setItem("chartType", chartType);
  }, [chartType]);

  React.useEffect(() => {
    if (mountedRef.current) {
      if (token !== "") {
        const fetchAllProducts = async () => {
          try {
            const result = await apiInstance.get(LIST_PRODUCTS_DATA_URL);
            if (!mountedRef.current) return null;
            setListProducts(result.data);
          } catch (err) {}
        };

        const fetchAllCollectPoints = async () => {
          try {
            const result = await apiInstance.get(LIST_COLLECT_POINTS_DATA_URL);
            if (!mountedRef.current) return null;
            setListCollectPoints(result.data);
          } catch (err) {}
        };

        const fetchAllAgregators = async () => {
          try {
            const result = await apiInstance.get(LIST_AGREGATORS_DATA_URL, {
              headers: {
                Authorization: `Token ${token}`,
              },
            });
            if (!mountedRef.current) return null;
            setListAgregators(result.data);
          } catch (err) {}
        };

        const fetchAllGroupments = async () => {
          try {
            const result = await apiInstance.get(LIST_GROUPMENTS_DATA_URL);
            if (!mountedRef.current) return null;
            setListGroupments(result.data);
          } catch (err) {}
        };

        const fetchAllProductors = async () => {
          try {
            const result = await apiInstance.get(LIST_PRODUCTORS_DATA_URL);
            if (!mountedRef.current) return null;
            setListProductors(result.data);
          } catch (err) {}
        };

        const fetchAllLettreVoitures = async () => {
          try {
            const result = await apiInstance.get(
              LIST_LETTRE_VOITURES_DATA_URL,
              {
                headers: { Authorization: `Token ${token}` },
              }
            );

            setListLettreVoitures(result.data);
          } catch (err) {
            return err;
          }
        };

        fetchAllProducts();
        fetchAllCollectPoints();
        fetchAllAgregators();
        fetchAllGroupments();
        fetchAllProductors();
        fetchAllLettreVoitures();
      }
    }
    return () => {
      mountedRef.current = false;
    };
  }, [
    token,
    mountedRef,
    setListAgregators,
    setListCollectPoints,
    setListGroupments,
    setListProductors,
    setListProducts,
    setListLettreVoitures,
  ]);
  return (
    <React.Fragment>
      <div className={classes.contentHead}>
        <SelectGroupForDashboard />
      </div>
      <div className={classes.contentBody}>
        {/* Style need to be made inline */}
        <ButtonGroup
          variant="text"
          color="primary"
          aria-label="contained primary button group"
        >
          <Button
            startIcon={<DonutLargeIcon />}
            onClick={() => {
              setChartType("doghnut");
            }}
          ></Button>
          <Button
            startIcon={<BarChartIcon />}
            onClick={() => {
              setChartType("bar");
            }}
          ></Button>
          <Button
            startIcon={<PieChartIcon />}
            onClick={() => {
              setChartType("pie");
            }}
          ></Button>
        </ButtonGroup>
        <div className={classes.chartsGroup}>
          {chartType === "doghnut" && (
            <React.Fragment>
              <div className={classes.chart}>
                {listProducts.length === 0 ? (
                  <CircularProgress />
                ) : (
                  <CustomDoughnut
                    data={chartVarietesOfProduit}
                    options={optionsVarietesOfProduit}
                  />
                )}
              </div>

              <div className={classes.chart}>
                {listAgregators.length === 0 ? (
                  <CircularProgress />
                ) : (
                  <CustomDoughnut
                    data={chartGroupsEtZonesOfAgregateur}
                    options={optionsGroupsEtZonesOfAgregateur}
                  />
                )}
              </div>
            </React.Fragment>
          )}

          {chartType === "bar" && (
            <React.Fragment>
              <div className={classes.chart}>
                {listProducts.length === 0 ? (
                  <CircularProgress />
                ) : (
                  <CustomBar
                    data={chartVarietesOfProduit}
                    options={optionsVarietesOfProduit}
                  />
                )}
              </div>

              <div className={classes.chart}>
                {listAgregators.length === 0 ? (
                  <CircularProgress />
                ) : (
                  <CustomBar
                    data={chartGroupsEtZonesOfAgregateur}
                    options={optionsGroupsEtZonesOfAgregateur}
                  />
                )}
              </div>
            </React.Fragment>
          )}

          {chartType === "pie" && (
            <React.Fragment>
              <div className={classes.chart}>
                {listProducts.length === 0 ? (
                  <CircularProgress />
                ) : (
                  <CustomPie
                    data={chartVarietesOfProduit}
                    options={optionsVarietesOfProduit}
                  />
                )}
              </div>

              <div className={classes.chart}>
                {listAgregators.length === 0 ? (
                  <CircularProgress />
                ) : (
                  <CustomPie
                    data={chartGroupsEtZonesOfAgregateur}
                    options={optionsGroupsEtZonesOfAgregateur}
                  />
                )}
              </div>
            </React.Fragment>
          )}
        </div>
        <div className={classes.chartsGroup}>
          {chartType === "doghnut" && (
            <React.Fragment>
              <div className={classes.chart}>
                {listProductors.length === 0 ? (
                  <CircularProgress />
                ) : (
                  <CustomDoughnut
                    data={chartProductorsByGender}
                    options={optionsProductorsByGender}
                  />
                )}
              </div>

              <div className={classes.chart}>
                {listAgregators.length === 0 && listGroupments.length === 0 ? (
                  <CircularProgress />
                ) : (
                  <CustomDoughnut
                    data={chartProductorsOfGroupment}
                    options={optionsProductorsOfGroupment}
                  />
                )}
              </div>
            </React.Fragment>
          )}

          {chartType === "bar" && (
            <React.Fragment>
              <div className={classes.chart}>
                {listProductors.length === 0 ? (
                  <CircularProgress />
                ) : (
                  <CustomBar
                    data={chartProductorsByGender}
                    options={optionsProductorsByGender}
                  />
                )}
              </div>

              <div className={classes.chart}>
                {listAgregators.length === 0 && listGroupments.length === 0 ? (
                  <CircularProgress />
                ) : (
                  <CustomBar
                    data={chartProductorsOfGroupment}
                    options={optionsProductorsOfGroupment}
                  />
                )}
              </div>
            </React.Fragment>
          )}

          {chartType === "pie" && (
            <React.Fragment>
              <div className={classes.chart}>
                {listProductors.length === 0 ? (
                  <CircularProgress />
                ) : (
                  <CustomPie
                    data={chartProductorsByGender}
                    options={optionsProductorsByGender}
                  />
                )}
              </div>

              <div className={classes.chart}>
                {listAgregators.length === 0 && listGroupments.length === 0 ? (
                  <CircularProgress />
                ) : (
                  <CustomPie
                    data={chartProductorsOfGroupment}
                    options={optionsProductorsOfGroupment}
                  />
                )}
              </div>
            </React.Fragment>
          )}
        </div>
        <div
          style={{
            display: "flex",
            borderBottom: "2px solid rgba(0, 0, 0, 0.12)",
            width: "90%",
          }}
        />
        <div className={classes.chartsGroup}>
          {chartType === "doghnut" && (
            <React.Fragment>
              <div className={classes.chart}>
                {listLettreVoitures.length === 0 ? (
                  <CircularProgress />
                ) : (
                  <CustomDoughnut
                    data={chartOutStockQuantityByProduct}
                    options={optionsOutStockQuantityByProduct}
                  />
                )}
              </div>
              <div className={classes.chart}></div>
            </React.Fragment>
          )}

          {chartType === "bar" && (
            <React.Fragment>
              <div className={classes.chart}>
                {listLettreVoitures.length === 0 ? (
                  <CircularProgress />
                ) : (
                  <CustomBar
                    data={chartOutStockQuantityByProduct}
                    options={optionsOutStockQuantityByProduct}
                  />
                )}
              </div>
              <div className={classes.chart}></div>
            </React.Fragment>
          )}

          {chartType === "pie" && (
            <React.Fragment>
              <div className={classes.chart}>
                {listLettreVoitures.length === 0 ? (
                  <CircularProgress />
                ) : (
                  <CustomPie
                    data={chartOutStockQuantityByProduct}
                    options={optionsOutStockQuantityByProduct}
                  />
                )}
              </div>
              <div className={classes.chart}></div>
            </React.Fragment>
          )}
        </div>
        <div
          className="chart-line"
          style={{
            width: "80%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginTop: "20px",
          }}
        >
          <CustomTwoAxisLinesGraph data={linesData} options={optionsLines} />
        </div>
        <div className={classes.map}>
          <LeafletMap listCollectPoints={listCollectPoints} />
        </div>
      </div>
    </React.Fragment>
  );
}

export default ARMDasboardContainer;
