import { CircularProgress } from "@mui/material";
import React from "react";
import CommonPagesLayout from "../../../components/layouts/Common/CommonPagesLayout";
import { LIST_SORTIE_DOMMAGES_DATA_URL } from "../../../utils/api/apiUrls";
import apiInstance from "../../../utils/api/axios.config";
import { useAuthenticationContext } from "../../../utils/contexts/AuthenticationProvider";
import { useRecordsContext } from "../../../utils/contexts/RecordsProvider";
import SortieDommagesTableContainer from "./CustomTable/SortieDommagesTableContainer";

function SortieDommagesContainer() {
  const { token } = useAuthenticationContext();
  const { setListSortieDommages } = useRecordsContext();
  const [progressState, setProgressState] = React.useState(true);

  React.useEffect(() => {
    if (token !== "") {
      const fetchAllSortieDommages = async () => {
        try {
          const result = await apiInstance.get(LIST_SORTIE_DOMMAGES_DATA_URL, {
            headers: { Authorization: `Token ${token}` },
          });
          setListSortieDommages(result.data);
          setProgressState(false);
        } catch (err) {
          setProgressState(false);
          return err;
        }
      };

      fetchAllSortieDommages();
    }
  }, [token, setListSortieDommages, setProgressState]);

  return (
    <CommonPagesLayout>
      <h1>Sorties Dommages</h1>
      {progressState ? <CircularProgress /> : <SortieDommagesTableContainer />}
    </CommonPagesLayout>
  );
}

export default SortieDommagesContainer;
