import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
  searchGroup: {
    display: "flex",
    alignItems: "center",
    width: "100%",
    alignItems: "center",
    justifyContent: "flex-start",
    gap: "10px",
  },
  searchInput: { display: "flex", paddingLeft: "5px", flex: 1 },
}));

export default useStyles;
