import React from "react";
import { Container, Nav, Navbar } from "react-bootstrap";
import { Link } from "react-router-dom";
import Logo from "../../assets/logoSysStock.svg";
import withNavBarAuthenticated from "../../components/hoc/withNavBarAuthenticated";
import NavLoginLogout from "./NavItems/NavLoginLogout/NavLoginLogout";
import "bootstrap/dist/css/bootstrap.css";
import useStyles from "./styles";
import { useAuthenticationContext } from "../../utils/contexts/AuthenticationProvider";

function CustomNavBar() {
  const classes = useStyles();
  const { user } = useAuthenticationContext();

  return (
    <div className={classes.navbarWrapper}>
      <Navbar className={classes.navbar} expand="xl" variant="dark">
        <Navbar.Brand>
          <Link to="/dashboard" style={{ textDecoration: "none" }}>
            <img
              src={Logo}
              alt="SysStock 3"
              style={{ width: "60px", objectFit: "cover" }}
            />
          </Link>
        </Navbar.Brand>

        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Container className="text-white">
            {/* Display Nav items for the current user */}
            {withNavBarAuthenticated(Nav, user)}
          </Container>
          <NavLoginLogout />
        </Navbar.Collapse>
      </Navbar>
    </div>
  );
}

export default CustomNavBar;
