import React from "react";

function Items({ items }) {
  return (
    <React.Fragment>
      <option value="null">TOUT</option>;
      {items.map((item, index) => {
        return (
          <option key={index} value={item.id}>
            {item.nom}
          </option>
        );
      })}
      
    </React.Fragment>
  );
}

export default Items;
