import React from "react";

function Items({ compareInputValue, listGroupments }) {
  return (
    <React.Fragment>
      <option value="null">TOUT</option>
      {compareInputValue === "null"
        ? listGroupments.map((gp, index) => (
            <option key={gp.id} value={gp.id}>
              {gp.nom}
            </option>
          ))
        : listGroupments.map((gp, index) => {
            if (JSON.parse(compareInputValue) === gp.agregateur.id) {
              return (
                <option key={index} value={gp.id}>
                  {gp.nom}
                </option>
              );
            }
            return null;
          })}
    </React.Fragment>
  );
}

export default Items;
