import {
  Backdrop,
  Collapse,
  Container,
  Dialog,
  DialogContent,
  Fade,
  Modal,
  Typography,
  Zoom,
} from "@mui/material";
import React, { createRef, useRef } from "react";
import useFormModalsWrapperStyles from "./FormModalsWrapper.style";

const DialogTransition = React.forwardRef((props, ref) => (
  <Fade ref={ref} {...props} />
));

function FormModalsWrapper({ open, title, handleClose, children }) {
  const classes = useFormModalsWrapperStyles();
  const modalRef = createRef(null);
  return (
    <Dialog
      ref={modalRef}
      TransitionComponent={DialogTransition}
      className={classes.modal}
      open={open}
      onClose={handleClose}
      closeAfterTransition
    >
      <div className={classes.paperContainer}>
        <Typography variant="h5" className={classes.paperHead}>
          {title}
        </Typography>
        <DialogContent className={classes.paperContent}>
          {children}
        </DialogContent>
      </div>
    </Dialog>
  );
}

export default FormModalsWrapper;
