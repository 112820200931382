import React from "react";
import useStyles from "./styles";

function Items({ items }) {
  const classes = useStyles();
  return (
    <React.Fragment>
      <option value="null">TOUT</option>;
      {items.map((item, index) => {
        return (
          <option key={index} value={item.nom} className={classes.itemText}>
            {item.nom}
          </option>
        );
      })}
    </React.Fragment>
  );
}

export default Items;
