import { CircularProgress } from "@mui/material";
import React from "react";
import CommonPagesLayout from "../../../components/layouts/Common/CommonPagesLayout";
import { LIST_PRODUCTS_DATA_URL } from "../../../utils/api/apiUrls";
import apiInstance from "../../../utils/api/axios.config";
import { useAuthenticationContext } from "../../../utils/contexts/AuthenticationProvider";
import { useProductsContext } from "../../../utils/contexts/ProductsProvider";
import { useUpdatePagesStateContext } from "../../../utils/contexts/UpdatePagesStateProvider";
import ProductsListTableContainer from "./CustomTable/ProductsListTableContainer";

function ProductsListContainer() {
  const { token } = useAuthenticationContext();
  const { isListProductsUpdated, setIsListProductsUpdated } =
    useUpdatePagesStateContext();
  const { setListProducts } = useProductsContext();
  const [progressState, setProgressState] = React.useState(true);

  React.useEffect(() => {
    if (token !== "") {
      const fetchAllProducts = async () => {
        try {
          const result = await apiInstance.get(LIST_PRODUCTS_DATA_URL, {
            headers: { Authorization: `Token ${token}` },
          });
          setListProducts(result.data);
          setProgressState(false);
        } catch (err) {
          setProgressState(false);
          return err;
        }
      };

      fetchAllProducts();
    }
  }, [token, setListProducts]);

  React.useEffect(() => {
    if (isListProductsUpdated) {
      const fetchAllProducts = async () => {
        try {
          const result = await apiInstance.get(LIST_PRODUCTS_DATA_URL, {
            headers: { Authorization: `Token ${token}` },
          });
          setListProducts(result.data);
          setProgressState(false);
        } catch (err) {
          setProgressState(false);
          return err;
        }
      };

      fetchAllProducts();
      setIsListProductsUpdated(false);
    }
  }, [
    isListProductsUpdated,
    setIsListProductsUpdated,
    setListProducts,
    token,
    setProgressState,
  ]);

  return (
    <CommonPagesLayout>
      <h1>Produits</h1>
      {progressState ? <CircularProgress /> : <ProductsListTableContainer />}
    </CommonPagesLayout>
  );
}

export default ProductsListContainer;
