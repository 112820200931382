import React from "react";
import CommonPagesLayout from "../../../../components/layouts/Common/CommonPagesLayout";

function ControlLettreVoiture() {
  // let { reference } = useParams();
  return (
    <CommonPagesLayout>
      <h1>Lettre Voiture</h1>
      <div
        className="content"
        style={{
          display: "flex",
          width: "100%",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        En construction ...
      </div>
    </CommonPagesLayout>
  );
}

export default ControlLettreVoiture;
