import React from "react";
import { Route } from "react-router-dom";
import ConnexionContainer from "../containers/Connexion/ConnexionContainer";
import DashboardContainer from "../containers/Dashboard/DashboardContainer";
import MarketsListContainer from "../containers/Markets/MarketsList/MarketsListContainer";
import MarketsPricesContainer from "../containers/Markets/MarketsPrices/MarketsPricesContainer";
import AgregatorsContainer from "../containers/Organisations/Agregators/AgregatorsContainer";
import GroupmentsContainer from "../containers/Organisations/Groupments/GroupmentsContainer";
import MerchantsContainer from "../containers/Organisations/Commercants/CommercantsContainer";
import ProductorsContainer from "../containers/Organisations/Productors/ProductorsContainer";
import ProductsListContainer from "../containers/Products/ProductsList/ProductsListContainer";
import VarietiesListOfAProductContainer from "../containers/Products/ProductsList/VarietiesListOfAProduct/VarietiesListOfAProductContainer";
import VarietiesListContainer from "../containers/Products/VarietiesList/VarietiesListContainer";
import CodeDommagesContainer from "../containers/Records/CodeDommages/CodeDommagesContainer";
import DashboardProvider from "../utils/contexts/DashboardProvider";
import MarketsProvider from "../utils/contexts/MarketsProvider";
import OrganizationsProvider from "../utils/contexts/OrganizationsProvider";
import ProductsProvider from "../utils/contexts/ProductsProvider";
import RecordsProvider from "../utils/contexts/RecordsProvider";
import UsersProvider from "../utils/contexts/UsersProvider";
import PrivateRouteFirewall from "./PrivateRouteFirewall";
import EntreeStockContainer from "../containers/Records/EntreeStocks/EntreeStockContainer";
import LettreVoituresContainer from "../containers/Records/LettreVoitures/LettreVoituresContainer";
import DetailsLettreVoitureContainer from "../containers/Records/LettreVoitures/DetailsLettreVoiture/DetailsLettreVoitureContainer";
import ControlLettreVoiture from "../containers/Records/LettreVoitures/ControlLettreVoiture/ControlLettreVoiture";
import CollectPointsContainer from "../containers/Organisations/CollectPoints/CollectPointsContainer";
import CoxeursContainer from "../containers/Organisations/Coxeurs/CoxeursContainer";
import SortieDommagesContainer from "../containers/Records/SortieDommages/SortieDommagesContainer";
import ProfileContainer from "../containers/Profile/ProfileContainer";
import DetailsEntreeStockContainer from "../containers/Records/EntreeStocks/DetailsEntreeStock/DetailsEntreeStockContainer";
import DetailsSortieDommagesContainer from "../containers/Records/SortieDommages/DetailsSortieDommages/DetailsSortieDommagesContainer";
import DetailsUserContainer from "../containers/Users/AdminGeneralEtBanque/DetailsUser/DetailsUserContainer";
import UsersContainer from "../containers/Users/AdminGeneralEtBanque/AdminGeneralEtBanque";
import UsersAutresContainer from "../containers/Users/Autres/UsersAutresContainer";
import DetailsUserAutresContainer from "../containers/Users/Autres/DetailsUser/DetailsUserAutresContainer";
import UpdatePagesStateProvider from "../utils/contexts/UpdatePagesStateProvider";
import CombineProvider from "../utils/contexts/CombineProvider";
import AuthenticationProvider from "../utils/contexts/AuthenticationProvider";
import AdminGeneralEtBanque from "../containers/Users/AdminGeneralEtBanque/AdminGeneralEtBanque";

function AllRoutes() {
  return (
    <React.Fragment>
      <CombineProvider
        components={[
          AuthenticationProvider,
          UpdatePagesStateProvider,
          DashboardProvider,
          ProductsProvider,
          RecordsProvider,
          OrganizationsProvider,
          MarketsProvider,
          UsersProvider,
        ]}
      >
        <Route exact path="/connexion" component={ConnexionContainer} />
        <PrivateRouteFirewall
          exact
          path="/profile"
          component={ProfileContainer}
          allowedRoles={[
            "is_administrateur",
            "is_admin_agregateur",
            "is_admin_groupement",
            "is_admin_banque",
            "is_payeur_banque",
            "is_agent_arm",
            "is_agent_marche",
            "is_commercant",
            "is_producteur",
            "is_admin_arm",
            "is_admin_saed",
            "is_resp_point_collecte",
          ]}
        />

        <Route
          exact
          path="/control-lettrevoiture/:reference"
          component={ControlLettreVoiture}
        />

        <PrivateRouteFirewall
          key="rootURL"
          exact
          path="/"
          component={DashboardContainer}
          allowedRoles={[
            "is_administrateur",
            "is_admin_agregateur",
            "is_admin_groupement",
            "is_admin_arm",
            "is_agent_arm",
            "is_admin_saed",
            "is_resp_point_collecte",
            "is_admin_banque",
          ]}
        />
        <PrivateRouteFirewall
          key="dashboard"
          exact
          path="/dashboard"
          component={DashboardContainer}
          allowedRoles={[
            "is_administrateur",
            "is_admin_agregateur",
            "is_admin_groupement",
            "is_admin_arm",
            "is_agent_arm",
            "is_admin_saed",
            "is_resp_point_collecte",
            "is_admin_banque",
          ]}
        />
        {/* 
        <PrivateRouteFirewall
          exact
          path="/utilisateurs/admin-general-banque"
          component={AdminGeneralEtBanque}
          allowedRoles={["is_administrateur", "is_admin_banque"]}
        /> */}

        <PrivateRouteFirewall
          exact
          path="/utilisateurs"
          component={UsersAutresContainer}
          allowedRoles={[
            "is_administrateur",
            "is_admin_agregateur",
            "is_admin_groupement",
            "is_admin_arm",
            "is_admin_saed",
          ]}
        />

        <PrivateRouteFirewall
          exact
          path="/utilisateurs/:id"
          component={DetailsUserAutresContainer}
          allowedRoles={[
            "is_administrateur",
            "is_admin_agregateur",
            "is_admin_groupement",
            "is_admin_saed",
          ]}
        />

        <PrivateRouteFirewall
          exact
          path="/utilisateurs/admin-general-banque/:id"
          component={DetailsUserContainer}
          allowedRoles={[
            "is_administrateur",
            "is_admin_banque",
            "is_admin_arm",
            "is_admin_saed",
          ]}
        />

        <PrivateRouteFirewall
          exact
          path="/produits"
          component={ProductsListContainer}
          allowedRoles={[
            "is_administrateur",
            "is_admin_agregateur",
            "is_admin_groupement",
            "is_admin_saed",
            "is_admin_banque",
          ]}
        />
        <PrivateRouteFirewall
          exact
          path="/varietes"
          component={VarietiesListContainer}
          allowedRoles={[
            "is_administrateur",
            "is_admin_agregateur",
            "is_admin_groupement",
            "is_admin_saed",
            "is_admin_banque",
          ]}
        />

        <PrivateRouteFirewall
          exact
          path="/produits/:id"
          component={VarietiesListOfAProductContainer}
          allowedRoles={[
            "is_administrateur",
            "is_admin_agregateur",
            "is_admin_groupement",
            "is_admin_saed",
            "is_admin_banque",
          ]}
        />

        <PrivateRouteFirewall
          exact
          path="/marches/liste"
          component={MarketsListContainer}
          allowedRoles={[
            "is_administrateur",
            "is_admin_arm",
            "is_agent_arm",
            "is_admin_saed",
            "is_admin_banque",
          ]}
        />

        <PrivateRouteFirewall
          exact
          path="/marches/prix"
          component={MarketsPricesContainer}
          allowedRoles={[
            "is_administrateur",
            "is_admin_arm",
            "is_agent_arm",
            "is_admin_saed",
            "is_admin_banque",
          ]}
        />

        <PrivateRouteFirewall
          exact
          path="/enregistrements/lettrevoitures"
          component={LettreVoituresContainer}
          allowedRoles={[
            "is_administrateur",
            "is_admin_agregateur",
            "is_admin_groupement",
            "is_admin_saed",
            "is_admin_banque",
          ]}
        />

        <PrivateRouteFirewall
          exact
          path="/enregistrements/lettrevoitures/:id"
          component={DetailsLettreVoitureContainer}
          allowedRoles={[
            "is_administrateur",
            "is_admin_agregateur",
            "is_admin_groupement",
            "is_admin_saed",
            "is_admin_banque",
          ]}
        />

        <PrivateRouteFirewall
          exact
          path="/enregistrements/entreestocks"
          component={EntreeStockContainer}
          allowedRoles={[
            "is_administrateur",
            "is_admin_agregateur",
            "is_admin_groupement",
            "is_admin_saed",
            "is_admin_banque",
          ]}
        />

        <PrivateRouteFirewall
          exact
          path="/enregistrements/entreestocks/:id"
          component={DetailsEntreeStockContainer}
          allowedRoles={[
            "is_administrateur",
            "is_admin_agregateur",
            "is_admin_groupement",
            "is_admin_saed",
            "is_admin_banque",
          ]}
        />

        <PrivateRouteFirewall
          exact
          path="/enregistrements/sortiedommages"
          component={SortieDommagesContainer}
          allowedRoles={[
            "is_administrateur",
            "is_admin_agregateur",
            "is_admin_groupement",
            "is_admin_saed",
            "is_admin_banque",
          ]}
        />

        <PrivateRouteFirewall
          exact
          path="/enregistrements/sortiedommages/:id"
          component={DetailsSortieDommagesContainer}
          allowedRoles={[
            "is_administrateur",
            "is_admin_agregateur",
            "is_admin_groupement",
            "is_admin_saed",
            "is_admin_banque",
          ]}
        />

        <PrivateRouteFirewall
          exact
          path="/organisations/points-collecte"
          component={CollectPointsContainer}
          allowedRoles={[
            "is_administrateur",
            "is_admin_agregateur",
            "is_admin_groupement",
            "is_admin_saed",
            "is_admin_banque",
          ]}
        />
        <PrivateRouteFirewall
          exact
          path="/enregistrements/coxeurs"
          component={CoxeursContainer}
          allowedRoles={[
            "is_administrateur",
            "is_admin_agregateur",
            "is_admin_groupement",
            "is_admin_saed",
            "is_admin_banque",
          ]}
        />

        <PrivateRouteFirewall
          exact
          path="/enregistrements/codedommages"
          component={CodeDommagesContainer}
          allowedRoles={[
            "is_administrateur",
            "is_admin_agregateur",
            "is_admin_groupement",
            "is_admin_saed",
            "is_admin_banque",
          ]}
        />

        <PrivateRouteFirewall
          exact
          path="/organisations/agregateurs"
          component={AgregatorsContainer}
          allowedRoles={[
            "is_administrateur",
            "is_admin_agregateur",
            "is_admin_groupement",
            "is_admin_saed",
            "is_admin_banque",
          ]}
        />
        <PrivateRouteFirewall
          exact
          path="/organisations/groupements"
          component={GroupmentsContainer}
          allowedRoles={[
            "is_administrateur",
            "is_admin_agregateur",
            "is_admin_groupement",
            "is_admin_saed",
            "is_admin_banque",
          ]}
        />

        <PrivateRouteFirewall
          exact
          path="/organisations/producteurs"
          component={ProductorsContainer}
          allowedRoles={[
            "is_administrateur",
            "is_admin_agregateur",
            "is_admin_groupement",
            "is_admin_saed",
            "is_admin_banque",
          ]}
        />

        <PrivateRouteFirewall
          exact
          path="/organisations/commercants"
          component={MerchantsContainer}
          allowedRoles={[
            "is_administrateur",
            "is_admin_agregateur",
            "is_admin_groupement",
            "is_admin_saed",
            "is_admin_banque",
          ]}
        />
      </CombineProvider>
    </React.Fragment>
  );
}

export default AllRoutes;
