import { CircularProgress } from "@mui/material";
import React from "react";
import CommonPagesLayout from "../../../components/layouts/Common/CommonPagesLayout";
import { LIST_CODES_DOMMAGES_URL } from "../../../utils/api/apiUrls";
import apiInstance from "../../../utils/api/axios.config";
import { useAuthenticationContext } from "../../../utils/contexts/AuthenticationProvider";
import { useRecordsContext } from "../../../utils/contexts/RecordsProvider";
import { useUpdatePagesStateContext } from "../../../utils/contexts/UpdatePagesStateProvider";
import CodeDommagesTableContainer from "./CustomTable/CodeDommagesTableContainer";

function CodeDommagesContainer() {
  const { token } = useAuthenticationContext();
  const { setIsListCodesDommagesUpdated, isListCodesDommagesUpdated } =
    useUpdatePagesStateContext();
  const { setListCodeDommages } = useRecordsContext();
  const [progressState, setProgressState] = React.useState(true);

  React.useEffect(() => {
    if (token !== "") {
      const fetchAllCodeDommages = async () => {
        try {
          const result = await apiInstance.get(LIST_CODES_DOMMAGES_URL, {
            headers: { Authorization: `Token ${token}` },
          });
          setListCodeDommages(result.data);
          setProgressState(false);
        } catch (err) {
          setProgressState(false);
          return err;
        }
      };

      fetchAllCodeDommages();
    }
  }, [token, setListCodeDommages, setProgressState]);

  React.useEffect(() => {
    if (isListCodesDommagesUpdated) {
      const fetchAllCodeDommages = async () => {
        try {
          const result = await apiInstance.get(LIST_CODES_DOMMAGES_URL, {
            headers: { Authorization: `Token ${token}` },
          });
          setListCodeDommages(result.data);
          setProgressState(false);
        } catch (err) {
          setProgressState(false);
          return err;
        }
      };

      fetchAllCodeDommages();
      setIsListCodesDommagesUpdated(false);
    }
  }, [
    isListCodesDommagesUpdated,
    setIsListCodesDommagesUpdated,
    setListCodeDommages,
    token,
    setProgressState,
  ]);

  return (
    <CommonPagesLayout>
      <h1>Codes Dommages</h1>
      {progressState ? <CircularProgress /> : <CodeDommagesTableContainer />}
    </CommonPagesLayout>
  );
}

export default CodeDommagesContainer;
