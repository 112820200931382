import { CircularProgress } from "@mui/material";
import React from "react";
import CommonPagesLayout from "../../../components/layouts/Common/CommonPagesLayout";
import {
  LIST_COLLECT_POINTS_DATA_URL,
  LIST_COXEURS_DATA_URL,
} from "../../../utils/api/apiUrls";
import apiInstance from "../../../utils/api/axios.config";
import { useAuthenticationContext } from "../../../utils/contexts/AuthenticationProvider";
import { useOrganizationsContext } from "../../../utils/contexts/OrganizationsProvider";
import { useUpdatePagesStateContext } from "../../../utils/contexts/UpdatePagesStateProvider";
import CoxeursTableContainer from "./CustomTable/CoxeursTableContainer";

function CoxeursContainer() {
  const { token } = useAuthenticationContext();
  const { setListCoxeurs, setListCollectPoints } = useOrganizationsContext();
  const { isListCoxeursUpdated, setIsListCoxeursUpdated } =
    useUpdatePagesStateContext();
  const [progressState, setProgressState] = React.useState(true);
  React.useEffect(() => {
    if (token !== "") {
      const fetchAllCoxeurs = async () => {
        try {
          const result = await apiInstance.get(LIST_COXEURS_DATA_URL, {
            headers: { Authorization: `Token ${token}` },
          });

          setListCoxeurs(result.data);
          setProgressState(false);
        } catch (err) {
          setProgressState(false);
          return err;
        }
      };

      const fetchAllCollectPoints = async () => {
        try {
          const result = await apiInstance.get(LIST_COLLECT_POINTS_DATA_URL, {
            headers: { Authorization: `Token ${token}` },
          });
          setListCollectPoints(result.data);
        } catch (err) {
          console.log(err);
        }
      };

      fetchAllCollectPoints();
      fetchAllCoxeurs();
    }
  }, [token, setListCoxeurs, setListCollectPoints, setProgressState]);

  React.useEffect(() => {
    if (isListCoxeursUpdated) {
      const fetchAllCoxeurs = async () => {
        try {
          const result = await apiInstance.get(LIST_COXEURS_DATA_URL, {
            headers: { Authorization: `Token ${token}` },
          });

          setListCoxeurs(result.data);
          setProgressState(false);
        } catch (err) {
          setProgressState(false);
          return err;
        }
      };
      fetchAllCoxeurs();
      setIsListCoxeursUpdated(false);
    }
  }, [
    isListCoxeursUpdated,
    setIsListCoxeursUpdated,
    setListCoxeurs,
    token,
    setProgressState,
  ]);

  return (
    <CommonPagesLayout>
      <h1>Coxeurs</h1>
      {progressState ? <CircularProgress /> : <CoxeursTableContainer />}
    </CommonPagesLayout>
  );
}

export default CoxeursContainer;
