import { createTheme } from "@mui/material";
import { makeStyles } from "@mui/styles";

const theme = createTheme();

const useStyles = makeStyles(() => ({
  accordion: {
    boxShadow: "4px 4px 4px 2px black",
    width: "70%",
    [theme.breakpoints.down('md')]: {
      width: "90%",
    },
  },
  accordionDetails: {
    display: "flex",
    flex: 1,
    justifyContent: "space-evenly",
    [theme.breakpoints.down('sm')]: {
      flexDirection: "column",
    },
  },
}));

export default useStyles;
