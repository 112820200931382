import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableFooter from "@mui/material/TableFooter";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import CustomTablePagination from "./TablePagination/CustomTablePagination";
import { TableHead, TableSortLabel } from "@mui/material";
import SearchForTables from "./SearchForTables/SearchForTables";
import ActionsButtonsGroup from "./Buttons/ActionsButtons/ActionsButtonsGroup";
import EditButtonsGroup from "./Buttons/EditButtons/EditButtonsGroup";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.primary.dark,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const useStyles2 = makeStyles((theme) => ({
  tableContainer: {
    display: "flex",
    flexDirection: "column",
  },
  table: {
    minWidth: 500,
  },
  headFunctions: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    alignItems: "center",
    gap: "10px",
    padding: "10px 10px",
  },

  [theme.breakpoints.down("lg")]: {
    headFunctions: {
      flexDirection: "column",
    },
  },

  headFunctionLeft: {
    display: "flex",
    flex: 0.4,
    justifyContent: "flex-start",
    gap: "10px",
  },
  headFunctionRight: {
    display: "flex",
    flex: 0.6,
    justifyContent: "flex-end",
    gap: "10px",
  },
  textUpperCase: { textTransform: "uppercase" },
}));
/** Component Description:
 *  This component render for each table container,
 *  the row's fields.
 */
export default function CustomTable({
  TableContext,
  rows,
  headCells,
  rowsPerPage,
  page,
  emptyRows,
  handleChangeRowsPerPage,
  handleChangePage,
  order,
  orderBy,
  handleSortRequest,
  recordsAfterPaginationSortingAndFiltering,
  disableSearch,
  filterLabel,
  filterValue,
  setFilterValue,
  handleSearch,
  tableName,
  stableSort,
  getComparator,
  filter,
  setRows,
  enableEdit,
  formModalInitialRow,
  FormModal,
  handleDeleteRow,
  disableAddButton,
  disableDownloadButton,
  disableLoadButton,
  formAction,
  setFormAction,
  enableMoreDetailsIcon,
  disableEditIcon,
  disableDeleteIcon,
  moreDetailsPath,
  disableSelectFilterValue,
  customId,
  inputSearchValue,
  setInputSearchValue,
  filteredRows,
  customExport,
  setJSONToSend,
  disableTemplateButton,
  templateFilePath,
  templateFileName,
  enableDateStartEndSearch,
  startDate,
  endDate,
  setStartDate,
  setEndDate,
  customStyle,
  toolTipMessage,
}) {
  const classes = useStyles2();

  return (
    <TableContainer
      component={Paper}
      className={
        customStyle !== undefined ? customStyle : classes.tableContainer
      }
    >
      <div className={classes.headFunctions}>
        <div className={classes.headFunctionLeft}>
          {!disableSearch && (
            <SearchForTables
              filterLabel={filterLabel}
              filterValue={filterValue}
              setFilterValue={setFilterValue}
              handleSearch={handleSearch}
              headCells={headCells}
              disableSelectFilterValue={disableSelectFilterValue}
              setInputSearchValue={setInputSearchValue}
              inputSearchValue={inputSearchValue}
              enableDateStartEndSearch={enableDateStartEndSearch}
              startDate={startDate}
              endDate={endDate}
              setStartDate={setStartDate}
              setEndDate={setEndDate}
              toolTipMessage={toolTipMessage}
            />
          )}
        </div>
        <div className={classes.headFunctionRight}>
          <ActionsButtonsGroup
            tableName={tableName}
            tableData={rows}
            setRows={setRows}
            formModalInitialRow={formModalInitialRow}
            FormModal={FormModal}
            disableAddButton={disableAddButton}
            disableDownloadButton={disableDownloadButton}
            disableLoadButton={disableLoadButton}
            setFormAction={setFormAction}
            formAction={formAction}
            enableEdit={enableEdit}
            customId={customId}
            filteredRows={filteredRows}
            customExport={customExport}
            setJSONToSend={setJSONToSend}
            disableTemplateButton={disableTemplateButton}
            templateFilePath={templateFilePath}
            templateFileName={templateFileName}
          />
        </div>
      </div>

      <Table className={classes.table} aria-label="custom pagination table">
        <TableHead>
          <TableRow>
            {headCells.map((headCell, index) => {
              let headCellId = headCell.id.toLowerCase();
              return (
                <StyledTableCell
                  key={headCellId}
                  sortDirection={orderBy === headCellId ? order : false}
                  className={classes.textUpperCase}
                  align={index === 0 ? "left" : "center"}
                >
                  {headCell.disableSort ? (
                    headCell.label
                  ) : (
                    <TableSortLabel
                      active={orderBy === headCellId}
                      direction={orderBy === headCellId ? order : "asc"}
                      onClick={() => handleSortRequest(headCellId)}
                    >
                      {headCell.label}
                    </TableSortLabel>
                  )}
                </StyledTableCell>
              );
            })}
            {enableEdit && (
              <StyledTableCell className={classes.textUpperCase} align="right">
                Actions
              </StyledTableCell>
            )}
          </TableRow>
        </TableHead>
        <TableBody>
          {(rowsPerPage > 0
            ? recordsAfterPaginationSortingAndFiltering()
            : stableSort(filter.fn(rows), getComparator(order, orderBy))
          ).map((row) => (
            <TableRow key={row.id} className={classes.textUpperCase}>
              <TableContext row={row} />
              {enableEdit && (
                <TableCell align="right">
                  <EditButtonsGroup
                    row={row}
                    rows={rows}
                    setRows={setRows}
                    formModalInitialRow={formModalInitialRow}
                    FormModal={FormModal}
                    handleDeleteRow={handleDeleteRow}
                    setFormAction={setFormAction}
                    formAction={formAction}
                    enableMoreDetailsIcon={enableMoreDetailsIcon}
                    disableEditIcon={disableEditIcon}
                    disableDeleteIcon={disableDeleteIcon}
                    moreDetailsPath={moreDetailsPath + row.id}
                  />
                </TableCell>
              )}
            </TableRow>
          ))}

          {emptyRows > 0 && (
            <TableRow style={{ height: 53 * emptyRows }}>
              <TableCell colSpan={6} />
            </TableRow>
          )}
        </TableBody>
        <TableFooter>
          <TableRow>
            <CustomTablePagination
              count={rows.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </TableRow>
        </TableFooter>
      </Table>
    </TableContainer>
  );
}

CustomTable.propTypes = {
  /**Table name */
  tableName: PropTypes.string.isRequired,
  /** Function to set table rows */
  setRows: PropTypes.func.isRequired,
  /** initial modal form values. Required if 'enableEdit' is true */
  formModalInitialRow: PropTypes.object,
  /** Form modal React component */
  FormModal: PropTypes.func,
  disableAddButton: PropTypes.bool,
  disableDownloadButton: PropTypes.bool,
  disableLoadButton: PropTypes.bool,
  disableTemplateButton: PropTypes.bool,
  setFormAction: PropTypes.func,
  formAction: PropTypes.string,
  enableEdit: PropTypes.bool,
  /** Id for a specific use instead of row's id*/
  customId: PropTypes.string,
  filteredRows: PropTypes.array,
  /** export function for a table container*/
  customExport: PropTypes.func,
  /** Set the import file objects for sending*/
  setJSONToSend: PropTypes.func,
  /** Template import file for a table container*/
  templateFilePath: PropTypes.string,
  /** Exported file name */
  templateFileName: PropTypes.string,
  /** Display table context rows (React component).*/
  TableContext: PropTypes.func,
  /** Table rows */
  rows: PropTypes.arrayOf(PropTypes.object),
  headCells: PropTypes.arrayOf(PropTypes.object),
  rowsPerPage: PropTypes.number,
  page: PropTypes.number,
  emptyRows: PropTypes.number,
  handleChangeRowsPerPage: PropTypes.func,
  handleChangePage: PropTypes.func,
  /** ASC or DESC order */
  order: PropTypes.string,
  /** Field id to order. Set in head cells*/
  orderBy: PropTypes.string,
  handleSortRequest: PropTypes.func,
  recordsAfterPaginationSortingAndFiltering: PropTypes.func,
  disableSearch: PropTypes.bool,
  /** Select filtering field */
  filterLabel: PropTypes.string,
  /** Filter value*/
  filterValue: PropTypes.string,
  setFilterValue: PropTypes.func,
  handleSearch: PropTypes.func,
  stableSort: PropTypes.func,
  getComparator: PropTypes.func,
  /**Filtered Items */
  filter: PropTypes.object,
  /** Delete a row by its id*/
  handleDeleteRow: PropTypes.func,
  enableMoreDetailsIcon: PropTypes.bool,
  disableEditIcon: PropTypes.bool,
  disableDeleteIcon: PropTypes.bool,
  moreDetailsPath: PropTypes.string,
  disableSelectFilterValue: PropTypes.bool,
  /** Filter value*/
  inputSearchValue: PropTypes.string,
  setInputSearchValue: PropTypes.func,
  enableDateStartEndSearch: PropTypes.bool,
  startDate: PropTypes.string,
  endDate: PropTypes.string,
  setStartDate: PropTypes.func,
  setEndDate: PropTypes.func,
  /** Style coming from makeStyle of material ui */
  customStyle: PropTypes.string,
  toolTipMessage: PropTypes.string,
};

CustomTable.defaultProps = { moreDetailsPath: "" };
