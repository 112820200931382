import { FormControl, InputLabel, NativeSelect } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import React from "react";
import { useOrganizationsContext } from "../../../../../utils/contexts/OrganizationsProvider";
import Items from "./Items/Items";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(2),
    minWidth: 150,
  },
}));

function SelectGroupments({ compareInputValue, setInputValue, inputValue }) {
  const classes = useStyles();
  const { listGroupments, setListGroupments } = useOrganizationsContext();

  const handleChangeProduct = (event) => {
    setInputValue(event.target.value);
  };

  return (
    <React.Fragment>
      <FormControl className={classes.formControl}>
        <InputLabel id="demo-controlled-open-select-label">
          Groupements
        </InputLabel>
        <NativeSelect
          id="demo-controlled-open-select"
          value={inputValue}
          onChange={handleChangeProduct}
        >
          <Items
            compareInputValue={compareInputValue}
            listGroupments={listGroupments}
            setListGroupments={setListGroupments}
            inputValue={inputValue}
          />
        </NativeSelect>
      </FormControl>
    </React.Fragment>
  );
}

export default SelectGroupments;
