import {
  FormControl,
  Modal,
  Button,
  ButtonGroup,
  FormHelperText,
  Typography,
  TextField,
  NativeSelect,
  FormControlLabel,
  Checkbox,
} from "@mui/material";

import makeStyles from "@mui/styles/makeStyles";
import Backdrop from "@mui/material/Backdrop";
import Fade from "@mui/material/Fade";
import React from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useMarketsContext } from "../../../../utils/contexts/MarketsProvider";
import { useProductsContext } from "../../../../utils/contexts/ProductsProvider";
import { useAuthenticationContext } from "../../../../utils/contexts/AuthenticationProvider";
import moment from "moment";
import FormModalsWrapper from "../FormModalsWrapper";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    zIndex: "99999",
  },
  paper: {
    "& > *": {
      width: "60ch",
    },
    [theme.breakpoints.down("md")]: {
      "& > *": {
        width: "35ch",
      },
    },
    background: "rgba( 255, 255, 255, 0.90 )",
    boxSshadow: "0 8px 32px 0 rgba( 31, 38, 135, 0.50 )",
    backdropFilter: "blur( 4px )",
    borderRadius: "10px",
    border: "1px solid rgba( 255, 255, 255, 0.18 )",
    height: "80%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "flex-start",
  },
  form: {
    display: "flex",
    width: "100%",
    flexDirection: "column",
    height: "100%",
    gap: "10px",
    justifyContent: "space-between",
    alignItems: "center",
    overflowY: "scroll",
    "&::-webkit-scrollbar": {
      width: "20px",
    },
    "&::-webkit-scrollbar-track": {
      backgroundColor: "transparent",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#172b18",
      borderRadius: "20px",
      border: "6px solid transparent",
      backgroundClip: "content-box",
    },
  },
  formRow: {
    marginTop: "10px",
    marginBottom: "10px",
    [theme.breakpoints.down("md")]: {
      marginTop: "5px",
      marginBottom: "5px",
    },
  },
}));

function MarketsPricesFormModals({ row, open, handleClose, formAction }) {
  const classes = useStyles();
  const { user } = useAuthenticationContext();
  const { listMarkets, createMarketPrice, updateMarketPrice } =
    useMarketsContext();
  const { listVarieties } = useProductsContext();
  const formik = useFormik({
    initialValues: {
      id: row.id === undefined ? "" : row.id,
      varietyId: row.variete_id === undefined ? "" : row.variete_id,
      marketId: row.marche_id === undefined ? "" : row.marche_id,
      prixMaxGros: row.prix_gros_max === undefined ? null : row.prix_gros_max,
      prixMinGros: row.prix_gros_min === undefined ? null : row.prix_gros_min,
      prixMaxSemiGros:
        row.prix_demi_gros_min === undefined ? null : row.prix_demi_gros_min,
      prixMinSemiGros:
        row.prix_demi_gros_max === undefined ? null : row.prix_demi_gros_max,
      uniteSemiGros:
        row.unite_demi_gros === undefined ? null : row.unite_demi_gros,
      quantiteSemiGros:
        row.quantite_demi_gros === undefined ? null : row.quantite_demi_gros,
      prixMaxDetail:
        row.prix_detaillant_max === undefined ? "" : row.prix_detaillant_max,
      prixMinDetail:
        row.prix_detaillant_min === undefined ? "" : row.prix_detaillant_min,
      prixDominantDetail:
        row.prix_dominant === undefined ? "" : row.prix_dominant,
      statut: row.statut === undefined ? false : row.statut,
      dateStatut:
        row.date_statut === undefined
          ? moment().format("YYYY-MM-DD")
          : row.date_statut,
      dateStatutExp:
        row.date_statut_expiration === undefined
          ? moment().add(7, "days").format("YYYY-MM-DD")
          : row.date_statut_expiration,
    },
    enableReinitialize: true,
    validateOnChange: true,

    validationSchema: Yup.object({
      id: Yup.string(),
      varietyId: Yup.number()
        .typeError("Doit être un nombre")
        .required("Champ requis"),
      marketId: Yup.number()
        .typeError("Doit être un nombre")
        .required("Champ requis"),
      prixMaxGros: Yup.number()
        .typeError("Doit être un nombre")
        .notRequired()
        .positive("Entrer un nombre positif!")
        .nullable(true),
      prixMinGros: Yup.number()
        .typeError("Doit être un nombre")
        .notRequired()
        .positive("Entrer un nombre positif!")
        .nullable(true),
      prixMaxSemiGros: Yup.number()
        .typeError("Doit être un nombre")
        .notRequired()
        .positive("Entrer un nombre positif!")
        .nullable(true),
      prixMinSemiGros: Yup.number()
        .typeError("Doit être un nombre")
        .notRequired()
        .positive("Entrer un nombre positif!")
        .nullable(true),
      uniteSemiGros: Yup.string()
        .oneOf(["KG", "T", null])
        .notRequired()
        .nullable(true),
      quantiteSemiGros: Yup.number()
        .typeError("Doit être un nombre")
        .notRequired()
        .positive("Entrer un nombre positif!")
        .nullable(true),
      prixMaxDetail: Yup.number()
        .typeError("Doit être un nombre")
        .required("Champ requis")
        .positive("Entrer un nombre positif!"),
      prixMinDetail: Yup.number()
        .typeError("Doit être un nombre")
        .required("Champ requis")
        .positive("Entrer un nombre positif!"),
      prixDominantDetail: Yup.number()
        .typeError("Doit être un nombre")
        .required("Champ requis")
        .positive("Entrer un nombre positif!"),
      statut: Yup.boolean().notRequired().default(false),
      dateStatut: Yup.date().notRequired().default(moment().format("L")),
      dateStatutExp: Yup.date()
        .notRequired()
        .default(moment().add(7, "days").format("L")),
    }),

    onSubmit: async (values, onSubmitProps) => {
      let data = {};
      onSubmitProps.setSubmitting(true);
      values.varietyId = JSON.parse(values.varietyId);
      values.marketId = JSON.parse(values.marketId);
      values.prixMinGros = JSON.parse(values.prixMinGros);
      values.prixMaxGros = JSON.parse(values.prixMaxGros);
      values.prixMinSemiGros = JSON.parse(values.prixMinSemiGros);
      values.prixMaxSemiGros = JSON.parse(values.prixMaxSemiGros);
      values.quantiteSemiGros = JSON.parse(values.quantiteSemiGros);
      values.prixMinDetail = JSON.parse(values.prixMinDetail);
      values.prixMaxDetail = JSON.parse(values.prixMaxDetail);
      values.prixDominantDetail = JSON.parse(values.prixDominantDetail);

      data = {
        variete_id: values.varietyId,
        marche_id: values.marketId,
        prix_gros_min: values.prixMinGros,
        prix_gros_max: values.prixMaxGros,
        prix_demi_gros_min: values.prixMinSemiGros,
        prix_demi_gros_max: values.prixMaxSemiGros,
        quantite_demi_gros: values.quantiteSemiGros,
        unite_demi_gros: values.uniteSemiGros,
        prix_detaillant_min: values.prixMinDetail,
        prix_detaillant_max: values.prixMaxDetail,
        prix_dominant: values.prixDominantDetail,
        statut: values.statut,
        date_statut: values.dateStatut,
        date_statut_expiration: values.dateStatutExp,
      };
      if (formAction === "update") {
        updateMarketPrice(row.id, data);
      } else if (formAction === "add") {
        createMarketPrice(data);
      }
      handleClose();
      onSubmitProps.resetForm();
      onSubmitProps.setSubmitting(false);
    },
  });

  return (
    <FormModalsWrapper
      open={open}
      handleClose={handleClose}
      title={
        formAction === "add" ? "NOUVEAU PRIX MARCHE" : "MODIFIER PRIX MARCHE"
      }
    >
      <form
        onSubmit={formik.handleSubmit}
        noValidate
        autoComplete="off"
        className={classes.form}
      >
        <FormControl className={classes.formRow}>
          <NativeSelect
            id="varietyId"
            value={formik.values.varietyId}
            onChange={formik.handleChange}
          >
            <option value="" disabled={formAction === "update" ? true : false}>
              Choisir une variete
            </option>
            {listVarieties.map((c, index) => (
              <option key={index} value={c.id}>
                {c.nom}
              </option>
            ))}
          </NativeSelect>
          <FormHelperText error={formik.errors.varietyId ? true : false}>
            {formik.errors.varietyId}
          </FormHelperText>
        </FormControl>

        <FormControl className={classes.formRow}>
          <NativeSelect
            id="marketId"
            value={formik.values.marketId}
            onChange={formik.handleChange}
          >
            <option value="" disabled={formAction === "update" ? true : false}>
              Choisir un marche
            </option>
            {listMarkets.map((c, index) => (
              <option key={index} value={c.id}>
                {c.nom}
              </option>
            ))}
          </NativeSelect>
          <FormHelperText error={formik.errors.marketId ? true : false}>
            {formik.errors.marketId}
          </FormHelperText>
        </FormControl>

        <FormControl className={classes.formRow}>
          <TextField
            error={formik.errors.prixMinGros ? true : false}
            id="prixMinGros"
            type="text"
            variant="outlined"
            label="PRIX MIN-GROS"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.prixMinGros}
            size="small"
          />
          <FormHelperText error={formik.errors.prixMinGros ? true : false}>
            {formik.errors.prixMinGros}
          </FormHelperText>
        </FormControl>

        <FormControl>
          <TextField
            error={formik.errors.prixMaxGros ? true : false}
            id="prixMaxGros"
            type="text"
            variant="outlined"
            label="PRIX MAX-GROS"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.prixMaxGros}
            size="small"
          />
          <FormHelperText error={formik.errors.prixMaxGros ? true : false}>
            {formik.errors.prixMaxGros}
          </FormHelperText>
        </FormControl>

        <FormControl className={classes.formRow}>
          <TextField
            error={formik.errors.prixMinSemiGros ? true : false}
            id="prixMinSemiGros"
            type="text"
            variant="outlined"
            label="PRIX MIN-DEMIS_GROS"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.prixMinSemiGros}
            size="small"
          />
          <FormHelperText error={formik.errors.prixMinSemiGros ? true : false}>
            {formik.errors.prixMinSemiGros}
          </FormHelperText>
        </FormControl>

        <FormControl className={classes.formRow}>
          <TextField
            error={formik.errors.prixMaxSemiGros ? true : false}
            id="prixMaxSemiGros"
            type="text"
            variant="outlined"
            label="PRIX MAX-DEMIS_GROS"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.prixMaxSemiGros}
            size="small"
          />
          <FormHelperText error={formik.errors.prixMaxSemiGros ? true : false}>
            {formik.errors.prixMaxSemiGros}
          </FormHelperText>
        </FormControl>

        <FormControl className={classes.formRow}>
          <TextField
            error={formik.errors.quantiteSemiGros ? true : false}
            id="quantiteSemiGros"
            type="text"
            variant="outlined"
            label="QUANTITE DEMIS-GROS"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.quantiteSemiGros}
            size="small"
          />
          <FormHelperText error={formik.errors.quantiteSemiGros ? true : false}>
            {formik.errors.quantiteSemiGros}
          </FormHelperText>
        </FormControl>

        <FormControl className={classes.formRow}>
          <NativeSelect
            id="uniteSemiGros"
            value={formik.values.uniteSemiGros}
            onChange={formik.handleChange}
          >
            <option value={null}>Choisir une unité demis-gros</option>
            <option value="KG">Kilogramme</option>
            <option value="T">Tonne</option>
          </NativeSelect>
          <FormHelperText error={formik.errors.uniteSemiGros ? true : false}>
            {formik.errors.uniteSemiGros}
          </FormHelperText>
        </FormControl>

        <FormControl className={classes.formRow}>
          <TextField
            error={formik.errors.prixMinDetail ? true : false}
            id="prixMinDetail"
            type="text"
            variant="outlined"
            label="PRIX MIN-DETAIL"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.prixMinDetail}
            size="small"
          />
          <FormHelperText error={formik.errors.prixMinDetail ? true : false}>
            {formik.errors.prixMinDetail}
          </FormHelperText>
        </FormControl>

        <FormControl className={classes.formRow}>
          <TextField
            error={formik.errors.prixMaxDetail ? true : false}
            id="prixMaxDetail"
            type="text"
            variant="outlined"
            label="PRIX MAX-DETAIL"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.prixMaxDetail}
            size="small"
          />
          <FormHelperText error={formik.errors.prixMaxDetail ? true : false}>
            {formik.errors.prixMaxDetail}
          </FormHelperText>
        </FormControl>

        <FormControl className={classes.formRow}>
          <TextField
            error={formik.errors.prixDominantDetail ? true : false}
            id="prixDominantDetail"
            type="text"
            variant="outlined"
            label="PRIX DOMINANT"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.prixDominantDetail}
            size="small"
          />
          <FormHelperText
            error={formik.errors.prixDominantDetail ? true : false}
          >
            {formik.errors.prixDominantDetail}
          </FormHelperText>
        </FormControl>

        {(user.is_admin_arm || user.is_administrateur) && (
          <FormControl className={classes.formRow}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={formik.values.statut}
                  onChange={formik.handleChange}
                  name="statut"
                  id="statut"
                />
              }
              label="STATUT"
            />
            <FormHelperText error={formik.errors.statut ? true : false}>
              {formik.errors.statut}
            </FormHelperText>
          </FormControl>
        )}
        <FormControl className={classes.formRow}>
          <TextField
            error={formik.errors.dateStatut ? true : false}
            id="dateStatut"
            type="date"
            variant="outlined"
            label="DATE STATUT"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.dateStatut}
            size="small"
            InputLabelProps={{ shrink: true }}
          />
          <FormHelperText error={formik.errors.dateStatut ? true : false}>
            {formik.errors.dateStatut}
          </FormHelperText>
        </FormControl>

        <FormControl className={classes.formRow}>
          <TextField
            disabled
            error={formik.errors.dateStatutExp ? true : false}
            id="dateStatutExp"
            type="date"
            variant="outlined"
            label="DATE STATUT EXPIRATION"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={moment(formik.values.dateStatut)
              .add(7, "days")
              .format("YYYY-MM-DD")}
            size="small"
            InputLabelProps={{ shrink: true }}
          />
          <FormHelperText error={formik.errors.dateStatutExp ? true : false}>
            {formik.errors.dateStatutExp}
          </FormHelperText>
        </FormControl>

        <ButtonGroup
          className={classes.formRow}
          variant="contained"
          aria-label="text primary button group"
        >
          <Button color="primary" type="submit">
            Enregistrer
          </Button>
          <Button
            color="secondary"
            onClick={() => {
              handleClose();
              formik.resetForm();
            }}
          >
            Annuler
          </Button>
        </ButtonGroup>
      </form>
    </FormModalsWrapper>
  );
}

export default MarketsPricesFormModals;
