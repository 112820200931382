import React from "react";
import "./Dashboard.css";
import moment from "moment";
import frLocale from "moment/locale/fr";
import LeafletMap from "../../components/Map/LeafletMap";
import CustomDoughnut from "../../components/Charts/CustomDoughnut";
import CustomTwoAxisLinesGraph from "../../components/Charts/CustomLinesGraph";
import SelectGroupForDashboard from "../../ui/SelectInput/SelectGroupForDashboard/SelectGroupForDashboard";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import BarChartIcon from "@mui/icons-material/BarChart";
import DonutLargeIcon from "@mui/icons-material/DonutLarge";
import PieChartIcon from "@mui/icons-material/PieChart";
import { useDashboardContext } from "../../utils/contexts/DashboardProvider";
import CustomBar from "../../components/Charts/CustomBar";
import CustomPie from "../../components/Charts/CustomPie";
import { useOrganizationsContext } from "../../utils/contexts/OrganizationsProvider";
import { useAuthenticationContext } from "../../utils/contexts/AuthenticationProvider";
import { useProductsContext } from "../../utils/contexts/ProductsProvider";
import apiInstance from "../../utils/api/axios.config";
import AirplayIcon from "@mui/icons-material/Airplay";
import {
  LIST_AGREGATORS_DATA_URL,
  LIST_COLLECT_POINTS_DATA_URL,
  LIST_GROUPMENTS_DATA_URL,
  LIST_PRODUCTORS_DATA_URL,
  LIST_PRODUCTS_DATA_URL,
  STATS_DOMMAGES,
  STATS_ENTREES,
  STATS_SORTIES,
} from "../../utils/api/apiUrls";
// import randomColor from "random-color";
import useStyles from "./styles";
import { CircularProgress, InputAdornment, TextField } from "@mui/material";
import StickerChart from "../../components/Charts/StickerChart";
import ClearIcon from "@mui/icons-material/Clear";

var randomColor = require('randomcolor'); // import the script
var color = randomColor(); // a hex code for an attractive color

function AdminGenAndOthersDasboardContainer() {
  moment.locale("fr");

  const classes = useStyles();
  const mountedRef = React.useRef(true);
  function fortmatDateForRequest(date) {
    return moment(date).format("YYYY-MM-DD");
  }
  const [totalEntree, setTotalEntree] = React.useState(0);
  const [totalSortie, setTotalSortie] = React.useState(0);
  const [totalDommage, setTotalDommage] = React.useState(0);
  const [localDateDebut, setLocalDateDebut] = React.useState("");
  const [localDateFin, setLocalDateFin] = React.useState("");
  const { token } = useAuthenticationContext();
  const { setInfosStockMap } = useDashboardContext();
  const { setListProducts, listProducts } = useProductsContext();
  const {
    setListCollectPoints,
    listCollectPoints,
    listAgregators,
    setListAgregators,
    listGroupments,
    setListGroupments,
    listProductors,
    setListProductors,
  } = useOrganizationsContext();
  const {
    selectedProduct,
    selectedAgregator,
    selectedGroupment,
    selectedCollectPoint,
  } = useDashboardContext();

  moment.updateLocale("fr", [frLocale]);

  const [chartType, setChartType] = React.useState(
    localStorage.getItem("chartType")
      ? localStorage.getItem("chartType")
      : "bar"
  );

  const [chartVarietesOfProduit, setChartVarietesOfProduit] = React.useState({
    labels: [],
    datasets: [],
  });

  const optionsLines = {
    responsive: true,
    legend: {
      display: true,
      labels: {
        fontSize: 16, //point style's size is based on font style not boxed width.
        usePointStyle: true,
      },
    },
    plugins: {
      legend: {
        position: "bottom",
      },
      title: {
        display: true,
        text: "Entrees et Sortie des Stocks",
      },
    },
    scales: {
      x: {
        display: true,
        title: {
          display: true,
          text: "Jour",
        },
      },
      y: {
        display: true,
        title: {
          display: true,
          text: "Stock",
        },
      },
    },
  };

  const optionsVarietesOfProduit = {
    responsive: true,
    plugins: {
      title: {
        display: true,
        text: "Répartition des variétés par produits",
      },
    },
  };

  const [chartGroupsEtZonesOfAgregateur, setChartGroupsEtZonesOfAgregateur] =
    React.useState({
      labels: [],
      datasets: [],
    });

  const optionsGroupsEtZonesOfAgregateur = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: true,
        text: "Répartion des zones et groupements par agrégateur",
        position: "top",
      },
    },
  };

  const [chartProductorsByGender, setChartProductorsByGender] = React.useState({
    labels: [],
    datasets: [],
  });

  const optionsProductorsByGender = {
    responsive: true,
    plugins: {
      legend: {
        position: "bottom",
      },
      title: {
        display: true,
        text: "Répartition des producteurs par sexe",
        position: "bottom",
      },
    },
  };

  const [chartProductorsOfGroupment, setChartProductorsOfGroupment] =
    React.useState({
      labels: [],
      datasets: [],
    });

  const optionsProductorsOfGroupment = {
    responsive: true,
    plugins: {
      legend: {
        position: "bottom",
      },
      title: {
        display: true,
        text: "Répartition des producteurs par groupement",
        position: "bottom",
      },
    },
  };

  React.useEffect(() => {
    if (listAgregators.length > 0) {
      let chartColors =
        chartType !== "bar" &&
        listAgregators.map(() => randomColor(0.99, 0.99));

      let agregatorsLabels = [];
      let dataZones = [];
      let dataGroupements = [];

      listAgregators.forEach((p) => {
        let tempTotal = 0;
        let tempTotalForOne = 0;
        if (selectedAgregator === "null") {
          agregatorsLabels.push(p.nom);
          dataZones.push(p.zones?.length);
          listGroupments.forEach((gp) => {
            if (gp.agregateur.id === p.id) {
              tempTotal++;
            }
          });
          dataGroupements.push(tempTotal);
        } else if (JSON.parse(selectedAgregator) === p.id) {
          agregatorsLabels.push(p.nom);
          dataZones.push(p.zones?.length);
          listGroupments.forEach((gp) => {
            if (gp.agregateur.id === p.id) {
              tempTotalForOne++;
            }
          });
          dataGroupements.push(tempTotalForOne);
        }
      });
      setChartGroupsEtZonesOfAgregateur({
        labels: agregatorsLabels,
        datasets: [
          {
            label: "Nombre de zones",
            data: dataZones,
            backgroundColor:
              chartType === "bar"
                ? [randomColor(0.99, 0.99)]
                : chartColors,
            hoverOffset: 4,
          },
          {
            label: "Nombre de groupements",
            data: dataGroupements,
            backgroundColor:
              chartType === "bar"
                ? [randomColor(0.99, 0.99)]
                : chartColors,
            hoverOffset: 4,
          },
        ],
      });
    }
  }, [listAgregators, chartType, selectedAgregator, listGroupments]);

  React.useEffect(() => {
    if (listProducts.length > 0) {
      let chartColors =
        chartType !== "bar" &&
        listProducts.map(() => randomColor(0.99, 0.99));
      let productLabels = [];
      let producVarieties = [];

      listProducts.forEach((p) => {
        if (p.nom === selectedProduct) {
          productLabels.push(p.nom);
          producVarieties.push(p.varietes?.length);
        }
      });

      setChartVarietesOfProduit({
        labels: productLabels,
        datasets: [
          {
            label: "Nombre de variétés",
            data: producVarieties,
            backgroundColor:
              chartType === "bar"
                ? [randomColor(0.99, 0.99)]
                : chartColors,
            hoverOffset: 4,
          },
        ],
      });
    }
  }, [listProducts, chartType, selectedProduct]);

  React.useEffect(() => {
    if (listProductors.length > 0) {
      let chartColors = chartType !== "bar" && [
        randomColor(0.99, 0.99),
        randomColor(0.99, 0.99),
      ];
      let tempTotalH = 0;
      let tempTotalF = 0;
      listProductors.forEach((p) => {
        if (p.sexe === "H") {
          tempTotalH++;
        } else if (p.sexe === "F") {
          tempTotalF++;
        }
      });

      setChartProductorsByGender({
        labels: ["Producteurs Hommes", "Producteurs Femmes"],
        datasets: [
          {
            label: "Nombre de producteurs",
            data: [tempTotalH, tempTotalF],
            backgroundColor:
              chartType === "bar"
                ? [randomColor(0.99, 0.99)]
                : chartColors,
            hoverOffset: 4,
          },
        ],
      });
    }
  }, [listProductors, chartType]);

  React.useEffect(() => {
    if (listGroupments.length > 0) {
      let chartColors =
        chartType !== "bar" &&
        listGroupments
          .filter(
            (gp) =>
              (selectedAgregator === "null" && gp) ||
              (selectedAgregator !== "null" &&
                gp.agregateur?.id === JSON.parse(selectedAgregator))
          )
          .map(() => randomColor(0.99, 0.99));

      let groupmentLabels = [];
      let dataProductors = [];

      listGroupments
        .filter(
          (gp) =>
            (selectedAgregator === "null" && gp) ||
            (selectedAgregator !== "null" &&
              gp.agregateur?.id === JSON.parse(selectedAgregator))
        )
        .forEach((p) => {
          let tempTotal = 0;
          let tempTotalForOne = 0;
          if (selectedGroupment === "null") {
            groupmentLabels.push(p.nom);
            listProductors.forEach((pr) => {
              if (pr.groupement.id === p.id) {
                tempTotal++;
              }
            });
            dataProductors.push(tempTotal);
          } else {
            if (JSON.parse(selectedGroupment) === p.id) {
              groupmentLabels.push(p.nom);
              listProductors.forEach((pr) => {
                if (pr.groupement.id === p.id) {
                  tempTotalForOne++;
                }
              });
              dataProductors.push(tempTotalForOne);
            }
          }
        });

      setChartProductorsOfGroupment({
        labels: groupmentLabels,
        datasets: [
          {
            label: "Nombre de producteurs",
            data: dataProductors,
            backgroundColor:
              chartType === "bar"
                ? [randomColor(0.99, 0.99)]
                : chartColors,
            hoverOffset: 4,
          },
        ],
      });
    }
  }, [
    listProductors,
    chartType,
    selectedGroupment,
    selectedAgregator,
    listGroupments,
  ]);

  const [linesData] = React.useState({
    labels: [
      moment().add(-5, "days").format("l"),
      moment().add(-4, "days").format("l"),
      moment().add(-3, "days").format("l"),
      moment().add(-2, "days").format("l"),
      moment().add(-1, "days").format("l"),
      moment().format("l"),
    ],
    datasets: [
      {
        label: "Sorties (T)",
        data: [12, 19, 3, 5, 2, 3],
        fill: false,
        backgroundColor: ["rgb(255, 99, 132)"],
        borderColor: "rgb(255, 99, 132, 0.5)",
        borderWidth: 10,
      },
      {
        label: "Entrées (T)",
        data: [4, 10, 34, 56, 26, 7],
        fill: false,
        backgroundColor: ["rgb(88, 206, 177)"],
        borderColor: "rgb(88, 206, 177, 0.5)",
        borderWidth: 10,
      },
    ],
  });

  // React.useEffect(() => {
  //   let noTrimmedQuantity = doghnutFilteredValues[1];
  //   let trimmedQuantity = [];
  //   let arrayLength = noTrimmedQuantity.length;
  //   if (arrayLength !== 0) {
  //     noTrimmedQuantity.map((element) => {
  //       trimmedQuantity.push(element.substring(0, arrayLength - 1));
  //       return null;
  //     });
  //   }

  //   setQuantitiesData({
  //     labels: [
  //       "Stocks disponibles (en Tonne)",
  //       "Stocks reçus (en Tonne)",
  //       "Qte sorties (en Tonne)",
  //       "Qte de dommages (en Tonne)",
  //     ],
  //     datasets: [
  //       {
  //         label: "Quantites",
  //         data: trimmedQuantity,
  //         backgroundColor: ["#5d9cec", "#6610f2", "#58ceb1", "#f77600"],
  //         hoverOffset: 4,
  //       },
  //     ],
  //   });

  //   setEntitiesData({
  //     labels: [
  //       "Producteurs",
  //       "Points de collecte",
  //       "Groupements",
  //       "Agrégateurs",
  //     ],
  //     datasets: [
  //       {
  //         label: "Entites",
  //         data: doghnutFilteredValues[0],
  //         backgroundColor: ["#5d9cec", "#6610f2", "#58ceb1", "#f77600"],
  //         hoverOffset: 4,
  //       },
  //     ],
  //   });
  // }, [doghnutFilteredValues]);

  // React.useEffect(() => {
  //   setLinesData({
  //     labels: [
  //       moment().add(-5, "days").format("l"),
  //       moment().add(-4, "days").format("l"),
  //       moment().add(-3, "days").format("l"),
  //       moment().add(-2, "days").format("l"),
  //       moment().add(-1, "days").format("l"),
  //       moment().format("l"),
  //     ],
  //     datasets: [
  //       {
  //         label: "Sorties (T)",
  //         data: courbeFilteredValue[1],
  //         fill: false,
  //         backgroundColor: "rgb(255, 99, 132)",
  //         borderColor: "rgba(255, 99, 132, 0.2)",
  //       },
  //       {
  //         label: "Entrées (T)",
  //         data: courbeFilteredValue[0],
  //         fill: false,
  //         backgroundColor: "rgb(88, 206, 177)",
  //         borderColor: "rgb(88, 206, 177, 0.2)",
  //       },
  //     ],
  //   });
  // }, [courbeFilteredValue]);

  React.useEffect(() => {
    localStorage.setItem("chartType", chartType);
  }, [chartType]);

  React.useEffect(() => {
    if (mountedRef.current) {
      if (token !== "") {
        const fetchAllProducts = async () => {
          try {
            const result = await apiInstance.get(LIST_PRODUCTS_DATA_URL);
            if (!mountedRef.current) return null;
            setListProducts(result.data);
          } catch (err) {}
        };

        const fetchAllCollectPoints = async () => {
          try {
            const result = await apiInstance.get(LIST_COLLECT_POINTS_DATA_URL);
            if (!mountedRef.current) return null;
            setListCollectPoints(result.data);
          } catch (err) {}
        };

        const fetchAllAgregators = async () => {
          try {
            const result = await apiInstance.get(LIST_AGREGATORS_DATA_URL, {
              headers: {
                Authorization: `Token ${token}`,
              },
            });
            if (!mountedRef.current) return null;
            setListAgregators(result.data);
          } catch (err) {}
        };

        const fetchAllGroupments = async () => {
          try {
            const result = await apiInstance.get(LIST_GROUPMENTS_DATA_URL);
            if (!mountedRef.current) return null;
            setListGroupments(result.data);
          } catch (err) {}
        };

        const fetchAllProductors = async () => {
          try {
            const result = await apiInstance.get(LIST_PRODUCTORS_DATA_URL);
            if (!mountedRef.current) return null;
            setListProductors(result.data);
          } catch (err) {}
        };

        fetchAllProducts();
        fetchAllCollectPoints();
        fetchAllAgregators();
        fetchAllGroupments();
        fetchAllProductors();
      }
    }
    return () => {
      mountedRef.current = false;
    };
  }, [
    token,
    mountedRef,
    setListAgregators,
    setListCollectPoints,
    setListGroupments,
    setListProductors,
    setListProducts,
  ]);

  const [chartStocks, setChartsStocks] = React.useState({
    labels: [],
    datasets: [],
  });

  const [chartStocksOptions, setChartStocksOptions] = React.useState({
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: true,
        text: "",
        position: "top",
      },
    },
  });

  React.useEffect(() => {
    if (listProducts.length > 0 && listCollectPoints.length > 0) {
      let localProductId = null;
      let localCollectPointId = null;
      listProducts.forEach((pr) => {
        if (pr.nom === selectedProduct) localProductId = pr.id;
      });
      listCollectPoints.forEach((cl) => {
        if (cl.nom === selectedCollectPoint) localCollectPointId = cl.id;
      });

      const fetchStatsStocks = async () => {
        let resultAll = {};
        let URLs = [];
        if (localCollectPointId === null) {
          if (localDateDebut !== "" && localDateFin !== "") {
            URLs = [
              `${STATS_ENTREES}?produit_id=${localProductId}&date_deb=${localDateDebut}&date_fin=${localDateFin}`,
              `${STATS_SORTIES}?produit_id=${localProductId}&date_deb=${localDateDebut}&date_fin=${localDateFin}`,
              `${STATS_DOMMAGES}?produit_id=${localProductId}&date_deb=${localDateDebut}&date_fin=${localDateFin}`,
            ];
          } else {
            URLs = [
              `${STATS_ENTREES}?produit_id=${localProductId}`,
              `${STATS_SORTIES}?produit_id=${localProductId}`,
              `${STATS_DOMMAGES}?produit_id=${localProductId}`,
            ];
          }
          if (localDateDebut !== "" && localDateFin === "") {
            URLs = [
              `${STATS_ENTREES}?produit_id=${localProductId}&date_deb=${localDateDebut}`,
              `${STATS_SORTIES}?produit_id=${localProductId}&date_deb=${localDateDebut}`,
              `${STATS_DOMMAGES}?produit_id=${localProductId}&date_deb=${localDateDebut}`,
            ];
          } else if (localDateDebut === "" && localDateFin !== "") {
            URLs = [
              `${STATS_ENTREES}?produit_id=${localProductId}&date_fin=${localDateFin}`,
              `${STATS_SORTIES}?produit_id=${localProductId}&date_fin=${localDateFin}`,
              `${STATS_DOMMAGES}?produit_id=${localProductId}&date_fin=${localDateFin}`,
            ];
          }
        } else {
          if (localDateDebut !== "" && localDateFin !== "") {
            URLs = [
              `${STATS_ENTREES}?pc_id=${localCollectPointId}&produit_id=${localProductId}&date_deb=${localDateDebut}&date_fin=${localDateFin}`,
              `${STATS_SORTIES}?pc_id=${localCollectPointId}&produit_id=${localProductId}&date_deb=${localDateDebut}&date_fin=${localDateFin}`,
              `${STATS_DOMMAGES}?pc_id=${localCollectPointId}&produit_id=${localProductId}&date_deb=${localDateDebut}&date_fin=${localDateFin}`,
            ];
          } else {
            URLs = [
              `${STATS_ENTREES}?pc_id=${localCollectPointId}&produit_id=${localProductId}`,
              `${STATS_SORTIES}?pc_id=${localCollectPointId}&produit_id=${localProductId}`,
              `${STATS_DOMMAGES}?pc_id=${localCollectPointId}&produit_id=${localProductId}`,
            ];
          }
          if (localDateDebut !== "" && localDateFin === "") {
            URLs = [
              `${STATS_ENTREES}?pc_id=${localCollectPointId}&produit_id=${localProductId}&date_deb=${localDateDebut}`,
              `${STATS_SORTIES}?pc_id=${localCollectPointId}&produit_id=${localProductId}&date_deb=${localDateDebut}`,
              `${STATS_DOMMAGES}?pc_id=${localCollectPointId}&produit_id=${localProductId}&date_deb=${localDateDebut}`,
            ];
          } else if (localDateDebut === "" && localDateFin !== "") {
            URLs = [
              `${STATS_ENTREES}?pc_id=${localCollectPointId}&produit_id=${localProductId}&date_fin=${localDateFin}`,
              `${STATS_SORTIES}?pc_id=${localCollectPointId}&produit_id=${localProductId}&date_fin=${localDateFin}`,
              `${STATS_DOMMAGES}?pc_id=${localCollectPointId}&produit_id=${localProductId}&date_fin=${localDateFin}`,
            ];
          }
        }

        resultAll = await Promise.all(
          URLs.map((url) => {
            return apiInstance
              .get(url)
              .then((response) => {
                return { success: response.data.success, data: response.data };
              })
              .catch((err) => {
                return { success: false };
              });
          })
        );

        setInfosStockMap(resultAll);

        if (!mountedRef.current) return null;
        let localLabels = [];
        let localDataSets = [];
        let localDataSetsEntrees = [];
        let localDataSetsSorties = [];
        let localDataSetsDommages = [];
        resultAll.forEach((result) => {
          if (result.success) {
            result.data.pcs.forEach((pc) => localLabels.push(pc.pc_nom));
          }
        });
        localLabels = [...new Set(localLabels)];

        resultAll.forEach((result, index) => {
          if (result.success) {
            switch (index) {
              case 0:
                setTotalEntree(result.data.poids_total / 1000);
                result.data.pcs.forEach((pc) => {
                  let foundIndexEntree = localLabels.indexOf(pc.pc_nom);
                  if (foundIndexEntree === 0 || foundIndexEntree > 0) {
                    localDataSetsEntrees[foundIndexEntree] =
                      pc.poids_total_pc / 1000;
                  }
                });
                break;
              case 1:
                setTotalSortie(result.data.poids_total / 1000);
                result.data.pcs.forEach((pc) => {
                  let foundIndexSortie = localLabels.indexOf(pc.pc_nom);
                  if (foundIndexSortie === 0 || foundIndexSortie > 0) {
                    localDataSetsSorties[foundIndexSortie] =
                      pc.poids_total_pc / 1000;
                  }
                });
                break;
              case 2:
                setTotalDommage(result.data.poids_total / 1000);
                result.data.pcs.forEach((pc) => {
                  let foundIndexDommage = localLabels.indexOf(pc.pc_nom);
                  if (foundIndexDommage === 0 || foundIndexDommage > 0) {
                    localDataSetsDommages[foundIndexDommage] =
                      pc.poids_total_pc / 1000;
                  }
                });
                break;
            }
            localDataSets.push({
              label:
                index === 0
                  ? "Entrées Stock"
                  : index === 1
                  ? "Sorties Stock"
                  : index === 2
                  ? "Dommages Stock"
                  : "",
              data:
                index === 0
                  ? localDataSetsEntrees
                  : index === 1
                  ? localDataSetsSorties
                  : localDataSetsDommages,
            });
          } else {
            switch (index) {
              case 0:
                setTotalEntree(0);
                break;
              case 1:
                setTotalSortie(0);
                break;
              case 2:
                setTotalDommage(0);
                break;
            }
          }
        });

        setChartStocksOptions((prev) => ({
          ...prev,
          plugins: {
            legend: {
              position: "top",
            },
            title: {
              display: true,
              text: `Répartition des Entrees, Sorties et Dommages du produit ${selectedProduct}`,
              position: "top",
              font: {
                size: 20,
                weight: "bold",
              },
            },
            subtitle: {
              display: true,
              text: `Du ${moment(resultAll[0]?.data?.date_deb).format(
                "L"
              )} au ${moment(resultAll[0]?.data?.date_fin).format("L")}`,
              position: "top",
              font: {
                size: 15,
                weight: "bold",
              },
            },
          },
          scales: {
            y: {
              display: true,
              title: {
                display: true,
                text: "Stock (Tonne)",
              },
            },
          },
        }));

        //Setting Color for each dataset
        localDataSets.forEach((dt) => {
          switch (dt.label) {
            case "Entrées Stock":
              dt["backgroundColor"] = ["#03a9f4"];
              break;
            case "Sorties Stock":
              dt["backgroundColor"] = ["#4caf50"];
              break;
            case "Dommages Stock":
              dt["backgroundColor"] = ["#ef5350"];
              break;
            default:
              break;
          }
        });

        // charts variables for display
        setChartsStocks({ labels: localLabels, datasets: localDataSets });
      };

      fetchStatsStocks();
    }
  }, [
    selectedProduct,
    selectedCollectPoint,
    listProducts,
    listCollectPoints,
    localDateDebut,
    localDateFin,
    setTotalDommage,
    setTotalEntree,
    setTotalSortie,
    setChartsStocks,
    setInfosStockMap,
  ]);

  return (
    <React.Fragment>
      <div className={classes.contentHead}>
        <SelectGroupForDashboard hideAgregators={true} hideGroupements={true} />
        <TextField
          variant="filled"
          type="date"
          label="Date début"
          InputLabelProps={{ shrink: true }}
          value={localDateDebut}
          onChange={(e) =>
            setLocalDateDebut(fortmatDateForRequest(e.target.value))
          }
          InputProps={{
            endAdornment: (
              <InputAdornment position="start">
                <ClearIcon
                  onClick={() => setLocalDateDebut("")}
                  style={{ cursor: "pointer" }}
                />
              </InputAdornment>
            ),
          }}
        />
        <TextField
          variant="filled"
          type="date"
          label="Date fin"
          InputLabelProps={{ shrink: true }}
          value={localDateFin}
          onChange={(e) =>
            setLocalDateFin(fortmatDateForRequest(e.target.value))
          }
          InputProps={{
            endAdornment: (
              <InputAdornment position="start">
                <ClearIcon
                  onClick={() => setLocalDateFin("")}
                  style={{ cursor: "pointer" }}
                />
              </InputAdornment>
            ),
          }}
        />
      </div>
      <div className={classes.contentBody}>
        {/* Style need to be made inline */}
        {/* <ButtonGroup
          variant="text"
          color="primary"
          aria-label="contained primary button group"
        >
          <Button
            startIcon={<DonutLargeIcon />}
            onClick={() => {
              setChartType("doghnut");
            }}
          ></Button>
          <Button
            startIcon={<BarChartIcon />}
            onClick={() => {
              setChartType("bar");
            }}
          ></Button>
          <Button
            startIcon={<PieChartIcon />}
            onClick={() => {
              setChartType("pie");
            }}
          ></Button>
        </ButtonGroup> */}

        <div className={classes.chartsGroup}>
          {/* {chartType === "doghnut" && (
            <React.Fragment>
              <div className={classes.chart}>
                {listProducts.length === 0 ? (
                  <CircularProgress />
                ) : (
                  <CustomDoughnut
                    data={chartVarietesOfProduit}
                    options={optionsVarietesOfProduit}
                  />
                )}
              </div>

              <div className={classes.chart}>
                {listAgregators.length === 0 ? (
                  <CircularProgress />
                ) : (
                  <CustomDoughnut
                    data={chartGroupsEtZonesOfAgregateur}
                    options={optionsGroupsEtZonesOfAgregateur}
                  />
                )}
              </div>
            </React.Fragment>
          )}

          {chartType === "bar" && (
            <React.Fragment>
              <div className={classes.chart}>
                {listProducts.length === 0 ? (
                  <CircularProgress />
                ) : (
                  <CustomBar
                    data={chartVarietesOfProduit}
                    options={optionsVarietesOfProduit}
                  />
                )}
              </div>

              <div className={classes.chart}>
                {listAgregators.length === 0 ? (
                  <CircularProgress />
                ) : (
                  <CustomBar
                    data={chartGroupsEtZonesOfAgregateur}
                    options={optionsGroupsEtZonesOfAgregateur}
                  />
                )}
              </div>
            </React.Fragment>
          )}

          {chartType === "pie" && (
            <React.Fragment>
              <div className={classes.chart}>
                {listProducts.length === 0 ? (
                  <CircularProgress />
                ) : (
                  <CustomPie
                    data={chartVarietesOfProduit}
                    options={optionsVarietesOfProduit}
                  />
                )}
              </div>

              <div className={classes.chart}>
                {listAgregators.length === 0 ? (
                  <CircularProgress />
                ) : (
                  <CustomPie
                    data={chartGroupsEtZonesOfAgregateur}
                    options={optionsGroupsEtZonesOfAgregateur}
                  />
                )}
              </div>
            </React.Fragment>
          )} */}
        </div>
        <div className={classes.chartsGroup}>
          {/* {chartType === "doghnut" && (
            <React.Fragment>
              <div className={classes.chart}>
                {listProductors.length === 0 ? (
                  <CircularProgress />
                ) : (
                  <CustomDoughnut
                    data={chartProductorsByGender}
                    options={optionsProductorsByGender}
                  />
                )}
              </div>

              <div className={classes.chart}>
                {listAgregators.length === 0 && listGroupments.length === 0 ? (
                  <CircularProgress />
                ) : (
                  <CustomDoughnut
                    data={chartProductorsOfGroupment}
                    options={optionsProductorsOfGroupment}
                  />
                )}
              </div>
            </React.Fragment>
          )}

          {chartType === "bar" && (
            <React.Fragment>
              <div className={classes.chart}>
                {listProductors.length === 0 ? (
                  <CircularProgress />
                ) : (
                  <CustomBar
                    data={chartProductorsByGender}
                    options={optionsProductorsByGender}
                  />
                )}
              </div>

              <div className={classes.chart}>
                {listAgregators.length === 0 && listGroupments.length === 0 ? (
                  <CircularProgress />
                ) : (
                  <CustomBar
                    data={chartProductorsOfGroupment}
                    options={optionsProductorsOfGroupment}
                  />
                )}
              </div>
            </React.Fragment>
          )}

          {chartType === "pie" && (
            <React.Fragment>
              <div className={classes.chart}>
                {listProductors.length === 0 ? (
                  <CircularProgress />
                ) : (
                  <CustomPie
                    data={chartProductorsByGender}
                    options={optionsProductorsByGender}
                  />
                )}
              </div>

              <div className={classes.chart}>
                {listAgregators.length === 0 && listGroupments.length === 0 ? (
                  <CircularProgress />
                ) : (
                  <CustomPie
                    data={chartProductorsOfGroupment}
                    options={optionsProductorsOfGroupment}
                  />
                )}
              </div>
            </React.Fragment>
          )} */}
        </div>
        <div
          className="chart-line"
          style={{
            width: "80%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: "20px",
            justifyContent: "center",
            marginTop: "20px",
          }}
        >
          <div className={classes.stickerContainer}>
            <StickerChart
              type="entree"
              text="Total Entrées Stocks"
              value={totalEntree}
            />
            <StickerChart
              type="sortie"
              text="Total Sorties Stocks"
              value={totalSortie}
            />
            <StickerChart
              type="dommage"
              text="Total Dommages Stocks"
              value={totalDommage}
            />
            <StickerChart
              type="reste"
              text="Total Stock Disponible"
              value={(totalEntree - (totalSortie + totalDommage)).toFixed(3)}
            />
          </div>
          {listProducts.length === 0 ? (
            <CircularProgress />
          ) : (
            <CustomBar data={chartStocks} options={chartStocksOptions} />
          )}
        </div>

        {/* <div className={classes.map}>

        </div> */}
      </div>
    </React.Fragment>
  );
}

export default AdminGenAndOthersDasboardContainer;
