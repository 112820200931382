import makeStyles from "@mui/styles/makeStyles";

const useFormModalsWrapperStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paperContainer: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    borderRadius: "10px 10px 0 0",
    padding: theme.spacing(0, 0, 3),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-between",
    gap: "10px",
    minWidth: "35rem",
    minHeight: "30rem",
    [theme.breakpoints.down("md")]: {
      minWidth: "21rem",
      minHeight: "25rem",
    },
  },
  paperHead: {
    backgroundImage: "linear-gradient(180deg, #4cd964, #4cd990)",
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "10px 10px 0 0",
    color: "white",
    fontWeight: "bold",
    boxShadow: "1px 2px 2px 1px gray",
    flex: 0.1,
    minHeight: "4rem",
  },
  paperContent: {
    flex: 0.9,
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    height: "10rem",
  },
}));

export default useFormModalsWrapperStyles;
