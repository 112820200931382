import React from "react";
import CustomLinesGraph from "../../components/Charts/CustomLinesGraph";
import CustomDateRangePicker from "../../ui/SelectInput/CustomDateRangePicker/CustomDateRangePicker";
import SelectGroupForDashboard from "../../ui/SelectInput/SelectGroupForDashboard/SelectGroupForDashboard";
import { STATS_MVTS } from "../../utils/api/apiUrls";
import apiInstance from "../../utils/api/axios.config";
import { useAuthenticationContext } from "../../utils/contexts/AuthenticationProvider";
import { useDashboardContext } from "../../utils/contexts/DashboardProvider";
import { useProductsContext } from "../../utils/contexts/ProductsProvider";
import moment from "moment";
import frLocale from "moment/locale/fr";
import useStyles from "./styles";
import CustomSelectIdInput from "../../ui/SelectInput/CustomSelectInput/CustomSelectIdInput";
import CustomDatePicker from "../../ui/SelectInput/CustomDatePIcker/CustomDatePicker";
import ClearIcon from "@mui/icons-material/Clear";
import { Button, InputAdornment, TextField } from "@mui/material";
import Input from "@mui/material/Input";

function GlobalDashboardContainer() {
  moment.updateLocale("fr", [frLocale]);
  const [localSelectedProductId, setLocalSelectedProductId] =
    React.useState("");
  const [statsMVTS, setStatsMVTS] = React.useState({
    labels: [],
    datasets: [],
  });
  const [localDateDebut, setLocalDateDebut] = React.useState("");
  const [localDateFin, setLocalDateFin] = React.useState("");
  const { listProducts } = useProductsContext();
  const { token } = useAuthenticationContext();
  const optionsLines = {
    responsive: true,
    legend: {
      display: true,
      labels: {
        fontSize: 16, //point style's size is based on font style not boxed width.
        usePointStyle: true,
      },
    },
    plugins: {
      legend: {
        position: "bottom",
      },
      title: {
        display: true,
        text: "Evolution des Entrees, Sorties et Dommages des Stocks",
      },
    },
    scales: {
      x: {
        display: true,
        title: {
          display: true,
          text: "Jour",
        },
      },
      y: {
        display: true,
        title: {
          display: true,
          text: "Stock (Tonne)",
        },
      },
    },
  };

  function fortmatDateForRequest(date) {
    return moment(date).format("YYYY-MM-DD");
  }

  React.useEffect(() => {
    setLocalSelectedProductId(listProducts[0].id);
  }, []);

  React.useEffect(() => {
    if (listProducts.length > 0) {
      let localUniqueDateArray = [];
      let localDataSets = [];
      const fetchStatsMVTS = async () => {
        try {
          let result = {};
          if (localSelectedProductId !== "") {
            if (localDateDebut !== "" && localDateFin !== "") {
              result = await apiInstance.get(
                STATS_MVTS +
                  `?produit_id=${localSelectedProductId}&date_deb=${localDateDebut}&date_fin=${localDateFin}`,
                {
                  headers: {
                    Authorization: `Token ${token}`,
                  },
                }
              );
            } else {
              result = await apiInstance.get(
                STATS_MVTS + `?produit_id=${localSelectedProductId}`,
                {
                  headers: {
                    Authorization: `Token ${token}`,
                  },
                }
              );
            }
            if (localDateDebut !== "" && localDateFin === "") {
              result = await apiInstance.get(
                STATS_MVTS +
                  `?produit_id=${localSelectedProductId}&date_deb=${localDateDebut}`,
                {
                  headers: {
                    Authorization: `Token ${token}`,
                  },
                }
              );
            } else if (localDateDebut === "" && localDateFin !== "") {
              result = await apiInstance.get(
                STATS_MVTS +
                  `?produit_id=${localSelectedProductId}&date_fin=${localDateFin}`,
                {
                  headers: {
                    Authorization: `Token ${token}`,
                  },
                }
              );
            }

            localUniqueDateArray.push(result.data.date_deb);
            localUniqueDateArray.push(result.data.date_fin);
            result.data.entrees.items.forEach((item) =>
              localUniqueDateArray.push(item.date_mvt)
            );
            result.data.sorties.items.forEach((item) =>
              localUniqueDateArray.push(item.date_mvt)
            );
            result.data.dommages.items.forEach((item) =>
              localUniqueDateArray.push(item.date_mvt)
            );
            localUniqueDateArray = [...new Set(localUniqueDateArray)];
            localUniqueDateArray.sort();
            let localEntreeValues = new Array(localUniqueDateArray.length);
            let localSortiesValues = new Array(localUniqueDateArray.length);
            let localDommagesValues = new Array(localUniqueDateArray.length);
            result.data.entrees.items.forEach((item) => {
              let findIndex = localUniqueDateArray.indexOf(item.date_mvt);
              localEntreeValues[findIndex] = item.poids / 1000;
            });
            result.data.sorties.items.forEach((item) => {
              let findIndex = localUniqueDateArray.indexOf(item.date_mvt);
              localSortiesValues[findIndex] = item.poids / 1000;
            });
            result.data.dommages.items.forEach((item) => {
              let findIndex = localUniqueDateArray.indexOf(item.date_mvt);
              localDommagesValues[findIndex] = item.poids / 1000;
            });
            localEntreeValues = Array.from(
              localEntreeValues,
              (item) => item || 0
            );
            localSortiesValues = Array.from(
              localSortiesValues,
              (item) => item || 0
            );
            localDommagesValues = Array.from(
              localDommagesValues,
              (item) => item || 0
            );

            setStatsMVTS({
              labels: localUniqueDateArray,
              datasets: [
                {
                  label: "Entrées Stocks",
                  data: localEntreeValues,
                  backgroundColor: ["#03a9f4"],
                  borderColor: ["#03a9f4"],
                  borderWidth: 5,
                },
                {
                  label: "Sorties Stocks",
                  data: localSortiesValues,
                  backgroundColor: ["#4caf50"],
                  borderColor: ["#4caf50"],
                  borderWidth: 5,
                },
                {
                  label: "Dommages Stocks",
                  data: localDommagesValues,
                  backgroundColor: ["#ef5350"],
                  borderColor: ["#ef5350"],
                  borderWidth: 5,
                },
              ],
            });
          }
        } catch (err) {}
      };
      fetchStatsMVTS();
    }
  }, [
    localSelectedProductId,
    localDateDebut,
    localDateFin,
    setStatsMVTS,
    listProducts,
    setLocalSelectedProductId,
  ]);
  const classes = useStyles();
  return (
    <React.Fragment>
      <div className={classes.contentHead}>
        <CustomSelectIdInput
          inputLabel="Produit"
          items={listProducts}
          setInputValue={setLocalSelectedProductId}
          inputValue={localSelectedProductId}
        />

        <TextField
          variant="filled"
          type="date"
          label="Date début"
          InputLabelProps={{ shrink: true }}
          value={localDateDebut}
          onChange={(e) =>
            setLocalDateDebut(fortmatDateForRequest(e.target.value))
          }
          InputProps={{
            endAdornment: (
              <InputAdornment position="start">
                <ClearIcon
                  onClick={() => setLocalDateDebut("")}
                  style={{ cursor: "pointer" }}
                />
              </InputAdornment>
            ),
          }}
        />

        <TextField
          variant="filled"
          type="date"
          label="Date fin"
          InputLabelProps={{ shrink: true }}
          value={localDateFin}
          onChange={(e) =>
            setLocalDateFin(fortmatDateForRequest(e.target.value))
          }
          InputProps={{
            endAdornment: (
              <InputAdornment position="start">
                <ClearIcon
                  onClick={() => setLocalDateFin("")}
                  style={{ cursor: "pointer" }}
                />
              </InputAdornment>
            ),
          }}
        />
      </div>
      <div className={classes.contentBody}>
        <div className={classes.chartLine}>
          <CustomLinesGraph data={statsMVTS} options={optionsLines} />
        </div>
      </div>
    </React.Fragment>
  );
}

export default GlobalDashboardContainer;
