import React from "react";
import { toast } from "react-toastify";
import { CREATE_USER, LIST_USERS_DATA_URL } from "../api/apiUrls";
import apiInstance from "../api/axios.config";
import { useAuthenticationContext } from "./AuthenticationProvider";
import { useUpdatePagesStateContext } from "./UpdatePagesStateProvider";

const UsersContext = React.createContext();

export const useUsersContext = () => {
  return React.useContext(UsersContext);
};

function UsersProvider({ children }) {
  const { token } = useAuthenticationContext();
  const [listUsers, setListUsers] = React.useState([]);
  const { setIsUserListUpdated } = useUpdatePagesStateContext();

  const createUser = async (data) => {
    try {
      await apiInstance.post(CREATE_USER, data, {
        headers: {
          Authorization: `Token ${token}`,
        },
      });
      toast.success("Utilisateur crée!", {
        position: "bottom-center",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      setIsUserListUpdated(true);
    } catch (err) {}
  };

  const updateUser = async (id, data) => {
    try {
      await apiInstance.patch(LIST_USERS_DATA_URL + `${id}/`, data, {
        headers: {
          Authorization: `Token ${token}`,
        },
      });
      toast.success("Utilisateur mis a jour!", {
        position: "bottom-center",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      setIsUserListUpdated(true);
    } catch (err) {}
  };

  const deleteUser = async (row) => {
    try {
      await apiInstance.delete(LIST_USERS_DATA_URL + `${row.id}/`, {
        headers: {
          Authorization: `Token ${token}`,
        },
      });
      toast.success("Utilisateur supprime!", {
        position: "bottom-center",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      setIsUserListUpdated(true);
    } catch (err) {}
  };

  return (
    <UsersContext.Provider
      value={{
        listUsers,
        setListUsers,
        createUser,
        updateUser,
        deleteUser,
      }}
    >
      {children}
    </UsersContext.Provider>
  );
}

export default UsersProvider;
