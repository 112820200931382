import {
  FormControl,
  Modal,
  TextField,
  Button,
  ButtonGroup,
  FormHelperText,
  Typography,
  unstable_createMuiStrictModeTheme,
} from "@mui/material";

import makeStyles from "@mui/styles/makeStyles";
import Backdrop from "@mui/material/Backdrop";
import Fade from "@mui/material/Fade";
import React from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import SelectGroupForRecords from "../../../SelectInput/SelectGroupForRecords/SelectGroupForRecords";
import { useRecordsContext } from "../../../../utils/contexts/RecordsProvider";
import { useOrganizationsContext } from "../../../../utils/contexts/OrganizationsProvider";
import FormModalsWrapper from "../FormModalsWrapper";

const theme = unstable_createMuiStrictModeTheme();

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    zIndex: "99999",
  },
  paper: {
    "& > *": {
      width: "60ch",
    },
    [theme.breakpoints.down("md")]: {
      "& > *": {
        width: "35ch",
      },
    },
    background: "rgba( 255, 255, 255, 0.90 )",
    boxSshadow: "0 8px 32px 0 rgba( 31, 38, 135, 0.50 )",
    backdropFilter: "blur( 4px )",
    borderRadius: "10px",
    border: "1px solid rgba( 255, 255, 255, 0.18 )",
    height: "80%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "flex-start",
  },
  form: {
    display: "flex",
    width: "100%",
    flexDirection: "column",
    height: "100%",
    gap: "10px",
    justifyContent: "space-between",
    alignItems: "center",
    overflowY: "scroll",
    "&::-webkit-scrollbar": {
      width: "20px",
    },
    "&::-webkit-scrollbar-track": {
      backgroundColor: "transparent",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#172b18",
      borderRadius: "20px",
      border: "6px solid transparent",
      backgroundClip: "content-box",
    },
  },
  formRow: {
    marginTop: "10px",
    marginBottom: "10px",
    [theme.breakpoints.down("md")]: {
      marginTop: "5px",
      marginBottom: "5px",
    },
  },
}));
function CollectPointsFormModal({
  row,
  open,
  handleClose,
  formAction,
  formRef,
}) {
  const classes = useStyles();
  const { createCollectPoint, updateCollectPoint } = useOrganizationsContext();

  const { listZones } = useRecordsContext();

  const formik = useFormik({
    initialValues: {
      id: row.id,
      nom: row.nom,
      superficie: row.superficie,
      latitude: row.latitude,
      longitude: row.longitude,
      telephone: row.telephone,
      region: row.zone === undefined ? "" : row.zone.region,
      departement: row.zone === undefined ? "" : row.zone.departement,
      commune: row.zone === undefined ? "" : row.zone.commune,
      zoneId: row.zone === undefined ? "" : row.zone.id,
    },
    enableReinitialize: true,
    validateOnChange: true,

    validationSchema: Yup.object({
      id: Yup.string(),
      nom: Yup.string().required("Champ requis!"),
      superficie: Yup.string().required("Champ requis!"),
      latitude: Yup.string().nullable().notRequired(),
      longitude: Yup.string().nullable().notRequired(),
      telephone: Yup.string().required("Champ requis!"),
      region: Yup.string().required("Champ requis!"),
      departement: Yup.string().required("Champ requis!"),
      commune: Yup.string().required("Champ requis!"),
      zoneId: Yup.number(),
    }),

    onSubmit: (values, onSubmitProps) => {
      onSubmitProps.setSubmitting(true);

      // Find the zone id in case of zone modification, update or creation
      let tempZoneId = "";

      tempZoneId = listZones.find(
        (e) =>
          e.region === values.region &&
          e.departement === values.departement &&
          e.commune === values.commune
      );
      tempZoneId = tempZoneId.id;

      let data = {};
      if (values.latitude === "" || values.longitude === "") {
        data = {
          zone_id: tempZoneId,
          nom: values.nom,
          telephone: values.telephone,
          superficie: values.superficie,
        };
      } else {
        data = {
          zone_id: tempZoneId,
          nom: values.nom,
          latitude: values.latitude,
          longitude: values.longitude,
          telephone: values.telephone,
          superficie: values.superficie,
        };
      }

      if (formAction === "update") {
        updateCollectPoint(values.id, data);
        onSubmitProps.resetForm();
      } else if (formAction === "add") {
        createCollectPoint(data);
        onSubmitProps.resetForm();
      }
      handleClose();
      onSubmitProps.setSubmitting(false);
    },
  });

  React.useEffect(() => {});

  return (
    <FormModalsWrapper
      open={open}
      handleClose={handleClose}
      title={
        formAction === "add"
          ? "NOUVEAU POINT COLLECTE"
          : "MODIFIER POINT COLLETE"
      }
    >
      <form
        onSubmit={formik.handleSubmit}
        noValidate
        autoComplete="off"
        className={classes.form}
      >
        <FormControl className={classes.formRow}>
          <TextField
            error={formik.errors.nom ? true : false}
            id="nom"
            type="text"
            variant="outlined"
            label="NOM"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.nom}
            size="small"
          />
          <FormHelperText error={formik.errors.nom ? true : false}>
            {formik.errors.nom}
          </FormHelperText>
        </FormControl>

        <FormControl className={classes.formRow}>
          <TextField
            error={formik.errors.superficie ? true : false}
            id="superficie"
            type="text"
            variant="outlined"
            label="SUPERFICIE"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.superficie}
            size="small"
          />
          <FormHelperText error={formik.errors.superficie ? true : false}>
            {formik.errors.superficie}
          </FormHelperText>
        </FormControl>

        <FormControl className={classes.formRow}>
          <TextField
            error={formik.errors.latitude ? true : false}
            id="latitude"
            type="text"
            variant="outlined"
            label="LATITUDE"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.latitude}
            size="small"
          />
          <FormHelperText error={formik.errors.latitude ? true : false}>
            {formik.errors.latitude}
          </FormHelperText>
        </FormControl>

        <FormControl className={classes.formRow}>
          <TextField
            error={formik.errors.longitude ? true : false}
            id="longitude"
            type="text"
            variant="outlined"
            label="LONGITUDE"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.longitude}
            size="small"
          />
          <FormHelperText error={formik.errors.longitude ? true : false}>
            {formik.errors.longitude}
          </FormHelperText>
        </FormControl>

        <FormControl className={classes.formRow}>
          <TextField
            error={formik.errors.telephone ? true : false}
            id="telephone"
            type="text"
            variant="outlined"
            label="TELEPHONE"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.telephone}
            size="small"
          />
          <FormHelperText error={formik.errors.telephone ? true : false}>
            {formik.errors.telephone}
          </FormHelperText>
        </FormControl>
        <SelectGroupForRecords formik={formik} formAction={formAction} />
        <ButtonGroup
          className={classes.formRow}
          variant="contained"
          aria-label="text primary button group"
        >
          <Button type="submit" color="primary">
            Enregistrer
          </Button>
          <Button
            color="secondary"
            onClick={() => {
              handleClose();
              formik.resetForm();
            }}
          >
            Annuler
          </Button>
        </ButtonGroup>
      </form>
    </FormModalsWrapper>
  );
}

export default CollectPointsFormModal;
