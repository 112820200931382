import { CircularProgress } from "@mui/material";
import React from "react";
import CommonPagesLayout from "../../../components/layouts/Common/CommonPagesLayout";
import { LIST_LETTRE_VOITURES_DATA_URL } from "../../../utils/api/apiUrls";
import apiInstance from "../../../utils/api/axios.config";
import { useAuthenticationContext } from "../../../utils/contexts/AuthenticationProvider";
import { useRecordsContext } from "../../../utils/contexts/RecordsProvider";
import LettreVoituresTableContainer from "./CustomTable/LettreVoituresTableContainer";

function LettreVoituresContainer() {
  const { token } = useAuthenticationContext();

  const { setListLettreVoitures } = useRecordsContext();
  const [progressState, setProgressState] = React.useState(true);

  React.useEffect(() => {
    if (token !== "") {
      const fetchAllLettrevoitures = async () => {
        try {
          const result = await apiInstance.get(LIST_LETTRE_VOITURES_DATA_URL, {
            headers: { Authorization: `Token ${token}` },
          });

          setListLettreVoitures(result.data);
          setProgressState(false);
        } catch (err) {
          setProgressState(false);
          return err;
        }
      };

      fetchAllLettrevoitures();
    }
  }, [token, setListLettreVoitures, setProgressState]);

  return (
    <CommonPagesLayout>
      <h1>Lettres Voitures</h1>
      {progressState ? <CircularProgress /> : <LettreVoituresTableContainer />}
    </CommonPagesLayout>
  );
}

export default LettreVoituresContainer;
