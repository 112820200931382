import { CircularProgress } from "@mui/material";
import React from "react";
import CommonPagesLayout from "../../../components/layouts/Common/CommonPagesLayout";
import { LIST_ENTREE_STOCKS_DATA_URL } from "../../../utils/api/apiUrls";
import apiInstance from "../../../utils/api/axios.config";
import { useAuthenticationContext } from "../../../utils/contexts/AuthenticationProvider";
import { useRecordsContext } from "../../../utils/contexts/RecordsProvider";
import EntreeStocksTableContainer from "./CustomTable/EntreeStocksTableContainer";

function EntreeStockContainer() {
  const { token } = useAuthenticationContext();

  const { setListEntreeStocks } = useRecordsContext();
  const [progressState, setProgressState] = React.useState(true);
  React.useEffect(() => {
    if (token !== "") {
      const fetchAllEntreeStocks = async () => {
        try {
          const result = await apiInstance.get(LIST_ENTREE_STOCKS_DATA_URL, {
            headers: { Authorization: `Token ${token}` },
          });
          setListEntreeStocks(result.data);
          console.log(result.data);
          setProgressState(false);
        } catch (err) {
          console.log(err);
          setProgressState(false);
          return err;
        }
      };

      fetchAllEntreeStocks();
    }
  }, [token, setListEntreeStocks, setProgressState]);

  return (
    <CommonPagesLayout>
      <h1>Entrées Stocks</h1>
      {progressState ? <CircularProgress /> : <EntreeStocksTableContainer />}
    </CommonPagesLayout>
  );
}

export default EntreeStockContainer;
