import React from "react";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import ResetOwnPassword from "../../ui/Modals/ResetPassword/ResetOwnPassword";
import CommonPagesLayout from "../../components/layouts/Common/CommonPagesLayout";
import useStyles from "./styles";

function ProfileContainer() {
  let my_user = localStorage.getItem("my_user");
  my_user = JSON.parse(my_user);
  const classes = useStyles();
  const [openResetOwn, setOpenResetOwn] = React.useState(false);
  return (
    <CommonPagesLayout>
      <h1>Profile </h1>
      <Card className={classes.root}>
        <CardMedia className={classes.media} image={my_user.photo_base64} />
        <CardContent>
          <Typography gutterBottom variant="h5" component="p">
            {`${my_user.user.last_name} ${my_user.user.first_name}`}
          </Typography>
          <Typography component="p">
            Username: {my_user.user.username}
          </Typography>
          <Typography component="p">Email: {my_user.user.email}</Typography>
          <Typography component="p">
            Roles: {my_user.is_administrateur && "Administrateur, "}
            {my_user.is_admin_saed && "Administrateur SAED"}
            {my_user.is_admin_agregateur && "Administrateur Agregateur, "}
            {my_user.is_admin_groupement && "Administrateur Groupement, "}
            {my_user.is_resp_point_collecte &&
              "Responsable  point de collecte, "}
            {my_user.is_admin_banque && "Administrateur Banque, "}
            {my_user.is_payeur_banque && "Payeur Banque, "}
            {my_user.is_agent_arm && "Agent ARM, "}
            {my_user.is_commercant && "Commercant, "}
            {my_user.is_admin_arm && "Admin ARM, "}
            {my_user.is_producteur && "Producteur, "}
          </Typography>
          {my_user.is_admin_groupement && (
            <React.Fragment>
              <Typography component="p">
                Groupement: {my_user.groupement.nom}
              </Typography>
              <Typography component="p">
                Agrégateur: {my_user.groupement.agregateur.nom}
              </Typography>
            </React.Fragment>
          )}

          {my_user.is_resp_point_collecte && (
            <React.Fragment>
              <Typography component="p">
                Point Collecte: {my_user.point_collecte?.nom}
              </Typography>
            </React.Fragment>
          )}

          {my_user.is_admin_agregateur && (
            <div className={classes.listZones}>
              <Typography component="p">
                Agrégateur: {my_user.agregateur.nom}
              </Typography>

              <Typography component="p">
                Zones:
                {my_user.agregateur.zones.map((zn) => (
                  <ul>
                    <li>
                      {`${zn.region} | ${zn.departement} | ${zn.commune}`}
                    </li>
                  </ul>
                ))}
              </Typography>
            </div>
          )}

          <ResetOwnPassword open={openResetOwn} setOpen={setOpenResetOwn} />
        </CardContent>
        <CardActions>
          <Button
            size="medium"
            color="primary"
            variant="contained"
            onClick={() => setOpenResetOwn(true)}
          >
            Changer mon mot de passe
          </Button>
        </CardActions>
      </Card>
    </CommonPagesLayout>
  );
}

export default ProfileContainer;
