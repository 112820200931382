import React from "react";
import CustomTable from "../../../../components/Table/CustomTable/CustomTable";
import DisplayAgregatorsTableRows from "../../../../components/Table/CustomTable/TableContext/AgregatorsTableCells/DisplayAgregatorsTableRows";
import { exportTableListAgregateurs } from "../../../../services/filesTransfer";
import AgregatorsFormModal from "../../../../ui/Modals/FormModals/Organizations/AgregatorsFormModal";
import { useOrganizationsContext } from "../../../../utils/contexts/OrganizationsProvider";
import templateImport from "../../../../assets/download-templates/template_agregateur.xlsx";
import moment from "moment";
import makeStyles from '@mui/styles/makeStyles';
import { useRecordsContext } from "../../../../utils/contexts/RecordsProvider";
import { useAuthenticationContext } from "../../../../utils/contexts/AuthenticationProvider";

const useStyles = makeStyles({
  agregateurTable: {
    width: "100%",
  },
});

function AgregatorsTableContainer() {
  const classes = useStyles();
  const { listAgregators, deleteAgregator } = useOrganizationsContext();
  const { listZones } = useRecordsContext();
  const { user } = useAuthenticationContext();
  const formModalInitialRow = {
    id: "",
    nom: "",
  };

  const headCells = [
    { label: "Nom", id: "NOM" },
    { label: "Zones", id: "ZONES" },
  ];
  const [jsonToSend, setJSONToSend] = React.useState([]);
  const [filteredRows, setFilteredRows] = React.useState([]);
  const [inputSearchValue, setInputSearchValue] = React.useState("");
  const [formAction, setFormAction] = React.useState("");
  const [rows, setRows] = React.useState([]);
  const [startDate, setStartDate] = React.useState("");
  const [endDate, setEndDate] = React.useState("");
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [filterValue, setFilterValue] = React.useState(headCells[0].id || "");
  const [filter, setFilter] = React.useState({
    fn: (items) => {
      return items;
    },
  });
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState(headCells[0].id);

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

  const handleSearch = (event) => {
    let searchQuery = event.target.value;
    setInputSearchValue(searchQuery);
    setFilter({
      fn: (items) => {
        if (searchQuery === "") return items;
        else
          return items.filter((x) => {
            return (
              x["nom"].toLowerCase().includes(searchQuery.toLowerCase()) ||
              (x["zones"].includes(
                x["zones"].filter(
                  (zn) =>
                    zn.region.toLowerCase() === searchQuery.toLowerCase() ||
                    zn.departement.toLowerCase() ===
                      searchQuery.toLowerCase() ||
                    zn.commune.toLowerCase() === searchQuery.toLowerCase()
                )[0]
              ) &&
                x)
            );
          });
      },
    });
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSortRequest = (cellId) => {
    const isASC = orderBy === cellId && order === "asc";
    setOrder(isASC ? "desc" : "asc");
    setOrderBy(cellId);
  };

  function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  const getComparator = React.useCallback((order, orderBy) => {
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }, []);

  function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }

  const recordsAfterPaginationSortingAndFiltering = () => {
    return stableSort(filter.fn(rows), getComparator(order, orderBy)).slice(
      page * rowsPerPage,
      (page + 1) * rowsPerPage
    );
  };

  React.useEffect(() => {
    if (startDate !== "" && endDate !== "" && listAgregators.length > 0) {
      let tempList = [];
      tempList = listAgregators.filter((ag) => {
        return moment(ag.created_at).isBetween(startDate, endDate) && ag;
      });
      setRows(tempList);
    } else {
      setRows(listAgregators);
    }
  }, [listAgregators, startDate, endDate]);

  React.useEffect(() => {
    function stableSort(array, comparator) {
      const stabilizedThis = array.map((el, index) => [el, index]);
      stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
      });
      setFilteredRows(stabilizedThis.map((el) => el[0]));
    }
    stableSort(filter.fn(rows), getComparator(order, orderBy));
  }, [
    inputSearchValue,
    setFilteredRows,
    getComparator,
    filter,
    order,
    orderBy,
    rows,
  ]);

  React.useEffect(() => {
    if (jsonToSend.length > 0) {
      jsonToSend.forEach((json) => {
        let tempIds = [];
        let tempZnList = json?.zones.split(",");
        tempZnList.forEach((tmpZn) => {
          listZones.forEach((zn) => {
            if (zn.nom.toLowerCase() === tmpZn.toLowerCase()) {
              tempIds.push(zn.id);
            }
          });
        });
        json["zones_ids"] = tempIds;
      });
    }
  }, [jsonToSend, listZones]);

  return (
    <React.Fragment>
      <CustomTable
        customStyle={classes.agregateurTable}
        tableName="agregateur"
        TableContext={DisplayAgregatorsTableRows}
        headCells={headCells}
        rows={rows}
        rowsPerPage={rowsPerPage}
        page={page}
        emptyRows={emptyRows}
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        order={order}
        orderBy={orderBy}
        handleSortRequest={handleSortRequest}
        recordsAfterPaginationSortingAndFiltering={
          recordsAfterPaginationSortingAndFiltering
        }
        filterLabel="Filtrer par:"
        filterValue={filterValue}
        setFilterValue={setFilterValue}
        handleSearch={handleSearch}
        stableSort={stableSort}
        getComparator={getComparator}
        filter={filter}
        setRows={setRows}
        enableEdit={user.is_administrateur ? true : false}
        formModalInitialRow={formModalInitialRow}
        FormModal={AgregatorsFormModal}
        handleDeleteRow={deleteAgregator}
        formAction={formAction}
        setFormAction={setFormAction}
        disableSelectFilterValue={true}
        setInputSearchValue={setInputSearchValue}
        filteredRows={filteredRows}
        inputSearchValue={inputSearchValue}
        customExport={exportTableListAgregateurs}
        setJSONToSend={setJSONToSend}
        templateFilePath={templateImport}
        templateFileName="template_agregateur"
        startDate={startDate}
        setStartDate={setStartDate}
        endDate={endDate}
        setEndDate={setEndDate}
      />
    </React.Fragment>
  );
}

export default AgregatorsTableContainer;
