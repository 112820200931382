import { TableCell } from "@mui/material";
import React from "react";
import PropTypes from "prop-types";

/** Component Description:
 *  This component render for each table container,
 *  the row's fields.
 */
function DisplayCollectPointsTableRows({ row }) {
  return (
    <React.Fragment>
      <TableCell align="left">{row.nom}</TableCell>
      <TableCell align="center">{row.superficie}</TableCell>
      <TableCell align="center">{row.latitude}</TableCell>
      <TableCell align="center">{row.longitude}</TableCell>
      <TableCell align="center">{row.telephone}</TableCell>
      <TableCell align="center">{row.zone?.region}</TableCell>
      <TableCell align="center">{row.zone?.departement}</TableCell>
      <TableCell align="center">{row.zone?.commune}</TableCell>
    </React.Fragment>
  );
}

DisplayCollectPointsTableRows.propTypes = {
  row: PropTypes.object,
};

export default DisplayCollectPointsTableRows;
