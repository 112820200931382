import {
  FormControl,
  Modal,
  TextField,
  Button,
  ButtonGroup,
  FormHelperText,
  Typography,
} from "@mui/material";

import makeStyles from "@mui/styles/makeStyles";
import Backdrop from "@mui/material/Backdrop";
import Fade from "@mui/material/Fade";
import React from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useOrganizationsContext } from "../../../../utils/contexts/OrganizationsProvider";
import { useRecordsContext } from "../../../../utils/contexts/RecordsProvider";
import SelectGroupForRecords from "../../../SelectInput/SelectGroupForRecords/SelectGroupForRecords";
import FormModalsWrapper from "../FormModalsWrapper";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    zIndex: "99999",
  },
  paper: {
    "& > *": {
      width: "60ch",
    },
    [theme.breakpoints.down("md")]: {
      "& > *": {
        width: "35ch",
      },
    },
    background: "rgba( 255, 255, 255, 0.90 )",
    boxSshadow: "0 8px 32px 0 rgba( 31, 38, 135, 0.50 )",
    backdropFilter: "blur( 4px )",
    borderRadius: "10px",
    border: "1px solid rgba( 255, 255, 255, 0.18 )",
    height: "80%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "flex-start",
  },
  form: {
    display: "flex",
    width: "100%",
    flexDirection: "column",
    height: "100%",
    justifyContent: "space-between",
    alignItems: "center",
    overflowY: "scroll",
    "&::-webkit-scrollbar": {
      width: "20px",
    },
    "&::-webkit-scrollbar-track": {
      backgroundColor: "transparent",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#172b18",
      borderRadius: "20px",
      border: "6px solid transparent",
      backgroundClip: "content-box",
    },
  },
  formRow: {
    marginTop: "10px",
    marginBottom: "10px",
    [theme.breakpoints.down("md")]: {
      marginTop: "5px",
      marginBottom: "5px",
    },
  },
}));

function AgregatorsFormModal({ row, open, handleClose, formAction, formRef }) {
  const classes = useStyles();
  const { createAgregator, updateAgregator } = useOrganizationsContext();
  const { listZones } = useRecordsContext();
  const formik = useFormik({
    initialValues: {
      id: row.id,
      nom: row.nom,
      region:
        row.zones !== undefined && row.zones.length > 0
          ? row.zones[0].region
          : "",
      departement:
        row.zones !== undefined && row.zones.length > 0
          ? row.zones[0].departement
          : "",
      commune:
        row.zones !== undefined && row.zones.length > 0
          ? row.zones[0].commune
          : "",
      zoneId: "",
      zoneIds:
        row.zones === undefined || row.zones === null || row.zones?.length === 0
          ? []
          : row.zones.map((zone) => {
              return zone["id"];
            }),
    },
    enableReinitialize: true,
    validateOnChange: true,

    validationSchema: Yup.object({
      id: Yup.string(),
      nom: Yup.string().required("Champ requis!"),
      region: Yup.string().required("Champ requis!"),
      departement: Yup.string().required("Champ requis!"),
      commune: Yup.string().required("Champ requis!"),
      zoneId: Yup.number(),
      zoneIds: Yup.array(),
    }),

    onSubmit: async (values, onSubmitProps) => {
      let tempZoneId = "";

      tempZoneId = listZones.find(
        (e) =>
          e.region === values.region &&
          e.departement === values.departement &&
          e.commune === values.commune
      );
      tempZoneId = tempZoneId.id;
      let tempZoneIds = [];
      tempZoneIds = values.zoneIds;
      if (!tempZoneIds.includes(tempZoneId)) {
        tempZoneIds.push(tempZoneId);
      }

      let data = {
        nom: values.nom,
        zones_ids: values.region === "" ? [] : tempZoneIds,
      };

      onSubmitProps.setSubmitting(true);
      if (formAction === "update") {
        await updateAgregator(values.id, data);
        console.log(data);
      } else if (formAction === "add") {
        await createAgregator(data);
        console.log(data);
      }
      handleClose();
      onSubmitProps.setSubmitting(false);
      onSubmitProps.resetForm();
    },
  });

  return (
    <FormModalsWrapper
      open={open}
      handleClose={handleClose}
      title={
        formAction === "add" ? "NOUVEAU AGREGATEUR" : "MODIFIER AGREGATEUR"
      }
    >
      <form
        onSubmit={formik.handleSubmit}
        noValidate
        autoComplete="off"
        className={classes.form}
      >
        <FormControl className={classes.formRow}>
          <TextField
            error={formik.errors.nom ? true : false}
            id="nom"
            type="text"
            variant="outlined"
            label="NOM"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.nom}
            size="small"
          />
          <FormHelperText error={formik.errors.nom ? true : false}>
            {formik.errors.nom}
          </FormHelperText>
        </FormControl>
        <SelectGroupForRecords formik={formik} formAction={formAction} />
        <ButtonGroup
          className={classes.formRow}
          variant="contained"
          aria-label="text primary button group"
        >
          <Button type="submit" color="primary">
            Enregistrer
          </Button>
          <Button color="secondary" onClick={handleClose}>
            Annuler
          </Button>
        </ButtonGroup>
      </form>
    </FormModalsWrapper>
  );
}

export default AgregatorsFormModal;
