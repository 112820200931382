import React from "react";
import "./Dashboard.css";
import moment from "moment";
import frLocale from "moment/locale/fr";
import LeafletMap from "../../components/Map/LeafletMap";
import CustomDoughnut from "../../components/Charts/CustomDoughnut";
import CommonPagesLayout from "../../components/layouts/Common/CommonPagesLayout";
import CustomTwoAxisLinesGraph from "../../components/Charts/CustomLinesGraph";
import SelectGroupForDashboard from "../../ui/SelectInput/SelectGroupForDashboard/SelectGroupForDashboard";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import BarChartIcon from "@mui/icons-material/BarChart";
import DonutLargeIcon from "@mui/icons-material/DonutLarge";
import PieChartIcon from "@mui/icons-material/PieChart";
import { useDashboardContext } from "../../utils/contexts/DashboardProvider";
import CustomBar from "../../components/Charts/CustomBar";
import CustomPie from "../../components/Charts/CustomPie";
import { useOrganizationsContext } from "../../utils/contexts/OrganizationsProvider";
import { useAuthenticationContext } from "../../utils/contexts/AuthenticationProvider";
import { useProductsContext } from "../../utils/contexts/ProductsProvider";
import apiInstance from "../../utils/api/axios.config";
import {
  LIST_AGREGATORS_DATA_URL,
  LIST_COLLECT_POINTS_DATA_URL,
  LIST_GROUPMENTS_DATA_URL,
  LIST_PRODUCTORS_DATA_URL,
  LIST_PRODUCTS_DATA_URL,
} from "../../utils/api/apiUrls";
// import randomColor from "random-color";
import useStyles from "./styles";
import { CircularProgress, Paper, Typography } from "@mui/material";
import AdminGenDasboardContainer from "./AdminGenAndOthersDasboardContainer";
import AdminGenAndOthersDasboardContainer from "./AdminGenAndOthersDasboardContainer";
import ARMDasboardContainer from "./ARMDasboardContainer";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import GlobalDashboardContainer from "./GlobalDashboardContainer";
import ResponsablePointCollectesDasboardContainer from "./ResponsablePointCollectesDasboardContainer";

var randomColor = require('randomcolor'); // import the script
var color = randomColor(); // a hex code for an attractive color

const DashboardContainer = React.memo(() => {
  const [value, setValue] = React.useState("1");
  const { listProducts } = useProductsContext();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const classes = useStyles();
  // const mountedRef = React.useRef(true);
  const { user } = useAuthenticationContext();
  // const { setListProducts, listProducts } = useProductsContext();
  // const {
  //   setListCollectPoints,
  //   listCollectPoints,
  //   listAgregators,
  //   setListAgregators,
  //   listGroupments,
  //   setListGroupments,
  //   listProductors,
  //   setListProductors,
  // } = useOrganizationsContext();
  // const { selectedProduct, selectedAgregator, selectedGroupment } =
  //   useDashboardContext();

  // moment.updateLocale("fr", [frLocale]);
  // moment().format("l");

  // const [chartType, setChartType] = React.useState(
  //   localStorage.getItem("chartType")
  //     ? localStorage.getItem("chartType")
  //     : "bar"
  // );

  // const [chartVarietesOfProduit, setChartVarietesOfProduit] = React.useState({
  //   labels: [],
  //   datasets: [],
  // });

  // const optionsLines = {
  //   responsive: true,
  //   legend: {
  //     display: true,
  //     labels: {
  //       fontSize: 16, //point style's size is based on font style not boxed width.
  //       usePointStyle: true,
  //     },
  //   },
  //   plugins: {
  //     legend: {
  //       position: "bottom",
  //     },
  //     title: {
  //       display: true,
  //       text: "Entrees et Sortie des Stocks",
  //     },
  //   },
  //   scales: {
  //     x: {
  //       display: true,
  //       title: {
  //         display: true,
  //         text: "Jour",
  //       },
  //     },
  //     y: {
  //       display: true,
  //       title: {
  //         display: true,
  //         text: "Stock",
  //       },
  //     },
  //   },
  // };

  // const optionsVarietesOfProduit = {
  //   responsive: true,
  //   plugins: {
  //     title: {
  //       display: true,
  //       text: "Répartition des variétés par produits",
  //     },
  //   },
  // };

  // const [chartGroupsEtZonesOfAgregateur, setChartGroupsEtZonesOfAgregateur] =
  //   React.useState({
  //     labels: [],
  //     datasets: [],
  //   });

  // const optionsGroupsEtZonesOfAgregateur = {
  //   responsive: true,
  //   plugins: {
  //     legend: {
  //       position: "top",
  //     },
  //     title: {
  //       display: true,
  //       text: "Répartion des zones et groupements par agrégateur",
  //       position: "top",
  //     },
  //   },
  // };

  // const [chartProductorsByGender, setChartProductorsByGender] = React.useState({
  //   labels: [],
  //   datasets: [],
  // });

  // const optionsProductorsByGender = {
  //   responsive: true,
  //   plugins: {
  //     legend: {
  //       position: "bottom",
  //     },
  //     title: {
  //       display: true,
  //       text: "Répartition des producteurs par sexe",
  //       position: "bottom",
  //     },
  //   },
  // };

  // const [chartProductorsOfGroupment, setChartProductorsOfGroupment] =
  //   React.useState({
  //     labels: [],
  //     datasets: [],
  //   });

  // const optionsProductorsOfGroupment = {
  //   responsive: true,
  //   plugins: {
  //     legend: {
  //       position: "bottom",
  //     },
  //     title: {
  //       display: true,
  //       text: "Répartition des producteurs par groupement",
  //       position: "bottom",
  //     },
  //   },
  // };

  // React.useEffect(() => {
  //   if (listAgregators.length > 0) {
  //     let chartColors =
  //       chartType !== "bar" &&
  //       listAgregators.map(() => randomColor(0.99, 0.99).hexString());

  //     let agregatorsLabels = [];
  //     let dataZones = [];
  //     let dataGroupements = [];

  //     listAgregators.forEach((p) => {
  //       let tempTotal = 0;
  //       let tempTotalForOne = 0;
  //       if (selectedAgregator === "null") {
  //         agregatorsLabels.push(p.nom);
  //         dataZones.push(p.zones?.length);
  //         listGroupments.forEach((gp) => {
  //           if (gp.agregateur.id === p.id) {
  //             tempTotal++;
  //           }
  //         });
  //         dataGroupements.push(tempTotal);
  //       } else if (JSON.parse(selectedAgregator) === p.id) {
  //         agregatorsLabels.push(p.nom);
  //         dataZones.push(p.zones?.length);
  //         listGroupments.forEach((gp) => {
  //           if (gp.agregateur.id === p.id) {
  //             tempTotalForOne++;
  //           }
  //         });
  //         dataGroupements.push(tempTotalForOne);
  //       }
  //     });
  //     setChartGroupsEtZonesOfAgregateur({
  //       labels: agregatorsLabels,
  //       datasets: [
  //         {
  //           label: "Nombre de zones",
  //           data: dataZones,
  //           backgroundColor:
  //             chartType === "bar"
  //               ? [randomColor(0.99, 0.99).hexString()]
  //               : chartColors,
  //           hoverOffset: 4,
  //         },
  //         {
  //           label: "Nombre de groupements",
  //           data: dataGroupements,
  //           backgroundColor:
  //             chartType === "bar"
  //               ? [randomColor(0.99, 0.99).hexString()]
  //               : chartColors,
  //           hoverOffset: 4,
  //         },
  //       ],
  //     });
  //   }
  // }, [listAgregators, chartType, selectedAgregator, listGroupments]);

  // React.useEffect(() => {
  //   if (listProducts.length > 0) {
  //     let chartColors =
  //       chartType !== "bar" &&
  //       listProducts.map(() => randomColor(0.99, 0.99).hexString());
  //     let productLabels = [];
  //     let producVarieties = [];

  //     listProducts.forEach((p) => {
  //       if (p.nom === selectedProduct) {
  //         productLabels.push(p.nom);
  //         producVarieties.push(p.varietes?.length);
  //       }
  //     });

  //     setChartVarietesOfProduit({
  //       labels: productLabels,
  //       datasets: [
  //         {
  //           label: "Nombre de variétés",
  //           data: producVarieties,
  //           backgroundColor:
  //             chartType === "bar"
  //               ? [randomColor(0.99, 0.99).hexString()]
  //               : chartColors,
  //           hoverOffset: 4,
  //         },
  //       ],
  //     });
  //   }
  // }, [listProducts, chartType, selectedProduct]);

  // React.useEffect(() => {
  //   if (listProductors.length > 0) {
  //     let chartColors = chartType !== "bar" && [
  //       randomColor(0.99, 0.99).hexString(),
  //       randomColor(0.99, 0.99).hexString(),
  //     ];
  //     let tempTotalH = 0;
  //     let tempTotalF = 0;
  //     listProductors.forEach((p) => {
  //       if (p.sexe === "H") {
  //         tempTotalH++;
  //       } else if (p.sexe === "F") {
  //         tempTotalF++;
  //       }
  //     });

  //     setChartProductorsByGender({
  //       labels: ["Producteurs Hommes", "Producteurs Femmes"],
  //       datasets: [
  //         {
  //           label: "Nombre de producteurs",
  //           data: [tempTotalH, tempTotalF],
  //           backgroundColor:
  //             chartType === "bar"
  //               ? [randomColor(0.99, 0.99).hexString()]
  //               : chartColors,
  //           hoverOffset: 4,
  //         },
  //       ],
  //     });
  //   }
  // }, [listProductors, chartType]);

  // React.useEffect(() => {
  //   if (listGroupments.length > 0) {
  //     let chartColors =
  //       chartType !== "bar" &&
  //       listGroupments
  //         .filter(
  //           (gp) =>
  //             (selectedAgregator === "null" && gp) ||
  //             (selectedAgregator !== "null" &&
  //               gp.agregateur?.id === JSON.parse(selectedAgregator))
  //         )
  //         .map(() => randomColor(0.99, 0.99).hexString());

  //     let groupmentLabels = [];
  //     let dataProductors = [];

  //     listGroupments
  //       .filter(
  //         (gp) =>
  //           (selectedAgregator === "null" && gp) ||
  //           (selectedAgregator !== "null" &&
  //             gp.agregateur?.id === JSON.parse(selectedAgregator))
  //       )
  //       .forEach((p) => {
  //         let tempTotal = 0;
  //         let tempTotalForOne = 0;
  //         if (selectedGroupment === "null") {
  //           groupmentLabels.push(p.nom);
  //           listProductors.forEach((pr) => {
  //             if (pr.groupement.id === p.id) {
  //               tempTotal++;
  //             }
  //           });
  //           dataProductors.push(tempTotal);
  //         } else {
  //           if (JSON.parse(selectedGroupment) === p.id) {
  //             groupmentLabels.push(p.nom);
  //             listProductors.forEach((pr) => {
  //               if (pr.groupement.id === p.id) {
  //                 tempTotalForOne++;
  //               }
  //             });
  //             dataProductors.push(tempTotalForOne);
  //           }
  //         }
  //       });

  //     setChartProductorsOfGroupment({
  //       labels: groupmentLabels,
  //       datasets: [
  //         {
  //           label: "Nombre de producteurs",
  //           data: dataProductors,
  //           backgroundColor:
  //             chartType === "bar"
  //               ? [randomColor(0.99, 0.99).hexString()]
  //               : chartColors,
  //           hoverOffset: 4,
  //         },
  //       ],
  //     });
  //   }
  // }, [
  //   listProductors,
  //   chartType,
  //   selectedGroupment,
  //   selectedAgregator,
  //   listGroupments,
  // ]);

  // const [linesData] = React.useState({
  //   labels: [
  //     moment().add(-5, "days").format("l"),
  //     moment().add(-4, "days").format("l"),
  //     moment().add(-3, "days").format("l"),
  //     moment().add(-2, "days").format("l"),
  //     moment().add(-1, "days").format("l"),
  //     moment().format("l"),
  //   ],
  //   datasets: [
  //     {
  //       label: "Sorties (T)",
  //       data: [12, 19, 3, 5, 2, 3],
  //       fill: false,
  //       backgroundColor: ["rgb(255, 99, 132)"],
  //       borderColor: "rgb(255, 99, 132, 0.5)",
  //       borderWidth: 10,
  //     },
  //     {
  //       label: "Entrées (T)",
  //       data: [4, 10, 34, 56, 26, 7],
  //       fill: false,
  //       backgroundColor: ["rgb(88, 206, 177)"],
  //       borderColor: "rgb(88, 206, 177, 0.5)",
  //       borderWidth: 10,
  //     },
  //   ],
  // });

  // // React.useEffect(() => {
  // //   let noTrimmedQuantity = doghnutFilteredValues[1];
  // //   let trimmedQuantity = [];
  // //   let arrayLength = noTrimmedQuantity.length;
  // //   if (arrayLength !== 0) {
  // //     noTrimmedQuantity.map((element) => {
  // //       trimmedQuantity.push(element.substring(0, arrayLength - 1));
  // //       return null;
  // //     });
  // //   }

  // //   setQuantitiesData({
  // //     labels: [
  // //       "Stocks disponibles (en Tonne)",
  // //       "Stocks reçus (en Tonne)",
  // //       "Qte sorties (en Tonne)",
  // //       "Qte de dommages (en Tonne)",
  // //     ],
  // //     datasets: [
  // //       {
  // //         label: "Quantites",
  // //         data: trimmedQuantity,
  // //         backgroundColor: ["#5d9cec", "#6610f2", "#58ceb1", "#f77600"],
  // //         hoverOffset: 4,
  // //       },
  // //     ],
  // //   });

  // //   setEntitiesData({
  // //     labels: [
  // //       "Producteurs",
  // //       "Points de collecte",
  // //       "Groupements",
  // //       "Agrégateurs",
  // //     ],
  // //     datasets: [
  // //       {
  // //         label: "Entites",
  // //         data: doghnutFilteredValues[0],
  // //         backgroundColor: ["#5d9cec", "#6610f2", "#58ceb1", "#f77600"],
  // //         hoverOffset: 4,
  // //       },
  // //     ],
  // //   });
  // // }, [doghnutFilteredValues]);

  // // React.useEffect(() => {
  // //   setLinesData({
  // //     labels: [
  // //       moment().add(-5, "days").format("l"),
  // //       moment().add(-4, "days").format("l"),
  // //       moment().add(-3, "days").format("l"),
  // //       moment().add(-2, "days").format("l"),
  // //       moment().add(-1, "days").format("l"),
  // //       moment().format("l"),
  // //     ],
  // //     datasets: [
  // //       {
  // //         label: "Sorties (T)",
  // //         data: courbeFilteredValue[1],
  // //         fill: false,
  // //         backgroundColor: "rgb(255, 99, 132)",
  // //         borderColor: "rgba(255, 99, 132, 0.2)",
  // //       },
  // //       {
  // //         label: "Entrées (T)",
  // //         data: courbeFilteredValue[0],
  // //         fill: false,
  // //         backgroundColor: "rgb(88, 206, 177)",
  // //         borderColor: "rgb(88, 206, 177, 0.2)",
  // //       },
  // //     ],
  // //   });
  // // }, [courbeFilteredValue]);

  // React.useEffect(() => {
  //   localStorage.setItem("chartType", chartType);
  // }, [chartType]);

  // React.useEffect(() => {
  //   if (mountedRef.current) {
  //     if (token !== "") {
  //       const fetchAllProducts = async () => {
  //         try {
  //           const result = await apiInstance.get(LIST_PRODUCTS_DATA_URL);
  //           if (!mountedRef.current) return null;
  //           setListProducts(result.data);
  //         } catch (err) {
  //         }
  //       };

  //       const fetchAllCollectPoints = async () => {
  //         try {
  //           const result = await apiInstance.get(LIST_COLLECT_POINTS_DATA_URL);
  //           if (!mountedRef.current) return null;
  //           setListCollectPoints(result.data);
  //         } catch (err) {
  //         }
  //       };

  //       const fetchAllAgregators = async () => {
  //         try {
  //           const result = await apiInstance.get(LIST_AGREGATORS_DATA_URL, {
  //             headers: {
  //               Authorization: `Token ${token}`,
  //             },
  //           });
  //           if (!mountedRef.current) return null;
  //           setListAgregators(result.data);
  //         } catch (err) {
  //         }
  //       };

  //       const fetchAllGroupments = async () => {
  //         try {
  //           const result = await apiInstance.get(LIST_GROUPMENTS_DATA_URL);
  //           if (!mountedRef.current) return null;
  //           setListGroupments(result.data);
  //         } catch (err) {}
  //       };

  //       const fetchAllProductors = async () => {
  //         try {
  //           const result = await apiInstance.get(LIST_PRODUCTORS_DATA_URL);
  //           if (!mountedRef.current) return null;
  //           setListProductors(result.data);
  //         } catch (err) {
  //         }
  //       };

  //       fetchAllProducts();
  //       fetchAllCollectPoints();
  //       fetchAllAgregators();
  //       fetchAllGroupments();
  //       fetchAllProductors();
  //     }
  //   }
  //   return () => {
  //     mountedRef.current = false;
  //   };
  // }, [
  //   token,
  //   mountedRef,
  //   setListAgregators,
  //   setListCollectPoints,
  //   setListGroupments,
  //   setListProductors,
  //   setListProducts,
  // ]);

  return (
    <CommonPagesLayout>
      <h1>Tableau de bord</h1>
      <div className={classes.content}>
        <Paper
          sx={{
            width: "100%",
            typography: "body1",
            backgroundColor: "white",
            minHeight: "40rem",
          }}
          elevation={3}
        >
          <TabContext value={value}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <TabList
                onChange={handleChange}
                aria-label="lab API tabs example"
                variant="scrollable"
                scrollButtons="auto"
              >
                <Tab label="Stocks" value="1" />
                <Tab
                  label="Courbes des flux de stock"
                  value="2"
                  disabled={
                    listProducts.length === 0 || user.is_resp_point_collecte
                      ? true
                      : false
                  }
                />
                <Tab label="Carte des points de collecte" value="3" />
              </TabList>
            </Box>
            <TabPanel value="1">
              {user.is_resp_point_collecte ? (
                <ResponsablePointCollectesDasboardContainer />
              ) : (
                <AdminGenAndOthersDasboardContainer />
              )}
            </TabPanel>
            <TabPanel value="2">
              <GlobalDashboardContainer />
            </TabPanel>
            <TabPanel
              value="3"
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  width: "95%",
                  margin: "10px 0",
                  height: "40rem",
                  boxShadow: "2px 2px 2px 2px gray",
                }}
              >
                <LeafletMap />
              </div>
            </TabPanel>
          </TabContext>
        </Paper>
      </div>
    </CommonPagesLayout>
  );
});

export default DashboardContainer;
