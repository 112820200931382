import React from "react";
import { useRecordsContext } from "../../../utils/contexts/RecordsProvider";
import SelectCommunes from "./SelectItems/Communes/SelectCommunes";
import SelectDepartments from "./SelectItems/Departments/SelectDepartments";
import SelectRegions from "./SelectItems/Regions/SelectRegions";

function SelectGroupForRecords({ formik, formAction }) {
  const { listZonesOrderByRegionByDepartmentByCommune } = useRecordsContext();

  // const handleChangeRegion = (e) => {
  //   formik.setFieldValue("region", e.currentTarget.value);
  //   formik.setFieldValue("departement", "");
  //   formik.setFieldValue("commune", "");
  // };

  // const handleChangeDepartment = (e) => {
  //   formik.setFieldValue("departement", e.currentTarget.value);
  //   formik.setFieldValue("commune", "");
  // };

  // const handleChangeCommune = (e) => {
  //   formik.setFieldValue("commune", e.currentTarget.value);
  // };
  return (
    <React.Fragment>
      <SelectRegions
        items={listZonesOrderByRegionByDepartmentByCommune}
        formik={formik}
      />
      <SelectDepartments
        items={listZonesOrderByRegionByDepartmentByCommune}
        formik={formik}
      />

      <SelectCommunes
        items={listZonesOrderByRegionByDepartmentByCommune}
        formik={formik}
      />
    </React.Fragment>
  );
}

export default SelectGroupForRecords;
